import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import {
  ListSubheader,
  Switch,
  FormControlLabel,
  CardContent,
  Grid,
  Card,
  CardHeader,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ButonGroupDicomFilter from '../ButtonGroup';
import {
  DIAGNOSTICS_MICROCALC,
  DIAGNOSTICS_OPACITIES,
} from '../../../../../config';

const useStyles = makeStyles((theme) => ({
  titleSettingsCard: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },
  action: {
    margin: 0,
  },
  listItem: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.texts.secondary,
  },
  listHeader: {
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },
  list: {
    width: '100%',
    minHeight: '100%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
  },
  module_report_title: {
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },

  module_title: {
    color: theme.palette.texts.secondary,
    fontSize: theme.typography.h6.fontSize,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    marginLeft: '10px',
    '&.Mui-focused': {
      color: theme.palette.texts.secondary,
    },
  },

  module_report_container: {
    margin: '2% 0',
  },

  classes_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    margin: '2% 1%',
  },

  form_control: {
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    marginTop: '14px',
  },
}));

const emptyFields = {
  Name: '',
  AET: '',
  IP: '',
  Port: '',
};

const ReportSettings = (props) => {
  const classes = useStyles();
  const [fieldValues, setFieldValues] = useState(emptyFields);
  const [selected, setSelected] = useState(0);
  const { t } = useTranslation();
  const {
    settings,
    updateSettingsCheckbox,
    updateSettingsText,
    updateSettingsValue,
    updateReportConfig,
  } = props;
  const currentRole = localStorage.getItem('role');
  const somePremiumDiagnostics = DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES;

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Card elevation={1}>
            <CardHeader
              disableTypography={true}
              classes={{
                action: classes.action,
              }}
              title={
                <Typography className={classes.titleSettingsCard} variant="h6">
                  {t('Settings.report_settings')}
                </Typography>
              }
            />
            <CardContent style={{ paddingTop: '0px' }}>
              <Grid item xs={5}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!settings.hide_operator_name}
                      onChange={updateSettingsCheckbox}
                      name="hide_operator_name"
                    />
                  }
                  label={t('Settings.hide_operator_name')}
                />
              </Grid>

              {somePremiumDiagnostics && (
                <div className={classes.module_report_container}>
                  <Typography className={classes.module_report_title}>
                    {t('report.report_modules_title')}
                  </Typography>

                  {DIAGNOSTICS_MICROCALC && (
                    <FormControl
                      component="fieldset"
                      className={classes.form_control}
                    >
                      <FormLabel
                        component="legend"
                        className={classes.module_title}
                      >
                        {t('ResultView.diagnosticsMicrocalc')}
                      </FormLabel>
                      <FormGroup className={classes.classes_container}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.calc_vessel
                              }
                              onChange={updateReportConfig}
                              name="calc_vessel"
                            />
                          }
                          label={t('projections.vessels')}
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.calc_birads2
                              }
                              onChange={updateReportConfig}
                              name="calc_birads2"
                            />
                          }
                          label={t('projections.birads_2')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.calc_birads3
                              }
                              onChange={updateReportConfig}
                              name="calc_birads3"
                            />
                          }
                          label={t('projections.birads_3')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.calc_birads4
                              }
                              onChange={updateReportConfig}
                              name="calc_birads4"
                            />
                          }
                          label={t('projections.birads_4')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.calc_birads5
                              }
                              onChange={updateReportConfig}
                              name="calc_birads5"
                            />
                          }
                          label={t('projections.birads_5')}
                        />
                      </FormGroup>
                    </FormControl>
                  )}

                  {DIAGNOSTICS_OPACITIES && (
                    <FormControl
                      component="fieldset"
                      className={classes.form_control}
                    >
                      <FormLabel
                        component="legend"
                        className={classes.module_title}
                      >
                        {t('ResultView.diagnosticsLesions')}
                      </FormLabel>
                      <FormGroup className={classes.classes_container}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.opac_birads2
                              }
                              onChange={updateReportConfig}
                              name="opac_birads2"
                            />
                          }
                          label={t('projections.birads_2')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.opac_birads3
                              }
                              onChange={updateReportConfig}
                              name="opac_birads3"
                            />
                          }
                          label={t('projections.birads_3')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.opac_birads4
                              }
                              onChange={updateReportConfig}
                              name="opac_birads4"
                            />
                          }
                          label={t('projections.birads_4')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.opac_birads5
                              }
                              onChange={updateReportConfig}
                              name="opac_birads5"
                            />
                          }
                          label={t('projections.birads_5')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                settings.report_customization?.opac_lesionKnown
                              }
                              onChange={updateReportConfig}
                              name="opac_lesionKnown"
                            />
                          }
                          label={t('projections.lesionKnown')}
                        />
                      </FormGroup>
                    </FormControl>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportSettings;
