import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  MenuItem,
  Menu,
  Typography,
  Tooltip,
  Divider,
} from '@material-ui/core';
import * as actions from '../../../../../store/index';
import { useStyles } from './DiagnoseOverlays.style';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { updateNoTypeRectangles } from '../../../../../store';
import {
  DIAGNOSTICS_MICROCALC,
  DIAGNOSTICS_OPACITIES,
} from '../../../../../config';
import {
  deleteFinding,
  getMatchedProj,
  severityColor,
} from '../../DanaiView/Diagnostic/Helpers/DiagnosticRevisionTableCommon';
import {
  handleDeleteButtonClick,
  handleReassignButtonClick,
} from './DiagnoseOverlaysHelpers/DiagnoseOverlaysHelpers';

const DiagnoseOverlays = ({
  proj_name,
  canvasRef,
  imgSize,
  onWheel,
  onMouseMove,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  // Store state
  const {
    applied_microcalc,
    applied_opacities,
    shown_microcalc,
    shown_opacities,
  } = useSelector((state) => state.results);

  const {
    savingPending,
    customMicrocalc,
    customOpacities,
    diagnosticsInRevisionMode,
    singleBoxHover,
    noTypeRectangles,
    lesion_index_mapping,
  } = useSelector((state) => state.labeling);
  const { microcalcOverlaysToShow, opacitiesOverlaysToShow } = useSelector(
    (state) => state.images
  );
  const {
    presentation_lesions,
    presentation_microcalc,
    tabsIndex,
    zoomedProjection,
  } = useSelector((state) => state.gui);

  // Local state
  const [overlayTypes, setOverlayTypes] = useState({});
  const [tempDrawingRectangle, setTempDrawingRectangle] = useState(null);
  const [dragging, setDragging] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedOption, setSelectedOption] = useState({
    option: '',
    type: '',
    rectId: null,
  });
  const [isHovered, setIsHovered] = useState(false);
  const [selectedNewBox, setSelectedNewBox] = useState({});
  const [singleBoxStyles, setSingleBoxStyles] = useState([]);

  // Variables
  const microcalcShown = shown_microcalc || applied_microcalc;
  const opacitiesShown = shown_opacities || applied_opacities;
  const resultMicrocalcEvaluation = useAppliedEvaluation(
    'microcalc',
    microcalcShown,
    diagnosticsInRevisionMode
  );
  const resultOpacitiesEvaluation = useAppliedEvaluation(
    'opacities',
    opacitiesShown,
    diagnosticsInRevisionMode
  );

  // Methods
  const drawOverlays = useCallback(() => {
    if (
      savingPending ||
      (!resultMicrocalcEvaluation && !resultOpacitiesEvaluation)
    )
      return;

    if (!canvasRef || !canvasRef.current || !imgSize) return;

    const context = canvasRef.current.getContext('2d');
    context.clearRect(0, 0, imgSize[0], imgSize[1]);

    for (let { lesionModule, type, color, show, presentation } of Object.values(
      overlayTypes
    )) {
      if (!show) continue;

      const data =
        lesionModule === 'microcalc'
          ? resultMicrocalcEvaluation
          : lesionModule === 'opacities'
          ? resultOpacitiesEvaluation
          : null;

      if (data) {
        const overlayData = data[proj_name]?.[type];

        if (overlayData) {
          if (
            (tabsIndex === 0 || tabsIndex === 2) &&
            presentation?.includes('overlay')
          ) {
            drawAllShapes(
              overlayData,
              context,
              color,
              show,
              lesionModule,
              false
            );
          } else if (
            (tabsIndex === 0 || tabsIndex === 2) &&
            presentation?.includes('contour')
          ) {
            drawAllShapes(
              overlayData,
              context,
              color,
              show,
              lesionModule,
              true
            );
          }
        }
      }
    }
  }, [
    savingPending,
    overlayTypes,
    resultMicrocalcEvaluation,
    resultOpacitiesEvaluation,
    proj_name,
    tabsIndex,
    diagnosticsInRevisionMode,
    imgSize,
    canvasRef?.current?.width,
    canvasRef?.current?.height,
  ]);

  // Effects
  useEffect(() => {
    const newOverlayTypes = {
      microcalcBirads2: {
        lesionModule: 'microcalc',
        type: 'birads2',
        color: theme.palette.lesions.birads2,
        show: microcalcOverlaysToShow.birads2?.[proj_name?.toLowerCase()],
        presentation: presentation_microcalc,
        boxShadow: '0px 0px 2px 1px #155711, 0px 0px 3px #27d51b',
      },
      microcalcBirads3: {
        lesionModule: 'microcalc',
        type: 'birads3',
        color: theme.palette.lesions.birads3,
        show: microcalcOverlaysToShow.birads3?.[proj_name?.toLowerCase()],
        presentation: presentation_microcalc,
        boxShadow:
          '0px 0px 1px 1px rgb(139 88 13), 0px 0px 1px rgb(243 181 88)',
      },
      microcalcBirads4: {
        lesionModule: 'microcalc',
        type: 'birads4',
        color: theme.palette.lesions.birads4,
        show: microcalcOverlaysToShow.birads4?.[proj_name?.toLowerCase()],
        presentation: presentation_microcalc,
        boxShadow:
          '0px 0px 1px 1px rgb(137, 89, 16), 0px 0px 2px rgb(203, 152, 31)',
      },
      microcalcBirads5: {
        lesionModule: 'microcalc',
        type: 'birads5',
        color: theme.palette.lesions.birads5,
        show: microcalcOverlaysToShow.birads5?.[proj_name?.toLowerCase()],
        presentation: presentation_microcalc,
        boxShadow:
          '0px 0px 1px 1px rgb(147, 28, 28), 0px 0px 2px rgb(181, 70, 70)',
      },
      lesionBirads2: {
        lesionModule: 'opacities',
        type: 'birads2',
        color: theme.palette.lesions.birads2,
        show: opacitiesOverlaysToShow.birads2?.[proj_name?.toLowerCase()],
        presentation: presentation_lesions,
        boxShadow: '0px 0px 2px 1px #155711, 0px 0px 3px #27d51b',
      },
      lesionBirads3: {
        lesionModule: 'opacities',
        type: 'birads3',
        color: theme.palette.lesions.birads3,
        show: opacitiesOverlaysToShow.birads3?.[proj_name?.toLowerCase()],
        presentation: presentation_lesions,
        boxShadow:
          '0px 0px 1px 1px rgb(139 88 13), 0px 0px 1px rgb(243 181 88)',
      },
      lesionBirads4: {
        lesionModule: 'opacities',
        type: 'birads4',
        color: theme.palette.lesions.birads4,
        show: opacitiesOverlaysToShow.birads4?.[proj_name?.toLowerCase()],
        presentation: presentation_lesions,
        boxShadow:
          '0px 0px 1px 1px rgb(137, 89, 16), 0px 0px 2px rgb(203, 152, 31)',
      },
      lesionBirads5: {
        lesionModule: 'opacities',
        type: 'birads5',
        color: theme.palette.lesions.birads5,
        show: opacitiesOverlaysToShow.birads5?.[proj_name?.toLowerCase()],
        presentation: presentation_lesions,
        boxShadow:
          '0px 0px 1px 1px rgb(147, 28, 28), 0px 0px 2px rgb(181, 70, 70)',
      },
      lesionKnown: {
        lesionModule: 'opacities',
        type: 'lesionKnown',
        color: theme.palette.lesions.lesionKnown,
        show: opacitiesOverlaysToShow.lesionKnown?.[proj_name?.toLowerCase()],
        presentation: presentation_lesions,
        boxShadow: '0px 0px 1px 1px #04432e, 0px 0px 2px #1cff0d',
      },
    };

    setOverlayTypes(newOverlayTypes);
    setSingleBoxStyles([]);
  }, [
    microcalcOverlaysToShow,
    opacitiesOverlaysToShow,
    presentation_microcalc,
    presentation_lesions,
    customMicrocalc,
    customOpacities,
    diagnosticsInRevisionMode,
    tabsIndex,
  ]);

  useEffect(() => {
    drawOverlays();
  }, [drawOverlays, presentation_microcalc, presentation_lesions]);

  const drawAllShapes = (
    overlayData,
    context,
    color,
    show,
    lesionModule,
    fill
  ) => {
    if (!show || show === false) {
      return;
    }

    if (overlayData) {
      overlayData.forEach((overlay) => {
        if (overlay.contours) {
          overlay.contours.forEach((contour) => {
            if (fill) {
              drawShape(contour, context, color, false, false);
            } else {
              drawShape(contour, context, color, false, true);
            }
          });
        } else if (tabsIndex === 0 && overlay.box && show) {
          drawSingleBox(overlay.box, color, lesionModule, show);
        }
      });
    }
  };

  const drawShape = (points, context, color, subtract, fill) => {
    if (!points || points.length === 0) return;

    if (subtract) context.globalCompositeOperation = 'destination-out';
    else context.globalCompositeOperation = 'source-over';

    context.beginPath();
    const x0 = points[0][0] * imgSize[0];
    const x1 = points[0][1] * imgSize[1];
    context.moveTo(x0, x1);

    let x;
    let y;
    for (let i = 1; i < points.length; i++) {
      x = points[i][0] * imgSize[0];
      y = points[i][1] * imgSize[1];
      context.lineTo(x, y);
    }
    context.closePath();

    if (fill) {
      context.fillStyle = color;
      context.fill();
    } else {
      context.lineWidth = 20;
      context.strokeStyle = color;
      context.stroke();
    }
  };

  const drawSingleBox = (overlayInfo, color, lesionModule, show) => {
    const birads = [2, 3, 4, 5];
    const opacitiesBiradsShown = birads.map((num) =>
      Object.values(opacitiesOverlaysToShow[`birads${num}`]).some(
        (value) => value === true
      )
    );

    const microcalcBiradsShown = birads.map((num) =>
      Object.values(microcalcOverlaysToShow[`birads${num}`]).some(
        (value) => value === true
      )
    );

    if (!show) {
      return;
    }

    const x = overlayInfo?.[0];
    const y = overlayInfo?.[1];
    const w = overlayInfo?.[2];
    const h = overlayInfo?.[3];

    setSingleBoxStyles((prevState) => [
      ...prevState,
      {
        border: color
          ? lesionModule === 'microcalc'
            ? `0.2px dashed ${color}`
            : `0.2px solid ${color}`
          : '0.2px solid cyan',
        left: `${100 * x}%`,
        top: `${100 * y}%`,
        width: `${100 * w}%`,
        height: `${100 * h}%`,
        position: 'absolute',
        cursor: 'default',
      },
    ]);
  };

  useEffect(() => {
    let newBoxIndex;

    if (selectedNewBox && diagnosticsInRevisionMode) {
      const currentBoxLesions =
        selectedNewBox.boxtype === 'microcalc'
          ? resultMicrocalcEvaluation
          : resultOpacitiesEvaluation;

      const movedBoxIndex = currentBoxLesions?.[selectedNewBox.proj]?.[
        selectedNewBox.boxclass
      ]?.findIndex((rectangle) => {
        return (
          rectangle?.box[0] === selectedNewBox?.box?.box[0] &&
          rectangle?.box[1] === selectedNewBox?.box?.box[1] &&
          rectangle?.box[2] === selectedNewBox?.box?.box[2] &&
          rectangle?.box[3] === selectedNewBox?.box?.box[3]
        );
      });

      if (movedBoxIndex < 0) newBoxIndex = 0;
      else newBoxIndex = movedBoxIndex;

      const newBoxClicked = {
        box: `${newBoxIndex}${selectedNewBox.proj}${selectedNewBox?.boxclass}`,
        breast: selectedNewBox.proj,
        type:
          selectedNewBox.boxtype === 'microcalc'
            ? selectedNewBox.boxclass
            : selectedNewBox?.boxclass?.slice(6, 7),
        lesionType: selectedNewBox.boxtype,
        classType: selectedNewBox.boxclass,
        locationIndex: newBoxIndex,
        score:
          currentBoxLesions?.[selectedNewBox.proj]?.[selectedNewBox.boxclass]?.[
            newBoxIndex
          ]?.score,
        color: severityColor(selectedNewBox.boxclass, theme),
      };

      dispatch(
        actions.setSingleBoxHover({
          box: newBoxClicked?.box,
          module: selectedNewBox?.boxtype,
        })
      );
    } else {
      dispatch(actions.setSingleBoxHover(null));
    }
  }, [selectedNewBox]);

  const showEvaluatedBoundingBoxes = (
    lesionType,
    color,
    itemData,
    boxShadow
  ) => {
    if (
      !lesionType ||
      !itemData.show ||
      (!resultMicrocalcEvaluation && !resultOpacitiesEvaluation)
    ) {
      return null;
    }
    const lesionDataList =
      itemData.lesionModule === 'microcalc'
        ? resultMicrocalcEvaluation?.[proj_name]?.[lesionType]
        : resultOpacitiesEvaluation?.[proj_name]?.[lesionType];

    if (!lesionDataList) {
      return null;
    }
    const boundingBoxes = Object.values(lesionDataList).map(
      (lesionData, index) => {
        const lesionIndex =
          lesion_index_mapping?.[`${proj_name},${lesionType},${index}`];

        const [x, y, w, h] = lesionData.box || [0, 0, 0, 0];

        const probTextTop = `calc(${100 * (y + h)}%)`;
        const probTextLeft = proj_name?.includes('r')
          ? x > 0.8
            ? `calc(${100 * (x + w)}% - 20px)`
            : `calc(${100 * x}%)`
          : x > 0.8
          ? `calc(${100 * (x + w)}% - 10px)`
          : `calc(${100 * x}%)`;

        const lesionClass =
          itemData.lesionModule === 'microcalc'
            ? `BI-RADS ${lesionType?.slice(6, 7)}`
            : lesionType === 'lesionKnown'
            ? t('projections.lesionKnown')
            : `BI-RADS ${lesionType?.slice(6, 7)}`;

        const biradsTextTop =
          y <= 0.03 ? `calc(${100 * y + 100 * h}%)` : `calc(${100 * y - 7}%)`;
        const biradsMicrocalcTextTop =
          y <= 0.03 ? `calc(${100 * y + 100 * h}%)` : `calc(${100 * y - 5}%)`;

        const biradsTextLeft = proj_name?.includes('r')
          ? x > 0.4
            ? `calc(${100 * (x + w)}% - 58px)`
            : `calc(${100 * x}%)`
          : x > 0.8
          ? `calc(${100 * (x + w)}% - 20px)`
          : `calc(${100 * x}%)`;

        const lesionKey = `${index}${proj_name}${lesionType}`;
        const hoverBoxId =
          itemData?.lesionModule === 'microcalc'
            ? lesionKey
            : `${proj_name?.[0]?.[0]}${lesionIndex}`;
        const formattedWidth = lesionData?.size?.[0]?.toFixed(2);
        const formattedHeight = lesionData?.size?.[1]?.toFixed(2);

        return (
          <div key={lesionKey}>
            <p
              className={classes.lession_class}
              style={{
                left:
                  biradsTextLeft?.includes('-') && x < 0.1 ? 0 : biradsTextLeft,
                top:
                  itemData.lesionModule === 'microcalc' &&
                  lesionType === 'birads2'
                    ? biradsMicrocalcTextTop
                    : biradsTextTop,
                fontSize:
                  itemData.lesionModule === 'microcalc' &&
                  lesionType === 'birads2'
                    ? theme.fonts.responsiveSmall
                    : theme.fonts.responsiveMedium,
                color: color || 'cyan',
                margin: y <= 0.03 && '2px',
              }}
            >
              {itemData.lesionModule === 'microcalc' && lesionType === 'birads2'
                ? ''
                : lesionClass}
            </p>

            <Tooltip
              arrow
              open={
                zoomedProjection && zoomedProjection !== proj_name
                  ? false
                  : isHovered === lesionKey ||
                    (singleBoxHover?.box === hoverBoxId &&
                      formattedWidth &&
                      formattedHeight)
                  ? true
                  : false
              }
              disableHoverListener={
                formattedWidth && formattedHeight ? false : true
              }
              title={
                <>
                  <Typography
                    className={classes.tooltip_text}
                    style={{ fontWeight: 'bold' }}
                  >
                    {`${t('diagnose.index')}${lesionIndex}`}
                  </Typography>
                  <Divider style={{ marginBottom: '4px' }} />
                  <Typography className={classes.tooltip_text}>
                    {t('projections.lesion_w', {
                      lesionWidth: `${formattedWidth}` || '-',
                    })}
                  </Typography>
                  <Typography className={classes.tooltip_text}>
                    {t('projections.lesion_h', {
                      lesionHeight: `${formattedHeight}` || '-',
                    })}
                  </Typography>
                </>
              }
            >
              <div
                onClick={() => handleBoxClick()}
                onMouseEnter={() =>
                  handleMouseEnterBox(hoverBoxId, itemData?.lesionModule)
                }
                onMouseOver={() =>
                  handleMouseEnterBox(hoverBoxId, itemData?.lesionModule)
                }
                onMouseLeave={() => handleMouseLeaveBox()}
                onContextMenu={(e) =>
                  diagnosticsInRevisionMode &&
                  handleMultipleBoxesMenuClick(
                    proj_name,
                    lesionType,
                    itemData?.lesionModule,
                    lesionData?.match,
                    lesionKey,
                    e
                  )
                }
                className={classes.bounding_rect}
                style={{
                  borderStyle:
                    itemData?.lesionModule === 'microcalc' ? 'dashed' : 'solid',
                  borderColor: color || 'cyan',
                  left: `${100 * x}%`,
                  top: `${100 * y}%`,
                  width: `${100 * w}%`,
                  height: `${100 * h}%`,
                  cursor: zoomedProjection ? 'zoom-out' : 'zoom-in',
                  boxShadow:
                    isHovered === lesionKey ||
                    singleBoxHover?.box === hoverBoxId
                      ? '0px 0px 7px 2px #1eaaaa, 0px 0px 5px 1px rgb(0, 196, 255)'
                      : boxShadow,
                }}
              />
            </Tooltip>

            {itemData?.lesionModule === 'opacities' && lesionIndex && (
              <p
                className={classes.box_index}
                style={{
                  left:
                    probTextLeft?.includes('-') && x < 0.1 ? 0 : probTextLeft,
                  top: probTextTop,
                  background: color || 'cyan',
                }}
              >
                {`#${lesionIndex}`}
              </p>
            )}

            {contextMenu?.rectangleId &&
              getContextMenu(
                contextMenu?.rectangleId === lesionKey ? true : false,
                lesionData,
                lesionData.id
              )}
          </div>
        );
      }
    );

    return boundingBoxes;
  };

  const handleBoxClick = () => {
    if (!zoomedProjection) dispatch(actions.setZoomedProjection(proj_name));
    else {
      dispatch(actions.setZoomedProjection(null));
    }
  };

  // Todo: Migrate Handle hovers
  const handleMouseEnterBox = (lesionKey, module) => {
    setIsHovered(lesionKey);
    dispatch(actions.setSingleBoxHover({ box: lesionKey, module: module }));
  };

  const handleMouseLeaveBox = () => {
    setIsHovered(null);
    dispatch(actions.setSingleBoxHover(null));
  };

  // Todo: Migrate all Drawing functions in revision handlers
  const generateUniqueID = (proj_name, rect) => {
    let rectanglesLength = rect?.length || 0;
    let newID = proj_name + rectanglesLength;

    while (rect?.some((item) => item.id === newID)) {
      rectanglesLength++;
      newID = proj_name + rectanglesLength;
    }
    return newID;
  };

  const startDrawingRectangle = (event) => {
    if (!canvasRef || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    const x = (event.clientX - rect.left) / rect.width;
    const y = (event.clientY - rect.top) / rect.height;

    if (tempDrawingRectangle?.mode === 'drawing') {
      setTempDrawingRectangle((prevState) => ({
        ...prevState,
        endPoint: { x, y },
      }));
    } else
      setTempDrawingRectangle({
        mode: 'drawing',
        startPoint: { x, y },
        endPoint: { x, y },
      });
  };

  const handleDrawingRect = (event) => {
    if (!canvasRef || !canvasRef.current) return;

    if (tempDrawingRectangle?.mode === 'drawing') {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();

      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;

      setTempDrawingRectangle((prevDrawing) => ({
        ...prevDrawing,
        endPoint: { x, y },
      }));
    }
  };

  const stopDrawingRectangle = () => {
    if (!diagnosticsInRevisionMode || !tempDrawingRectangle) {
      return;
    } else if (tempDrawingRectangle?.mode === 'drawing') {
      const newRectangle = {
        id: generateUniqueID(proj_name, noTypeRectangles?.[proj_name]),
        box: [
          tempDrawingRectangle?.startPoint?.x,
          tempDrawingRectangle?.startPoint?.y,
          tempDrawingRectangle?.endPoint?.x -
            tempDrawingRectangle?.startPoint?.x,
          tempDrawingRectangle?.endPoint?.y -
            tempDrawingRectangle?.startPoint?.y,
        ],
        color: 'cyan',
        score: '100%',
      };
      const currentRectangles = noTypeRectangles[proj_name] || [];
      const projectionRectanglesUpdated = [...currentRectangles, newRectangle];
      dispatch(updateNoTypeRectangles(proj_name, projectionRectanglesUpdated));
    }
    setTempDrawingRectangle(null);
  };

  // Temporal Rectangle Handlers
  const showUnassignedBoundingBoxes = (temporalRectangles) => {
    return temporalRectangles.map((rectangle) => {
      const x = rectangle.box[0];
      const y = rectangle.box[1];
      const w = rectangle.box[2];
      const h = rectangle.box[3];

      return (
        <React.Fragment key={rectangle.id}>
          <div
            style={{
              zIndex: 2500,
              left: `${100 * x}%`,
              top: `${100 * y}%`,
              width: `${100 * w}%`,
              height: `${100 * h}%`,
              border: rectangle.color
                ? `2px solid ${rectangle.color}`
                : '2px solid cyan',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              position: 'absolute',
              cursor: 'pointer',
              boxShadow:
                isHovered === rectangle.id && '0px 0px 5px 1px rgb(0 196 255)',
            }}
            onMouseOver={() => setIsHovered(rectangle.id)}
            onMouseMove={handleBoxDragging}
            onMouseUp={handleUnassignedMouseUp}
            onMouseLeave={handleUnassignedMouseUp}
            onMouseDown={(e) => handleUnassignedBoxClick(rectangle.id, e)}
            onContextMenu={(e) =>
              handleNewMenuClick(proj_name, rectangle.id, e)
            }
          />
          {contextMenu?.rectangleId === rectangle.id &&
            getContextMenu(
              contextMenu?.rectangleId === rectangle.id,
              rectangle,
              rectangle.id
            )}
        </React.Fragment>
      );
    });
  };

  const handleUnassignedBoxClick = (id, e) => {
    e.preventDefault();
    if (!canvasRef || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    const rectangle = noTypeRectangles?.[proj_name]?.find(
      (rect) => rect.id === id
    );

    if (rectangle) {
      setDragging({
        id,
        offsetX: offsetX - rectangle.box[0] * rect.width,
        offsetY: offsetY - rectangle.box[1] * rect.height,
      });
    }
  };

  const handleBoxDragging = (e) => {
    if (!canvasRef || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (dragging !== null) {
      const x = (e.clientX - rect.left) / rect.width;
      const y = (e.clientY - rect.top) / rect.height;

      const newX = x - dragging.offsetX / rect.width;
      const newY = y - dragging.offsetY / rect.height;

      const updatedRectangles = noTypeRectangles?.[proj_name]?.map((rect) =>
        rect.id === dragging.id
          ? {
              ...rect,
              box: [newX, newY, rect.box[2], rect.box[3]],
            }
          : rect
      );

      dispatch(updateNoTypeRectangles(proj_name, updatedRectangles));
    }
  };

  const handleUnassignedMouseUp = () => {
    setDragging(null);
    setIsHovered(false);
  };

  const closeMenu = () => {
    setSelectedOption({});
    setContextMenu(null);
  };

  // Boxes Context Menu
  const getContextMenu = (open, rectangle, i) => {
    const renderMenuItems = (items, type) =>
      items.map((item) => (
        <MenuItem
          key={item.label}
          disabled={
            contextMenu?.rectangleType === type &&
            contextMenu.rectangleClass === item.class
          }
          className={classes.menu_item_text}
          onClick={() => handleMenuItemClick(item.class)}
          style={{ color: theme.palette.lesions[item.class] }}
        >
          {t(`projections.${item.label}`)}
        </MenuItem>
      ));

    const microcalcClassesItems = [
      { label: 'birads_2', class: 'birads2' },
      { label: 'birads_3', class: 'birads3' },
      { label: 'birads_4', class: 'birads4' },
      { label: 'birads_5', class: 'birads5' },
      // { label: 'vassels', class: 'vassels' },
    ];

    const opacitiesClassesItems = [
      { label: 'birads_2', class: 'birads2' },
      { label: 'birads_3', class: 'birads3' },
      { label: 'birads_4', class: 'birads4' },
      { label: 'birads_5', class: 'birads5' },
      { label: 'lesionKnown', class: 'lesionKnown' },
    ];

    return (
      <Menu
        keepMounted
        open={open}
        onClose={closeMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() =>
            setSelectedOption({ option: 'reassign', rectId: rectangle.id })
          }
          className={classes.menu_item_container}
        >
          <Typography className={classes.menu_item_text}>
            {rectangle.color === 'cyan'
              ? t('projections.assign')
              : t('projections.reassign')}
          </Typography>
          <ArrowForwardIosIcon
            fontSize="small"
            className={classes.menu_item_icon}
          />
        </MenuItem>

        <Menu
          open={contextMenu && selectedOption?.option === 'reassign'}
          onClose={closeMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu && selectedOption?.option === 'reassign'
              ? { top: contextMenu.mouseY + 20, left: contextMenu.mouseX + 94 }
              : undefined
          }
        >
          {DIAGNOSTICS_MICROCALC && (
            <MenuItem
              onClick={() =>
                setSelectedOption({ ...selectedOption, type: 'microcalc' })
              }
              className={classes.menu_item_container}
            >
              <Typography className={classes.menu_item_text}>
                {t('projections.microcalcifications')}
              </Typography>
              <ArrowForwardIosIcon
                fontSize="small"
                className={classes.menu_item_icon}
              />
            </MenuItem>
          )}
          <Menu
            open={contextMenu && selectedOption?.type === 'microcalc'}
            onClose={closeMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu && selectedOption?.type === 'microcalc'
                ? {
                    top: contextMenu.mouseY + 40,
                    left: contextMenu.mouseX + 200,
                  }
                : undefined
            }
          >
            {renderMenuItems(microcalcClassesItems, 'microcalc')}
          </Menu>

          {DIAGNOSTICS_OPACITIES && (
            <MenuItem
              onClick={() =>
                setSelectedOption({ ...selectedOption, type: 'opacities' })
              }
              className={classes.menu_item_container}
            >
              <Typography className={classes.menu_item_text}>
                {t('projections.opacity')}
              </Typography>
              <ArrowForwardIosIcon
                fontSize="small"
                className={classes.menu_item_icon}
              />
            </MenuItem>
          )}

          <Menu
            open={contextMenu && selectedOption?.type === 'opacities'}
            onClose={closeMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu && selectedOption?.type === 'opacities'
                ? {
                    top: contextMenu.mouseY + 70,
                    left: contextMenu.mouseX + 200,
                  }
                : undefined
            }
          >
            {renderMenuItems(opacitiesClassesItems, 'opacities')}
          </Menu>
        </Menu>

        <MenuItem
          onClick={() => handleMenuItemClick('delete', rectangle.id)}
          onMouseOver={() => setSelectedOption({ type: 'delete' })}
          className={classes.menu_item_container}
        >
          <Typography className={classes.menu_item_text}>
            {t('projections.delete')}
          </Typography>
          <DeleteIcon fontSize="small" className={classes.menu_item_icon} />
        </MenuItem>
      </Menu>
    );
  };

  const handleMultipleBoxesMenuClick = (
    projection,
    boxClass,
    lesionModule,
    matchBox,
    boxId,
    e
  ) => {
    e.preventDefault();

    const mouseX = e.clientX - 2;
    const mouseY = e.clientY - 4;
    const rectangleId = boxId;
    const rectangleType = lesionModule;
    const rectangleClass = boxClass;
    const rectangleProjection = projection;

    setContextMenu({
      mouseX,
      mouseY,
      rectangleId,
      rectangleProjection,
      rectangleType,
      rectangleClass,
      matchBox,
    });
  };

  const handleNewMenuClick = (projection, boxId, e) => {
    e.preventDefault();
    setContextMenu({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
      rectangleId: boxId,
      rectangleProjection: projection,
    });
  };

  const handleMenuItemClick = (action) => {
    if (action === 'delete') {
      const customResults =
        contextMenu.rectangleType === 'opacities'
          ? customOpacities
          : customMicrocalc;

      handleDeleteButtonClick(
        contextMenu,
        setContextMenu,
        customResults,
        noTypeRectangles,
        dispatch,
        actions
      );
    } else {
      const resultFindings =
        contextMenu.rectangleType === 'microcalc'
          ? resultMicrocalcEvaluation
          : resultOpacitiesEvaluation;

      handleReassignButtonClick(
        selectedOption?.type,
        action,
        contextMenu,
        resultFindings,
        customMicrocalc,
        customOpacities,
        noTypeRectangles,
        setContextMenu,
        setSelectedOption,
        setSelectedNewBox,
        dispatch,
        actions
      );
    }

    closeMenu();
  };

  return (
    <div
      className={classes.container_overlay}
      onWheel={onWheel}
      onContextMenu={(e) => e.preventDefault()}
      onMouseMove={onMouseMove}
      onMouseLeave={() =>
        tempDrawingRectangle?.mode === 'drawing' &&
        setTempDrawingRectangle(null)
      }
    >
      {tempDrawingRectangle && tempDrawingRectangle?.mode === 'drawing' && (
        <div
          style={{
            zIndex: 2500,
            left: `${100 * tempDrawingRectangle?.startPoint?.x}%`,
            top: `${100 * tempDrawingRectangle?.startPoint?.y}%`,
            width: `${
              100 *
              (tempDrawingRectangle?.endPoint.x -
                tempDrawingRectangle?.startPoint.x)
            }%`,
            height: `${
              100 *
              (tempDrawingRectangle?.endPoint.y -
                tempDrawingRectangle?.startPoint.y)
            }%`,
            border: '2px solid cyan',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            position: 'absolute',
          }}
        />
      )}

      {Object.keys(overlayTypes).map(
        (type, i) =>
          (overlayTypes[type].show || diagnosticsInRevisionMode) &&
          showEvaluatedBoundingBoxes(
            overlayTypes[type].type,
            overlayTypes[type].color,
            overlayTypes[type],
            overlayTypes[type].boxShadow
          )
      )}

      {noTypeRectangles?.[proj_name] &&
        showUnassignedBoundingBoxes(noTypeRectangles?.[proj_name])}
      {!contextMenu && (
        <canvas
          key="overlays"
          className={classes.canvas_overlay}
          ref={canvasRef}
          width={imgSize[0]}
          height={imgSize[1]}
          onMouseDown={
            diagnosticsInRevisionMode && !contextMenu
              ? startDrawingRectangle
              : undefined
          }
          onMouseMove={handleDrawingRect}
          onMouseUp={stopDrawingRectangle}
          style={{
            pointerEvents: contextMenu ? 'none' : 'auto',
            transform: zoomedProjection && 'scale(1)',
          }}
        />
      )}

      {/* {tabsIndex === 0 &&
        singleBoxStyles.length > 0 &&
        singleBoxStyles.map((boxStyle, index) => (
          <div key={index} className={classes.bounding_rect} style={boxStyle} />
        ))} */}
    </div>
  );
};

export default DiagnoseOverlays;
