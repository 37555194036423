import { deleteFinding, getMatchedProj } from './DiagnosticRevisionTableCommon';

export const getBoxes = (resultData, breast, type, lesionType, theme, t) => {
  const boxes = [];
  const typeMatch = type.match(/\d+/);

  if (resultData[breast] && resultData[breast][type]) {
    resultData[breast][type].forEach((item, index) => {
      boxes.push({
        box: `${index}${breast}${type}`,
        breast: breast,
        type: typeMatch ? parseInt(typeMatch[0]) : t('projections.lesionKnown'),
        lesionType: lesionType,
        classType: type,
        locationIndex: index,
        score: item.score ? item.score * 100 : null,
        reassigned: item?.reassigned,
        assigned: item?.assigned,
      });
    });
  }
  return boxes;
};

export const changeBiradsToShow = (
  type,
  shouldShow,
  biradsToShow,
  dispatch,
  actions
) => {
  if (type === 'opacities') {
    biradsToShow?.forEach((birads) =>
      dispatch(actions.changeOpacitiesToShow(birads, shouldShow))
    );
  } else if (type === 'microcalc') {
    biradsToShow?.forEach((birads) =>
      dispatch(actions.changeMicrocalcToShow(birads, shouldShow))
    );
  }
};

export const handleFilteringLesionType = (overlayClass, dispatch, actions) => {
  dispatch(actions.hideAllLesions());

  if (overlayClass === 'opacities') {
    dispatch(actions.changeOpacitiesToShow('birads2', true));
    dispatch(actions.changeOpacitiesToShow('birads3', true));
    dispatch(actions.changeOpacitiesToShow('birads4', true));
    dispatch(actions.changeOpacitiesToShow('birads5', true));
    dispatch(actions.changeOpacitiesToShow('lesionKnown', true));
  } else if (overlayClass === 'microcalc') {
    dispatch(actions.changeMicrocalcToShow('birads2', true));
    dispatch(actions.changeMicrocalcToShow('birads3', true));
    dispatch(actions.changeMicrocalcToShow('birads4', true));
    dispatch(actions.changeMicrocalcToShow('birads5', true));
  }
  // dispatch(actions.setZoomedProjection(null));
};

export const lesionExists = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!lesionExists(obj[key])) {
          return false;
        }
      } else if (obj[key] !== null && obj[key] !== undefined) {
        return false;
      }
    }
  }
  return true;
};

export const tableRowClickHandler = (
  proj,
  dispatch,
  actions,
  zoomedProjection
) => {
  if (zoomedProjection !== proj) {
    dispatch(actions.setZoomedProjection(proj));
  } else if (zoomedProjection === proj) {
    dispatch(actions.setZoomedProjection(null));
  }
};

export const handleMouseEnterRow = (module, boxIndex, dispatch, actions) => {
  dispatch(actions.setSingleBoxHover({ box: boxIndex, module: module }));
};

export const handleMouseLeaveRow = (dispatch, actions) => {
  dispatch(actions.setSingleBoxHover(null));
};

export const hideAndResetActions = (dispatch, actions) => {
  dispatch(actions.setZoomedProjection(null));
};
export const processCCProjection = (
  breast,
  groupedBoxes,
  projection,
  biradsKey,
  box,
  boxIndex,
  lesion_index_mapping
) => {
  const matchedProjection = projection === 'rcc' ? 'rmlo' : 'lmlo';
  const currentIndex = groupedBoxes[breast].length + 1;

  const matchedLesion = box?.match;
  const lesionEntry = [
    [projection, biradsKey, boxIndex],
    matchedLesion
      ? [matchedProjection, matchedLesion[0], matchedLesion[1]]
      : null,
  ];

  groupedBoxes[breast].push(lesionEntry);

  lesion_index_mapping[`${projection},${biradsKey},${boxIndex}`] = currentIndex;
  if (matchedLesion) {
    lesion_index_mapping[
      `${matchedProjection},${matchedLesion[0]},${matchedLesion[1]}`
    ] = currentIndex;
  }
};

export const processMLOProjection = (
  breast,
  groupedBoxes,
  projection,
  biradsKey,
  box,
  boxIndex,
  lesion_index_mapping
) => {
  const matchedLesion = box?.match;

  if (!matchedLesion) {
    const currentIndex = groupedBoxes[breast].length + 1;

    const lesionEntry = [[projection, biradsKey, boxIndex], null];
    groupedBoxes[breast].push(lesionEntry);

    lesion_index_mapping[`${projection},${biradsKey},${boxIndex}`] =
      currentIndex;
  }
};

//Matching - Upscale / Downscale and Index alignment logic
const upscaleLesionsClass = (
  newOpacitiesResult,
  higherBreast,
  higherClass,
  higherIndex,
  lowerBreast,
  lowerClass,
  lowerIndex,
  lowerClassLesions,
  prevLowerClass
) => {
  if (!newOpacitiesResult[lowerBreast][higherClass]) {
    newOpacitiesResult[lowerBreast][higherClass] = [];
  }

  // Update the higher class with the new lower updated match and predicted_class
  newOpacitiesResult[lowerBreast][higherClass].push({
    ...newOpacitiesResult[lowerBreast][higherClass][0],
    ...lowerClassLesions?.[lowerIndex],
    match: [higherClass, Number(higherIndex)],
    predicted_class: prevLowerClass ? prevLowerClass : lowerClass,
    score: 1,
  });

  // Update the higher breast match and score
  newOpacitiesResult[higherBreast][higherClass][higherIndex] = {
    ...newOpacitiesResult[higherBreast][higherClass][higherIndex],
    match: [
      higherClass,
      newOpacitiesResult[lowerBreast][higherClass].length > 0
        ? newOpacitiesResult[lowerBreast][higherClass].length - 1
        : 0,
    ],
    score: 1,
  };

  // Remove the lesion from the lower class
  newOpacitiesResult[lowerBreast][lowerClass].splice(lowerIndex, 1);

  if (newOpacitiesResult[lowerBreast][lowerClass].length === 0) {
    newOpacitiesResult[lowerBreast][lowerClass] = null;
  }

  // Decrement the index in the "match" values for the matching lesions of the lower Birads class
  if (lowerClassLesions.length > 0) {
    for (let i = Number(lowerIndex); i < lowerClassLesions.length; i++) {
      const lesion = lowerClassLesions[i];
      if (lesion && lesion.match) {
        const [matchBirads, matchIdx] = lesion.match;
        const match =
          newOpacitiesResult[higherBreast][matchBirads][matchIdx]?.match;
        if (match) {
          match[1] -= 1;
        }
      }
    }
  }
  return newOpacitiesResult;
};

// Determines the higher class between two given classes
const findHigherClass = (class1, class2) => {
  const classOrder = [
    'birads2',
    'birads3',
    'birads4',
    'birads5',
    'lesionKnown',
  ];
  const class1Index = classOrder.indexOf(class1);
  const class2Index = classOrder.indexOf(class2);
  return class1Index > class2Index ? class1 : class2;
};

// Update lesion information in the newOpacitiesResult
const updateLesionInfo = (opacities, breast, classType, index, updates) => {
  if (!opacities[breast][classType]) {
    opacities[breast][classType] = [];
  }
  opacities[breast][classType][index] = {
    ...opacities[breast][classType][index],
    ...updates,
  };
};

// Removes a lesion from the given class and updates match indices
export const removeLesionAndUpdateIndices = (
  opacities,
  breast,
  classType,
  lesionIndex
) => {
  const matchProj = getMatchedProj(breast);
  const otherProjLesions = opacities?.[matchProj];

  // Remove the lesion from the specified class
  opacities[breast][classType].splice(lesionIndex, 1);

  // Set the class array to null if it's empty after removal
  if (opacities[breast][classType].length === 0) {
    opacities[breast][classType] = null;
  }

  // Iterate through each birads level in otherProjLesions
  if (otherProjLesions) {
    for (const biradsKey in otherProjLesions) {
      const lesions = otherProjLesions[biradsKey];

      if (Array.isArray(lesions)) {
        for (let i = 0; i < lesions.length; i++) {
          const lesion = lesions[i];
          if (lesion && lesion.match) {
            const [matchBirads, matchIdx] = lesion.match;
            if (matchBirads === classType && matchIdx > lesionIndex) {
              lesion.match[1] -= 1;
            }
          }
        }
      }
    }
  }
};

// update lesion with existing matches
export const handleMatchedLesion = (
  opacities,
  matchedLesion,
  matchedProjection,
  currentProjection
) => {
  const [classType, locationIndex] = matchedLesion;
  const matchedPrevClass =
    opacities[matchedProjection][classType][locationIndex];

  if (matchedPrevClass?.predicted_class) {
    const prevMatchedClass = matchedPrevClass.predicted_class;
    delete matchedPrevClass.match;
    delete matchedPrevClass.predicted_class;

    //  prevClass array if it doesn't exist
    if (!opacities[matchedProjection][prevMatchedClass]) {
      opacities[matchedProjection][prevMatchedClass] = [];
    }
    // Move the box to the new class
    opacities[matchedProjection][prevMatchedClass].push(matchedPrevClass);

    // Remove lesion from current class and update indices
    removeLesionAndUpdateIndices(
      opacities,
      matchedProjection,
      classType,
      locationIndex
    );
  } else {
    matchedPrevClass.match = null;
  }
};

// DROP LESION IN DRAG AND DROP TABLE
export const handleDragEnd = (
  result,
  customOpacities,
  setIsDraging,
  groupedOpacities,
  dispatch,
  actions,
  t
) => {
  setIsDraging(false);

  if (!result.destination) return;

  const selectedLesion = result.draggableId.match(
    /(\d+)([a-z]+)(birads\d+|lesionKnown)/
  );
  const [, currentMovingIndex, currentMovingBreast, currentMovingBirads] =
    selectedLesion;

  const destinationIndexInTable = result.destination.droppableId;
  const destinationProjection =
    groupedOpacities?.[result.source.droppableId]?.[
      destinationIndexInTable
    ]?.[0]?.[0];

  const selectedLesionInfo =
    customOpacities[currentMovingBreast][currentMovingBirads][
      currentMovingIndex
    ];

  const matchedLesion =
    customOpacities[currentMovingBreast][currentMovingBirads][
      currentMovingIndex
    ]?.match;

  const matchedProjection = getMatchedProj(currentMovingBreast);

  if (
    destinationIndexInTable === 'RightNewIndex' ||
    destinationIndexInTable === 'LeftNewIndex'
  ) {
    if (selectedLesionInfo) {
      const newOpacitiesResult = JSON.parse(JSON.stringify(customOpacities));
      const updatedLesionInfo = {
        ...selectedLesionInfo,
        match: null,
        predicted_class: null,
      };

      if (selectedLesionInfo.predicted_class) {
        updateLesionInfo(
          newOpacitiesResult,
          currentMovingBreast,
          currentMovingBirads,
          0,
          updatedLesionInfo
        );

        // Update the prev class with the updated lesion
        if (
          !newOpacitiesResult[currentMovingBreast][
            selectedLesionInfo.predicted_class
          ]
        ) {
          newOpacitiesResult[currentMovingBreast][
            selectedLesionInfo.predicted_class
          ] = [];
        }

        // Push the updatedLesionInfo into the previous class
        newOpacitiesResult[currentMovingBreast][
          selectedLesionInfo.predicted_class
        ].push(updatedLesionInfo);
        removeLesionAndUpdateIndices(
          newOpacitiesResult,
          currentMovingBreast,
          currentMovingBirads,
          currentMovingIndex,
          newOpacitiesResult[currentMovingBreast][currentMovingBirads]
        );
      } else {
        updateLesionInfo(
          newOpacitiesResult,
          currentMovingBreast,
          currentMovingBirads,
          currentMovingIndex,
          {
            match: null,
          }
        );
      }

      if (matchedLesion) {
        handleMatchedLesion(
          newOpacitiesResult,
          matchedLesion,
          matchedProjection,
          currentMovingBreast
        );
      }

      dispatch(
        actions.setSnackbar({
          msg: t('diagnose.new_index'),
          severity: 'success',
        })
      );
      dispatch(actions.setCustomOpacities(newOpacitiesResult));
      dispatch(actions.setOpacitiesLocalChanges(true));
    } else {
      dispatch(
        actions.setSnackbar({
          msg: t('diagnose.invalid_match'),
          severity: 'warning',
        })
      );
    }
  } else if (currentMovingBreast === destinationProjection) {
    dispatch(
      actions.setSnackbar({
        msg: t('diagnose.error_same_proj'),
        severity: 'warning',
      })
    );
  } else if (
    result.source.droppableId === destinationIndexInTable ||
    !destinationIndexInTable
  ) {
    dispatch(
      actions.setSnackbar({
        msg: t('diagnose.error_matching'),
        severity: 'warning',
      })
    );
    return;
  } else {
    const destinationLesion =
      groupedOpacities?.[result.source.droppableId]?.[
        destinationIndexInTable
      ]?.[0];

    const [
      destinationMovingBreast,
      destinationMovingBirads,
      destinationLesionIndex,
    ] = destinationLesion;

    if (result.draggableId) {
      const lesionsSameClass = currentMovingBirads === destinationMovingBirads;
      const newOpacitiesResult = JSON.parse(JSON.stringify(customOpacities));
      const higherClass = findHigherClass(
        currentMovingBirads,
        destinationMovingBirads
      );
      const higherBreast =
        higherClass === currentMovingBirads
          ? currentMovingBreast
          : destinationMovingBreast;
      const higherIndex =
        higherClass === currentMovingBirads
          ? currentMovingIndex
          : destinationLesionIndex;
      const lowerBreast =
        higherClass === currentMovingBirads
          ? destinationMovingBreast
          : currentMovingBreast;
      const lowerClass =
        higherClass === currentMovingBirads
          ? destinationMovingBirads
          : currentMovingBirads;
      const lowerIndex =
        higherClass === currentMovingBirads
          ? destinationLesionIndex
          : currentMovingIndex;

      const higherPreviousClass =
        newOpacitiesResult[higherBreast][higherClass][higherIndex]
          ?.predicted_class;
      const lowerPreviousClass =
        newOpacitiesResult[lowerBreast][lowerClass][lowerIndex]
          ?.predicted_class;

      if (higherPreviousClass || lowerPreviousClass) {
        const higherHighClass = higherPreviousClass
          ? higherPreviousClass
          : higherClass;
        const lowerHighClass = lowerPreviousClass
          ? lowerPreviousClass
          : lowerClass;
        const newHighClass = findHigherClass(higherHighClass, lowerHighClass);

        // CASE 1 - High class has previous class (but lower class dont)
        if (higherPreviousClass && !lowerPreviousClass) {
          // compare previous high class to lower
          const highestClass = findHigherClass(higherPreviousClass, lowerClass);

          // upscale higher lesion or lower lesion acordingly
          if (highestClass === lowerClass) {
            // upscale high lesion
            const updatedHigherLesionInfo = {
              ...newOpacitiesResult[higherBreast][higherClass][higherIndex],
              match: [lowerClass, lowerIndex],
              predicted_class:
                higherPreviousClass === highestClass
                  ? null
                  : higherPreviousClass,
            };

            updateLesionInfo(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              updatedHigherLesionInfo
            );

            if (!newOpacitiesResult[higherBreast][highestClass]) {
              newOpacitiesResult[higherBreast][highestClass] = [];
            }

            newOpacitiesResult[higherBreast][highestClass].push(
              updatedHigherLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              newOpacitiesResult[higherBreast][higherClass]
            );

            newOpacitiesResult[lowerBreast][lowerClass][lowerIndex].match = [
              highestClass,
              newOpacitiesResult[higherBreast][highestClass].length - 1,
            ];

            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          } else {
            // upscale low lesion
            const updatedLowerLesionInfo = {
              ...newOpacitiesResult[lowerBreast][lowerClass][lowerIndex],
              match: null,
              predicted_class: lowerPreviousClass,
            };

            updateLesionInfo(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              updatedLowerLesionInfo
            );

            if (!newOpacitiesResult[lowerBreast][highestClass]) {
              newOpacitiesResult[lowerBreast][highestClass] = [];
            }

            newOpacitiesResult[lowerBreast][highestClass].push(
              updatedLowerLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              newOpacitiesResult[lowerBreast][lowerClass]
            );

            const newLowerIndex =
              newOpacitiesResult[lowerBreast][highestClass]?.length || 0;

            // upscale high lesion to previous class
            const updatedHigherLesionInfo = {
              ...newOpacitiesResult[higherBreast][higherClass][higherIndex],
              match: [highestClass, newLowerIndex - 1],
              predicted_class: null,
            };

            updateLesionInfo(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              updatedHigherLesionInfo
            );

            if (!newOpacitiesResult[higherBreast][highestClass]) {
              newOpacitiesResult[higherBreast][highestClass] = [];
            }

            newOpacitiesResult[higherBreast][highestClass].push(
              updatedHigherLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              newOpacitiesResult[higherBreast][higherClass]
            );

            const newHigherIndex =
              newOpacitiesResult[lowerBreast][highestClass]?.length - 1 || 0;

            // update match of the lower
            newOpacitiesResult[lowerBreast][highestClass][newLowerIndex].match =
              [highestClass, newHigherIndex];

            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          }
        }
        // CASE 2 : Lower has previos class but higher dont
        else if (!higherPreviousClass && lowerPreviousClass) {
          // compare previous low class to higher
          const highestClass = findHigherClass(higherClass, lowerPreviousClass);
          if (highestClass === higherClass) {
            // Update lower lesion with new high class and push it
            const updatedLowerLesionInfo = {
              ...newOpacitiesResult[lowerBreast][lowerClass][lowerIndex],
              match: [higherClass, higherIndex],
              predicted_class: lowerPreviousClass,
            };

            updateLesionInfo(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              updatedLowerLesionInfo
            );

            if (!newOpacitiesResult[lowerBreast][highestClass]) {
              newOpacitiesResult[lowerBreast][highestClass] = [];
            }

            newOpacitiesResult[lowerBreast][highestClass].push(
              updatedLowerLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              newOpacitiesResult[lowerBreast][lowerClass]
            );

            // Update higher match with the new lower lesion
            newOpacitiesResult[higherBreast][higherClass][higherIndex].match = [
              highestClass,
              newOpacitiesResult[lowerBreast][highestClass]?.length - 1 || 0,
            ];

            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          } else {
            // upscale low lesion
            const updatedLowerLesion = {
              ...newOpacitiesResult[lowerBreast][lowerClass][lowerIndex],
              match: null,
              predicted_class: null,
            };

            updateLesionInfo(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              updatedLowerLesion
            );

            if (!newOpacitiesResult[lowerBreast][highestClass]) {
              newOpacitiesResult[lowerBreast][highestClass] = [];
            }

            newOpacitiesResult[lowerBreast][highestClass].push(
              updatedLowerLesion
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              newOpacitiesResult[lowerBreast][lowerClass]
            );

            const newLowerIndex =
              newOpacitiesResult[lowerBreast][lowerPreviousClass]?.length || 0;

            // upscale high lesion to previous class
            const updatedHigherLesionInfo = {
              ...newOpacitiesResult[higherBreast][higherClass][higherIndex],
              match: [lowerPreviousClass, newLowerIndex - 1],
              predicted_class: higherClass,
            };

            updateLesionInfo(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              updatedHigherLesionInfo
            );

            if (!newOpacitiesResult[higherBreast][highestClass]) {
              newOpacitiesResult[higherBreast][highestClass] = [];
            }

            newOpacitiesResult[higherBreast][highestClass].push(
              updatedHigherLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              newOpacitiesResult[higherBreast][higherClass]
            );

            const newHigherIndex =
              newOpacitiesResult[lowerBreast][highestClass]?.length - 1 || 0;

            // update indexe of the lower
            newOpacitiesResult[lowerBreast][highestClass][newLowerIndex].match =
              [highestClass, newHigherIndex];

            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          }
        }
        // CASE 3: BOTH lesions have previous classes'
        else if (higherPreviousClass && lowerPreviousClass) {
          const newHigherClass = findHigherClass(
            higherPreviousClass,
            lowerPreviousClass
          );
          if (newHigherClass === higherPreviousClass) {
            // upscale high lesion to previous one
            const updatedHigherLesionInfo = {
              ...newOpacitiesResult[higherBreast][higherClass][higherIndex],
              match: null,
              predicted_class: null,
            };

            updateLesionInfo(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              updatedHigherLesionInfo
            );

            if (!newOpacitiesResult[higherBreast][newHigherClass]) {
              newOpacitiesResult[higherBreast][newHigherClass] = [];
            }

            newOpacitiesResult[higherBreast][newHigherClass].push(
              updatedHigherLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              newOpacitiesResult[higherBreast][higherClass]
            );

            // update lower
            const updatedLowerLesionInfo = {
              ...newOpacitiesResult[lowerBreast][lowerClass][lowerIndex],
              match: [
                newHigherClass,
                newOpacitiesResult[higherBreast][newHigherClass]?.length - 1 ||
                  0,
              ],
              predicted_class: lowerPreviousClass,
            };

            updateLesionInfo(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              updatedLowerLesionInfo
            );

            if (!newOpacitiesResult[lowerBreast][newHigherClass]) {
              newOpacitiesResult[lowerBreast][newHigherClass] = [];
            }

            newOpacitiesResult[lowerBreast][newHigherClass].push(
              updatedLowerLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              newOpacitiesResult[lowerBreast][lowerClass]
            );

            // update index from higher one
            [newOpacitiesResult[higherBreast][newHigherClass]?.length].match = [
              newHigherClass,
              newOpacitiesResult[lowerBreast][newHigherClass]?.length - 1 || 0,
            ];
          } else {
            //  upscale lower and Change class from higher to  lowerPreviousClass
            const updatedLowerLesionInfo = {
              ...newOpacitiesResult[lowerBreast][lowerClass][lowerIndex],
              match: null,
              predicted_class: null,
            };

            if (!newOpacitiesResult[lowerBreast][newHigherClass]) {
              newOpacitiesResult[lowerBreast][newHigherClass] = [];
            }

            newOpacitiesResult[lowerBreast][newHigherClass].push(
              updatedLowerLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              newOpacitiesResult[lowerBreast][lowerClass]
            );

            const newLowIndex =
              newOpacitiesResult[lowerBreast][newHigherClass].length;

            const updatedLesionInfo = {
              ...newOpacitiesResult[higherBreast][higherClass][higherIndex],
              match: [newHigherClass, newLowIndex],
              predicted_class: higherPreviousClass,
            };

            if (!newOpacitiesResult[higherBreast][newHigherClass]) {
              newOpacitiesResult[higherBreast][newHigherClass] = [];
            }

            newOpacitiesResult[higherBreast][newHigherClass].push(
              updatedLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              newOpacitiesResult[higherBreast][higherClass]
            );

            newOpacitiesResult[lowerBreast][lowerPreviousClass][
              newLowIndex
            ].match = [
              lowerPreviousClass,
              newOpacitiesResult[higherBreast][newHigherClass].length - 1,
            ];

            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          }
        }
        // Case 4: classes are the same
        else if (
          newHighClass === higherHighClass &&
          newHighClass === lowerHighClass
        ) {
          // Case2 : New high class is from the higher class
          if (newHighClass === higherClass && !higherPreviousClass) {
            // Update lower lesion with new high class and push it
            const updatedLowerLesionInfo = {
              ...newOpacitiesResult[lowerBreast][lowerClass][lowerIndex],
              match: [higherClass, higherIndex],
              predicted_class: lowerPreviousClass,
            };

            updateLesionInfo(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              updatedLowerLesionInfo
            );

            if (!newOpacitiesResult[lowerBreast][newHighClass]) {
              newOpacitiesResult[lowerBreast][newHighClass] = [];
            }

            newOpacitiesResult[lowerBreast][newHighClass].push(
              updatedLowerLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              newOpacitiesResult[lowerBreast][lowerClass]
            );

            // Update higher match with the new lower lesion
            newOpacitiesResult[higherBreast][higherClass][higherIndex].match = [
              higherClass,
              newOpacitiesResult[lowerBreast][lowerClass].length,
            ];

            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          }

          // Case3 : Same class is from previous higher
          else if (newHighClass === higherPreviousClass) {
            const updatedHigherLesionInfo = {
              ...newOpacitiesResult[higherBreast][higherClass][higherIndex],
              match:
                newHighClass === lowerClass ? [lowerClass, lowerIndex] : null,
              predicted_class: null,
            };

            if (!newOpacitiesResult[higherBreast][higherPreviousClass]) {
              newOpacitiesResult[higherBreast][higherPreviousClass] = [];
            }

            newOpacitiesResult[higherBreast][higherPreviousClass].push(
              updatedHigherLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              newOpacitiesResult[higherBreast][higherClass]
            );

            const newHighIndex =
              newOpacitiesResult[higherBreast][higherPreviousClass].length;

            const updatedLesionInfo = {
              ...newOpacitiesResult[lowerBreast][lowerClass][lowerIndex],
              match: [higherPreviousClass, newHighIndex - 1],
              predicted_class: lowerPreviousClass,
            };

            updateLesionInfo(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              updatedLesionInfo
            );
            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          }

          // Case4: New high class is from the lower class
          else if (newHighClass === lowerClass && !lowerHighClass) {
            const updatedHigherLesionInfo = {
              ...newOpacitiesResult[higherBreast][higherClass][higherIndex],
              match: [lowerClass, lowerIndex],
              predicted_class: higherPreviousClass,
            };

            updateLesionInfo(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              updatedHigherLesionInfo
            );

            if (!newOpacitiesResult[higherBreast][newHighClass]) {
              newOpacitiesResult[higherBreast][newHighClass] = [];
            }

            newOpacitiesResult[higherBreast][newHighClass].push(
              updatedHigherLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              newOpacitiesResult[higherBreast][higherClass]
            );

            newOpacitiesResult[lowerBreast][lowerClass][lowerIndex].match = [
              lowerClass,
              newOpacitiesResult[higherBreast][higherClass].length,
            ];

            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          }
          // Case5: High class is from previous higher
          else if (newHighClass === higherHighClass) {
            const updatedLowerLesionInfo = {
              ...newOpacitiesResult[lowerBreast][lowerClass][lowerIndex],
              match: null,
              predicted_class: null,
            };

            if (!newOpacitiesResult[lowerBreast][lowerPreviousClass]) {
              newOpacitiesResult[lowerBreast][lowerPreviousClass] = [];
            }

            newOpacitiesResult[lowerBreast][lowerPreviousClass].push(
              updatedLowerLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              lowerBreast,
              lowerClass,
              lowerIndex,
              newOpacitiesResult[lowerBreast][lowerClass]
            );

            const newHighIndex =
              newOpacitiesResult[lowerBreast][lowerPreviousClass].length;

            const updatedLesionInfo = {
              ...newOpacitiesResult[higherBreast][newHighClass][newHighIndex],
              match: [lowerClass, lowerIndex],
              predicted_class: higherPreviousClass,
            };

            if (!newOpacitiesResult[higherBreast][newHighClass]) {
              newOpacitiesResult[higherBreast][newHighClass] = [];
            }

            newOpacitiesResult[higherBreast][newHighClass].push(
              updatedLesionInfo
            );

            removeLesionAndUpdateIndices(
              newOpacitiesResult,
              higherBreast,
              higherClass,
              higherIndex,
              newOpacitiesResult[higherBreast][higherClass]
            );

            newOpacitiesResult[lowerBreast][newHighClass][newHighIndex].match =
              [
                lowerClass,
                newOpacitiesResult[higherBreast][higherClass].length - 1,
              ];

            // Remove match from prev matched lesion
            if (matchedLesion) {
              handleMatchedLesion(
                newOpacitiesResult,
                matchedLesion,
                matchedProjection,
                currentMovingBreast
              );
            }
          }
        }
      } else {
        if (lesionsSameClass) {
          updateLesionInfo(
            newOpacitiesResult,
            currentMovingBreast,
            currentMovingBirads,
            currentMovingIndex,
            {
              match: [destinationMovingBirads, Number(destinationLesionIndex)],
            }
          );
          updateLesionInfo(
            newOpacitiesResult,
            destinationMovingBreast,
            destinationMovingBirads,
            destinationLesionIndex,
            {
              match: [currentMovingBirads, Number(currentMovingIndex)],
            }
          );
          if (matchedLesion) {
            handleMatchedLesion(
              newOpacitiesResult,
              matchedLesion,
              matchedProjection,
              destinationMovingBreast
            );
          }
        } else {
          upscaleLesionsClass(
            newOpacitiesResult,
            higherBreast,
            higherClass,
            higherIndex,
            lowerBreast,
            lowerClass,
            lowerIndex,
            newOpacitiesResult[lowerBreast][lowerClass]
          );
          if (matchedLesion) {
            handleMatchedLesion(
              newOpacitiesResult,
              matchedLesion,
              matchedProjection,
              currentMovingBreast
            );
          }
        }
      }

      dispatch(
        actions.setSnackbar({
          msg: t('diagnose.match_ok'),
          severity: 'success',
        })
      );
      dispatch(actions.setCustomOpacities(newOpacitiesResult));
      dispatch(actions.setOpacitiesLocalChanges(true));
    } else {
      dispatch(
        actions.setSnackbar({
          msg: t('diagnose.general_error'),
          severity: 'success',
        })
      );
      return;
    }
  }
};
