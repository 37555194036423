export function highDensity(data) {
  let highestDensClass = null;
  let densClass;

  for (const key in data) {
    if (
      data.hasOwnProperty(key) &&
      typeof data[key] === 'object' &&
      data[key]?.DensClass !== null
    ) {
      const entry = data[key];

      if (entry) {
        densClass = entry.DensClass;
      }

      if (!highestDensClass || densClass > highestDensClass) {
        highestDensClass = densClass;
      }
    }
  }

  return highestDensClass;
}

export const densityColor = (dens, theme) => {
  if (dens === 'A') {
    return theme.palette.projections.acrA;
  } else if (dens === 'B') {
    return theme.palette.projections.acrB;
  } else if (dens === 'C') {
    return theme.palette.projections.acrC;
  } else if (dens === 'D') {
    return theme.palette.projections.acrD;
  }
  return theme.palette.complementary.mainLight;
};
