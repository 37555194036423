import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { connect } from 'react-redux';

am4core.useTheme(am4themes_animated);

class Histogram extends React.Component {
  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.darkState !== this.props.darkState ||
      prevProps.theme.palette.primary.main !== this.props.theme.palette.primary.main
    ) {
      this.chart.dispose();
      this.initChart();
    } else if (prevProps.data !== this.props.data) {
      this.chart.data = this.props.data;
      this.chart.validateData();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  initChart() {
    const chart = am4core.create(this.props.chartdiv, am4charts.XYChart);
    chart.numberFormatter.numberFormat = '#.##';
    chart.data = this.props.data;

    chart.hiddenState.properties.opacity = 0;
    chart.padding(0, 30, 0, 30);
    chart.zoomOutButton.disabled = true;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.fontSize = '1.5vh';

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.calculateTotals = true;

    if (this.props.chartdiv === 'histogram_quality') {
      chart.padding(20, 30, 0, 30);

      let firstNonZeroIndex = 0;
      for (let i = 0; i < this.props.data.length; i++) {
        if (this.props.data[i].value > 0) {
          firstNonZeroIndex = i;
          break;
        }
      }

      const firstNonZeroCategory = parseFloat(this.props.data?.[firstNonZeroIndex]?.category);
      categoryAxis.start =
        firstNonZeroCategory && firstNonZeroCategory > 5 ? (firstNonZeroCategory - 10) / 100 : 0;

      categoryAxis.renderer.grid.template.disabled = false;
      categoryAxis.renderer.labels.template.disabled = false;
      categoryAxis.renderer.labels.template.fill = am4core.color(
        this.props.theme.palette.texts.secondary
      );

      categoryAxis.renderer.labels.template.adapter.add('text', function (text) {
        return text + '%';
      });

      categoryAxis.renderer.labels.template.adapter.add('fill', (fill, target) => {
        const categoryValue = parseFloat(target.dataItem.category);
        let color;
        if (categoryValue > 90) {
          color = this.props.theme.palette.dashboard.perfect;
        } else if (categoryValue > 80) {
          color = this.props.theme.palette.dashboard.good;
        } else if (categoryValue > 70) {
          color = this.props.theme.palette.dashboard.moderate;
        } else {
          color = this.props.theme.palette.dashboard.inadequate;
        }
        return am4core.color(color);
      });

      valueAxis.renderer.grid.template.disabled = false;
      valueAxis.visible = true;
      valueAxis.renderer.labels.template.disabled = false;
      valueAxis.renderer.labels.template.fill = am4core.color(
        this.props.theme.palette.texts.secondary
      );
    } else {
      valueAxis.renderer.maxWidth = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color(
        this.props.theme.palette.texts.secondary
      );
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.labels.template.disabled = false;
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.visible = true;
      valueAxis.renderer.labels.template.disabled = true;
    }

    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(40);
    series1.dataFields.categoryX = 'category';
    series1.dataFields.valueY = 'value';
    series1.columns.template.tooltipText = '{value}';
    series1.columns.template.adapter.add('tooltipText', (text, target) => {
      const val = target.tooltipDataItem.valueY;
      return this.props.chartdiv === 'histogram_dose'
        ? val === 0.5
          ? '~{category} mGy: <1 projections'
          : '~{category} mGy: {value} projections'
        : '{value} projections {category}%';
    });

    series1.columns.template.strokeWidth = 0;
    series1.columns.template.stroke = am4core.color(this.props.theme.palette.primary.light);
    series1.columns.template.strokeOpacity = 1;
    series1.columns.template.column.cornerRadiusTopLeft = 10;
    series1.columns.template.column.cornerRadiusTopRight = 10;

    series1.columns.template.adapter.add('fill', (fill, target) => {
      const color = target.dataItem.dataContext.color;
      return color ? am4core.color(color) : this.props.theme.palette.complementary.turquoise;
    });

    this.chart = chart;
  }

  render() {
    return <div id={this.props.chartdiv} style={{ width: '100%', height: '100%' }}></div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { chartdiv } = ownProps;
  let data;

  if (chartdiv === 'histogram_dose') {
    data = state.statistics.doseData.histo;
  } else if (chartdiv === 'histogram_quality') {
    data = state.statistics.performanceHistogramData.histo;
  } else {
    data = [];
  }
  return { data };
};

export default connect(mapStateToProps)(Histogram);
