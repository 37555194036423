import React from 'react';
import { Button, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

export default function ButtonGroupDicomFilter(props) {
  const { t } = useTranslation();

  return (
    <Grid
      direction="row"
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        xs={6}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button variant="outlined" size="medium" onClick={props.onClickClear}>
          {t('Settings.clear')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          startIcon={<AddIcon />}
          onClick={props.onClickAdd}
          style={{ marginRight: '20px' }}
        >
          {t('Settings.add')}
        </Button>
      </Grid>

      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!!props.onClickEcho && (
          <Button
            variant="outlined"
            color="secondary"
            size="medium"
            onClick={props.onClickEcho}
            style={{
              marginRight: '10px',
            }}
          >
            {t('Settings.c-echo')}
          </Button>
        )}
        <Button
          variant="outlined"
          color="secondary"
          size="medium"
          startIcon={<DeleteIcon />}
          onClick={props.onClickDelete}
        >
          {t('Settings.delete')}
        </Button>
      </Grid>
    </Grid>
  );
}
