import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import * as actions from '../../../../store/index';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  Divider,
  Typography,
  Grid,
  Paper,
  Link,
  Slide,
  IconButton,
  Button,
  SvgIcon,
  Tooltip,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import CodeIcon from '@material-ui/icons/Code';
import HelpIcon from '@material-ui/icons/Help';
// import InfoComplete from '../../../../resources/coorporate_images/info-barcode_2023.png';
import EcRepSymbol from '../../../../resources/coorporate_images/ecRep.png';
import MDSymbol from '../../../../resources/coorporate_images/MD_symbol.png';
import CESymbol from '../../../../resources/coorporate_images/CE_0297_Mark_Black.png';
import UDIBarcode from '../../../../resources/coorporate_images/UDI_barcode.png';
import UDISymbol from '../../../../resources/coorporate_images/UDI_symbol.png';
import ImporterSymbol from '../../../../resources/coorporate_images/importer.png';

import ManufactureDateSymbol from '../../../../resources/coorporate_images/manufacture_date_symbol.png';
import ManufactureSymbol from '../../../../resources/coorporate_images/manufacture_symbol.png';
import ExpirationDate from '../../../../resources/coorporate_images/expiration_date_symbol.png';
import REFSymbol from '../../../../resources/coorporate_images/REF_Symbol.png';

import { useTranslation } from 'react-i18next';
import IFU_icon from '../../../../resources/icons/IFU_icon.png';
import theme from '@amcharts/amcharts4/.internal/themes/animated';
import { IFU_VERSION, QUALITY_SYSTEM, QUALITY_VALUES } from './../../../../config';

import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const useStyles = makeStyles((theme) => ({
  infoTitle: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },

  infoText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.secondary,
    whiteSpace: 'break-spaces',
    maxWidth: '250px',
  },

  infoIcon: {
    color: theme.palette.secondary.main,
  },

  CEicon: {
    width: '100px',
    height: 'auto',
    // objectFit: "contain",
  },

  DOMImage: {
    width: '220px',
  },

  InfoImage: {
    width: '140px',
    height: 'auto',
    // objectFit: "contain",
  },

  info_icons: {
    width: '80px',
    height: 'auto',
  },

  ce_icon: {
    width: '90px',
    height: 'auto',
    marginLeft: '100%',
  },

  symbol_icon: {
    width: '70px',
    height: 'auto',
  },

  infoTexts: {
    fontSize: '0.8rem',
    width: '50%',
    color: 'rgb(204, 204, 204)',
    whiteSpace: 'pre-line',
    margin: '4%',
  },

  qualityInfoText: {
    width: '100%',
    color: 'rgb(204, 204, 204)',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.secondary,
  },

  importer_text: {
    fontSize: '0.8rem',
    color: 'rgb(204, 204, 204)',
  },

  icon_container: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const styles = (theme) => ({
  draggableTitle: {
    cursor: 'move',
    margin: 0,
    letterSpacing: '2.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
    transform: 'scale(1.1)',
  },
  title: {
    letterSpacing: '0.2rem',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='right' ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.draggableTitle} disableTypography {...other}>
      <Typography className={classes.title} variant='h5'>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function Info() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const softwareVersion = process.env.REACT_APP_SOFTWARE_VERSION ?? 'version unknown';
  const UDI = process.env.REACT_APP_UDI ?? 'UDI unknown';

  const darkState = useSelector((store) => store.gui.darkState);
  const activeDialog = useSelector((state) => state.gui.activeDialog);

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-dialog-title'
        onClose={() => dispatch(actions.setActiveDialog(null))}
        open={activeDialog === 'info'}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle
          id='draggable-dialog-title'
          onClose={() => dispatch(actions.setActiveDialog(null))}
          style={{ textTransform: 'none' }}
        >
          b-box plus (DANAI)
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Grid container direction='row' wrap='nowrap' justifyContent='flex-start' spacing={1}>
            <Grid
              xs={6}
              item
              container
              direction='column'
              alignItems='flex-start'
              justifyContent='space-evenly'
            >
              {/* Mail */}
              <Grid
                container
                direction='row'
                spacing={1}
                alignItems='center'
                style={{ padding: '10px' }}
              >
                <Grid item className={classes.icon_container}>
                  <Link href='mailto:info@b-rayz.ch>info@b-rayz.ch'>
                    <EmailIcon className={classes.infoIcon} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link className={classes.infoText} href='mailto:info@b-rayz.ch>info@b-rayz.ch'>
                    <Typography className={classes.infoText}>info@b-rayZ.ch</Typography>
                  </Link>
                </Grid>
              </Grid>

              {/* Web */}
              <Grid
                container
                direction='row'
                spacing={1}
                alignItems='center'
                style={{ padding: '10px' }}
              >
                <Grid item className={classes.icon_container}>
                  <Link target='_blank' href='https://www.b-rayz.ch'>
                    <LanguageIcon className={classes.infoIcon} />
                  </Link>
                </Grid>
                <Grid item>
                  <Typography>
                    <Link target='_blank' className={classes.infoText} href='https://www.b-rayz.ch'>
                      www.b-rayZ.ch
                    </Link>
                  </Typography>
                </Grid>
              </Grid>

              {/* Quality System */}
              <Grid
                container
                direction='row'
                spacing={1}
                alignItems='center'
                style={{ padding: '10px' }}
              >
                <Grid item xs={1} className={classes.icon_container}>
                  <CenterFocusWeakIcon className={classes.infoIcon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography className={classes.qualityInfoText} style={{ whiteSpace: 'pre' }}>
                    {`${t('information.quality_system')}: ${QUALITY_SYSTEM?.toUpperCase()}`}
                  </Typography>
                </Grid>
              </Grid>

              {/* Quality Values */}
              <Grid
                container
                direction='row'
                spacing={1}
                alignItems='center'
                style={{ padding: '10px' }}
              >
                <Grid item xs={1} className={classes.icon_container}>
                  <TrendingUpIcon className={classes.infoIcon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    className={classes.qualityInfoText}
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {`${t('information.quality_values')}:  ${QUALITY_VALUES.join(', ')}`}
                  </Typography>
                </Grid>
              </Grid>

              {/* PGMI */}
              {/* <Grid
                container
                direction='row'
                spacing={1}
                alignItems='center'
                style={{ cursor: 'pointer', padding: '10px' }}
                onClick={() => {
                  window.open(`/bbox/ifu_${i18n.language}`, '_blank');
                }}
              >
                <Grid item className={classes.icon_container}>
                  <HelpIcon className={classes.infoIcon} />
                </Grid>
                <Grid item>
                  <Typography className={classes.infoText}>
                    {t('information.quality_evaluation')}
                  </Typography>
                </Grid>
              </Grid> */}

              {/* SO Version */}
              <Grid
                container
                direction='row'
                spacing={1}
                alignItems='center'
                wrap='wrap'
                style={{ margin: '0' }}
              >
                <Grid item>
                  <img
                    src={REFSymbol}
                    alt='manufacture'
                    style={{
                      filter: !darkState && 'invert(80%)',
                      width: '50px',
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.infoText}>{softwareVersion}</Typography>
                </Grid>
              </Grid>

              {/* IFU Version */}
              <Grid
                container
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  cursor: 'pointer',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                }}
              >
                <Tooltip arrow placement='right-start' title='eIFU' style={{ zIndex: 2000 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                    onClick={() => {
                      window.open(`/bbox/ifu_${i18n.language}`, '_blank');
                    }}
                  >
                    <IconButton aria-label='eifu' color='secondary' style={{ padding: '5px' }}>
                      <img
                        src={IFU_icon}
                        alt='e-ifu'
                        style={{
                          width: '50px',
                          filter: !darkState && 'invert(40%)',
                        }}
                      />
                    </IconButton>

                    <Link className={classes.infoText}>
                      <Typography className={classes.infoText}>
                        {`IFU Version: ${IFU_VERSION}`}
                      </Typography>
                    </Link>
                  </div>
                </Tooltip>
              </Grid>
              <Box
                p={0}
                style={{
                  width: '25%',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '20px 0',
                }}
              >
                <img
                  className={classes.info_icons}
                  src={MDSymbol}
                  alt='medical_device'
                  style={{
                    filter: darkState ? 'invert(80%)' : 'invert(40%)',
                  }}
                />
                <img
                  className={classes.ce_icon}
                  src={CESymbol}
                  alt='ce_symbol'
                  style={{
                    filter: darkState ? 'invert(80%)' : 'invert(40%)',
                  }}
                />
              </Box>
            </Grid>

            <Grid xs={6} item>
              <Box
                p={0}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <img
                  className={classes.symbol_icon}
                  src={UDISymbol}
                  alt='udi'
                  style={{
                    filter: darkState ? 'invert(80%)' : 'invert(40%)',
                  }}
                />
                <Typography
                  style={{
                    color: darkState ? '#ccc' : '#575757',
                    margin: '2%',
                    letterSpacing: '0.1rem',
                    fontSize: '0.8rem',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {UDI}
                </Typography>
                <Typography
                  style={{
                    color: darkState ? '#898989' : '#3d3d3d',
                    margin: '2%',
                    letterSpacing: '0.1rem',
                    fontSize: '0.8rem',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {/* {UDI} */}
                  (01)Product Identifier(4326)Release Date(8012)Software Version
                </Typography>
              </Box>

              <Box
                p={0}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  margin: '20px 0',
                }}
              >
                <img
                  className={classes.info_icons}
                  src={ManufactureSymbol}
                  alt='manufacture'
                  style={{
                    filter: darkState ? 'invert(80%)' : 'invert(40%)',
                  }}
                />
                <Typography
                  className={classes.infoTexts}
                  style={{
                    color: darkState ? '#ccc' : '#575757',
                    width: '50%',
                  }}
                >
                  © 2022 b-rayZ AG, all rights reserved. Wagistrasse 21, 8952 Schlieren, Switzerland
                  Info: b-rayz.ch Email: info@b-rayz.ch
                </Typography>
              </Box>
              <Box
                p={0}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  margin: '20px 0',
                }}
              >
                <img
                  className={classes.info_icons}
                  src={ManufactureDateSymbol}
                  alt='manufacture'
                  style={{
                    filter: darkState ? 'invert(80%)' : 'invert(40%)',
                  }}
                />
                <Typography
                  className={classes.infoTexts}
                  style={{
                    color: darkState ? '#ccc' : '#575757',
                  }}
                >
                  19.02.2023
                </Typography>
              </Box>
              {/* <Box
                p={0}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
              >
                <img
                  className={classes.info_icons}
                  src={ExpirationDate}
                  alt='manufacture'
                  style={{
                    filter: darkState ? 'invert(80%)' : 'invert(40%)',
                  }}
                />
                <Typography
                  className={classes.infoTexts}
                  style={{
                    color: darkState ? '#ccc' : '#575757',
                  }}
                >
                  18.02.2028
                </Typography>
              </Box> */}
              <Box
                p={0}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  marginTop: '20px',
                }}
              >
                <img
                  className={classes.info_icons}
                  src={EcRepSymbol}
                  alt='ecrep'
                  style={{
                    filter: darkState ? 'invert(80%)' : 'invert(40%)',
                  }}
                />
                <Typography
                  className={classes.infoTexts}
                  style={{
                    color: darkState ? '#ccc' : '#575757',
                  }}
                >
                  MED-RAS GmbH, Eichenallee 8H, 21521 Wohltorf, Germany
                </Typography>
              </Box>

              <Box
                p={0}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  margin: '20px 0',
                }}
              >
                <img
                  className={classes.symbol_icon}
                  src={ImporterSymbol}
                  alt='medEnvoy'
                  style={{
                    filter: darkState ? 'invert(80%)' : 'invert(40%)',
                    width: '80px',
                    marginRight: '10px',
                  }}
                />
                <div
                  style={{
                    width: '70%',
                  }}
                >
                  <Typography className={classes.importer_text} style={{ fontWeight: 'bold' }}>
                    MedEnvoy Global BV
                  </Typography>
                  <Typography
                    className={classes.importer_text}
                    style={{
                      color: darkState ? '#ccc' : '#575757',
                    }}
                  >
                    Prinses Margrietplantsoen 33-
                  </Typography>
                  <Typography
                    className={classes.importer_text}
                    style={{
                      color: darkState ? '#ccc' : '#575757',
                    }}
                  >
                    Suite 123 2595 AM, The Hague
                  </Typography>

                  <Typography
                    className={classes.importer_text}
                    style={{
                      color: darkState ? '#ccc' : '#575757',
                    }}
                  >
                    The Netherlands
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
