import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../store';
import { useAppliedEvaluation } from '../../../../helpers/hooks/useEvaluation';
import {
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import QualityCC from './QualityProjections/QualityCC';
import QualityMLO from './QualityProjections/QualityMLO';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { singleSymmetryChip, symmetryChip } from './QualityProjections/QualityCommon/QualityCommon';
import QualityFeatures from './QualityRevisionMode/QualityFeatures';
import TooltipBadgeFab from '../DanaiView/Common/DiagnoseViewCommon';
import ReviewSelect from '../CardActions/ReviewSelect/ReviewSelect';
import { QUALITY_SYSTEM } from '../../../../config';
import { reviewerName } from '../../../../helpers/reviewerName';

const useStyles = makeStyles((theme) => ({
  card_content_container: {
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'center',
    alignItems: 'center',
    height: '96%',
  },

  stretchHeiht: {
    height: '100%',
  },

  overlays_icons: {
    height: '25px',
    width: '25px',
    marginRight: '4px',
  },

  tooltip_text: {
    letterSpacing: '0.09rem',
    fontSize: '12px',
  },

  InfoIcon: {
    cursor: 'pointer',
  },

  container_quality_pgmi: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    height: '100%',
    flexDirection: 'column',
  },

  container_symmetry: {
    position: 'relative',
    minHeight: '20px',
  },

  right_pgmi_container: {
    height: '55vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    right: '15%',
  },

  pgmi: {
    letterSpacing: '0.2rem',
    textTransform: 'uppercase',
    transform: 'rotate(-180deg)',
    writingMode: 'vertical-lr',
    margin: '0 6px',
    fontSize: theme.fonts.responsiveBig,
  },

  card_header_container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  quality_head_container: {
    margin: '0 16px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  revision_quality_chips_container: {
    width: '100%',
    margin: '0',
    padding: '0',
    height: '30px',
  },

  revision_info_icon: {
    position: 'absolute',
    top: '10vh',
    right: '2%',
  },

  reviewer_badge: {
    height: '14px',
    minHeight: '14px',
    fontSize: theme.fonts.responsiveSmall,
    marginBottom: '2px',
    minWidth: '70px',
  },

  reviewer_title_shape: {
    marginLeft: '10px',
  },

  QualityZoomView: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },

  topRow: {
    display: 'flex',
    alignSelf: 'end',
    margin: '0 auto min(0.6vh, 0.6vw) auto',
    position: 'relative',
    maxHeight: '50%',
    justifyContent: 'center',
  },

  bottomRow: {
    display: 'flex',
    alignSelf: 'start',
    margin: 'min(0.6vh, 0.6vw) auto 0 auto',
    position: 'relative',
    maxHeight: '50%',
    justifyContent: 'center',
  },

  topProjectionRow: {
    display: 'flex',
    alignSelf: 'end',
    margin: '0 auto min(0.3vh, 0.3vw) auto',
    position: 'relative',
    maxHeight: '50%',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },

  bottomProjectionRow: {
    display: 'flex',
    alignSelf: 'start',
    margin: 'min(0.3vh, 0.3vw) auto 0 auto',
    position: 'relative',
    maxHeight: '50%',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },

  rcc: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'top left',
    transform: 'scale(1)',
    transition: '0.3s',
    maxWidth: '50%',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  lcc: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'top right',
    transform: 'scale(1)',
    transition: '0.3s',
    maxWidth: '50%',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  rmlo: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'bottom left',
    transform: 'scale(1)',
    transition: '0.3s',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  lmlo: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 'min(0.3vh, 0.3vw)',
    transformOrigin: 'bottom right',
    transform: 'scale(1)',
    transition: '0.3s',
    width: '100%',
    height: '100%',
    alignItems: 'flex-start',
    borderRadius: '5px',
  },

  ZoomLeft: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 50,
    opacity: 0,
    boxShadow: 'none',
    animation: '$appear 600ms forwards',
    background: theme.palette.complementary.turquoiseMedium,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
      boxShadow: '0px 0px 5px -1px #FFFFF',
    },
  },

  ZoomRight: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 50,
    opacity: 0,
    boxShadow: 'none',
    animation: '$appear 600ms forwards',
    background: theme.palette.complementary.turquoiseMedium,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    transform: 'scaleX(-1)',
    '&:hover': {
      backgroundColor: theme.palette.complementary.turquoise,
      boxShadow: '0px 0px 5px -1px #FFFF',
    },
  },

  '@keyframes appear': {
    '70%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0.8,
    },
  },

  zoomIcon: {
    color: 'white',
    fontSize: theme.fonts.responsiveHeader,
  },

  zoom: {
    transform: 'scale(2.02)',
    transition: '0.3s',
    zIndex: 80,
  },

  shrink: {
    transform: 'scale(0)',
    transition: '0.3s',
  },
}));

function QualityView() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();
  const PNL_DIFF = useSelector((state) => state.gui.PNL_DIFF);

  const qualityInRevisionMode = useSelector((state) => state.labeling.qualityInRevisionMode);
  const anchorQualityRef = useRef();
  const { customQuality, localChangesQuality, qualityInDrawingMode } = useSelector(
    (state) => state.labeling
  );
  const {
    zoomedProjection,
    NIPPLE_IN_PROFILE,
    NIPPLE_ANGLE,
    IMF_DEPICTED,
    PARENCHYMA_CUTS,
    tabsIndex,
  } = useSelector((state) => state.gui);
  const {
    projections: projectionData,
    evaluations,
    applied_quality,
    shown_quality,
  } = useSelector((state) => state.results);

  const qualityInRevisionTab = tabsIndex === 2 && qualityInRevisionMode;

  // Local state
  const [reviewerTooltip, setReviewerTooltip] = useState(false);
  const [qualityPopperOpen, setQualityPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Variables
  const shownQuality = shown_quality || applied_quality;
  const qualityEvaluation = useAppliedEvaluation('quality', shownQuality, qualityInRevisionMode);
  const symmetryCC = qualityEvaluation?.lcc?.symmetry;
  const symmetryMLO = qualityEvaluation?.lmlo?.symmetry;
  const qualityChipsShown =
    (tabsIndex === 1 && !qualityInRevisionTab) || (qualityInRevisionTab && !qualityInDrawingMode);
  const projectionsType = ['rcc', 'lcc', 'rmlo', 'lmlo'];

  // Effects

  useEffect(() => {
    setQualityPopperOpen(false);
    dispatch(actions.setInitialImages());
    dispatch(actions.setDiagnosticsInRevisionMode(false));
    dispatch(actions.setDensityInRevisionMode(false));
    dispatch(actions.setShownQualityOverlayType(''));
    if (tabsIndex === 1) dispatch(actions.setQualityInRevisionMode(false));
  }, []);

  useEffect(() => {
    if (!localChangesQuality) return;

    const pnlClassRight = evalPNL(
      qualityEvaluation?.rmlo?.PectoNippleLength,
      qualityEvaluation?.rcc?.PectoNippleLength
    );
    if (pnlClassRight) dispatch(actions.updateCustomQualityFeature('RCC', 'PNL', pnlClassRight));

    const pnlClassLeft = evalPNL(
      qualityEvaluation?.lmlo?.PectoNippleLength,
      qualityEvaluation?.lcc?.PectoNippleLength
    );
    if (pnlClassLeft) dispatch(actions.updateCustomQualityFeature('LCC', 'PNL', pnlClassLeft));
  }, [
    qualityEvaluation?.rcc?.PectoNippleLength,
    qualityEvaluation?.lcc?.PectoNippleLength,
    qualityEvaluation?.rmlo?.PectoNippleLength,
    qualityEvaluation?.lmlo?.PectoNippleLength,
    localChangesQuality,
  ]);

  const classesMap = {
    rcc: [classes.rcc],
    lcc: [classes.lcc],
    rmlo: [classes.rmlo],
    lmlo: [classes.lmlo],
  };

  projectionsType.forEach((projection) => {
    if (zoomedProjection === projection) {
      classesMap[projection].push(classes.zoom);
    } else if (zoomedProjection) {
      classesMap[projection].push(classes.shrink);
    }
  });

  const { rcc: classesRcc, lcc: classesLcc, rmlo: classesRmlo, lmlo: classesLmlo } = classesMap;

  //Reviewer menu handler

  const handlePopperToggle = () => {
    setReviewerTooltip(false);
    setQualityPopperOpen((prevOpen) => !prevOpen);
  };

  const clickAwayHandler = () => {
    setReviewerTooltip(false);
    setQualityPopperOpen(false);
    setAnchorEl(null);
  };

  // Helpers
  const evalPNL = (pnlLenghtCC, pnlLengthMLO) => {
    const pnlDiff = pnlLenghtCC - pnlLengthMLO;
    if (isNaN(pnlDiff)) return null;

    if (pnlDiff < PNL_DIFF?.[0]) return 'correct';
    if (pnlDiff < PNL_DIFF?.[1]) return 'moderate';
    return 'insufficient';
  };

  const getTooltipText = (projName, feature) => {
    if (!evaluations?.hasOwnProperty(applied_quality)) return t('ResultView.not_available');

    const projections = {
      rcc: {
        predicted: evaluations?.quality?.bbox?.rcc ?? {},
        unsaved: customQuality?.rcc,
        applied: evaluations?.quality?.[applied_quality]?.rcc ?? {},
      },
      lcc: {
        predicted: evaluations?.quality?.bbox?.lcc ?? {},
        unsaved: customQuality?.lcc,
        applied: evaluations?.quality?.[applied_quality]?.lcc ?? {},
      },
      rmlo: {
        predicted: evaluations?.quality?.bbox?.rmlo ?? {},
        unsaved: customQuality?.rmlo,
        applied: evaluations?.quality?.[applied_quality]?.rmlo ?? {},
      },
      lmlo: {
        predicted: evaluations?.quality?.bbox?.lmlo ?? {},
        unsaved: customQuality?.lmlo,
        applied: evaluations?.quality?.[applied_quality]?.lmlo ?? {},
      },
    };

    // Unsaved changes
    if (!projections[projName].predicted.hasOwnProperty(feature))
      return t('ResultView.not_available');

    if (
      localChangesQuality &&
      projections[projName]?.applied[feature] !== projections[projName]?.unsaved[feature]
    )
      return (
        <>
          <p style={{ fontSize: '1.3vh' }}>{t('ResultView.unsaved')}</p>
          <p style={{ fontSize: '1.3vh' }}>
            {`${t('ResultView.org_pred')}: ${projections[projName]?.predicted[feature]}`}
          </p>
        </>
      );

    if (
      Object.keys(evaluations).length > 1 &&
      applied_quality !== 'bbox' &&
      projections[projName]?.applied &&
      projections[projName]?.applied[feature] !== projections[projName]?.predicted[feature]
    ) {
      if (Object.keys(evaluations).length === 1) return 'No active revision';

      return (
        <>
          <p style={{ fontSize: '1.3vh' }}>{`${t('ResultView.relabeled')} ${applied_quality}`}</p>
          <p style={{ fontSize: '1.3vh' }}>
            {`${t('ResultView.org_pred')}: ${projections[projName]?.predicted[feature]}`}
          </p>
        </>
      );
    }

    return (
      <>
        <p style={{ fontSize: '1.3vh' }}>{t('ResultView.no_changes')}</p>
        <p style={{ fontSize: '1.3vh' }}>
          {`${t('ResultView.org_pred')}: ${projections[projName]?.predicted[feature]}`}
        </p>
      </>
    );
  };

  // Handlers
  const onClickSymmetryCC = () => {
    dispatch(actions.switchQuality('RCC', 'symmetry', 3));
    dispatch(actions.switchQuality('LCC', 'symmetry', 3));
  };

  const onClickSymmetryMLO = () => {
    dispatch(actions.switchQuality('RMLO', 'symmetry', 3));
    dispatch(actions.switchQuality('LMLO', 'symmetry', 3));
  };

  const onClickNipple = (viewPosition) => {
    const base = !!NIPPLE_IN_PROFILE && NIPPLE_IN_PROFILE[0] == NIPPLE_IN_PROFILE[1] ? 2 : 3;
    dispatch(actions.switchQuality(viewPosition, 'Nipple', base));
  };

  const onClickParen = (viewPosition, laterality = '') => {
    const base = !!PARENCHYMA_CUTS && PARENCHYMA_CUTS[0] == PARENCHYMA_CUTS[1] ? 2 : 3;
    const key = 'ParenchymaCuts' + laterality;
    dispatch(actions.switchQuality(viewPosition, key, base));
  };

  const onClickInfram = (viewPosition) => {
    const base = !!IMF_DEPICTED && IMF_DEPICTED[0] == IMF_DEPICTED[1] ? 2 : 3;
    dispatch(actions.switchQuality(viewPosition, 'Infram', base));
  };

  const onClickBlur = (viewPosition) => {
    dispatch(actions.switchQuality(viewPosition, 'blur', 2, false));
  };

  const onClickSingularSymmetry = (proj) => {
    const projection = proj.toUpperCase();
    dispatch(actions.switchQuality(projection, 'symmetry', 3));
  };

  return (
    <Card
      elevation={0}
      style={{
        height: '100%',
      }}
    >
      <CardHeader
        style={{ padding: 0, margin: 0 }}
        title={
          qualityInRevisionTab ? (
            <div className={classes.revision_quality_chips_container}>
              <QualityFeatures />
            </div>
          ) : (
            <div className={classes.quality_head_container}>
              <ClickAwayListener
                onClickAway={() => clickAwayHandler('quality')}
                mouseEvent='onClick'
              >
                <div className={classes.reviewer_title_shape}>
                  <TooltipBadgeFab
                    content={
                      <>
                        <Typography className={classes.tooltip_text} color='inherit'>
                          {t('ResultView.current_results_shown')}: {reviewerName(shownQuality)}
                        </Typography>
                        <Divider />
                        <Typography className={classes.tooltip_text} color='inherit'>
                          {t('ResultView.current_applied')}: {reviewerName(applied_quality)}
                        </Typography>
                        <p>{t('ResultView.change_reviewer')}</p>
                      </>
                    }
                    open={reviewerTooltip}
                    badgeContent={shownQuality}
                    onClick={() => handlePopperToggle('quality')}
                    onMouseEnter={() => setReviewerTooltip(true)}
                    onMouseLeave={() => setReviewerTooltip(false)}
                    popperContent={
                      <ReviewSelect type='quality' setQualityPopperOpen={setQualityPopperOpen} />
                    }
                    anchorRef={anchorQualityRef}
                    popperOpen={qualityPopperOpen}
                    classes={classes}
                    theme={theme}
                    t={t}
                  />
                </div>
              </ClickAwayListener>
              <Tooltip arrow title={t('Dashboard.pgmi_info')} placement='right-end'>
                <InfoIcon
                  className={classes.InfoIcon}
                  fontSize='small'
                  onClick={() => {
                    window.open(`/bbox/ifu_${i18n.language}`, '_blank');
                  }}
                />
              </Tooltip>
            </div>
          )
        }
      />

      <CardContent className={classes.card_content_container}>
        {qualityInRevisionTab && (
          <Tooltip
            arrow
            placement={'top-end'}
            title={
              <>
                <Typography
                  style={{
                    letterSpacing: '0.1rem',
                    textTransform: 'uppercase',
                    fontSize: theme.fonts.responsiveMedium,
                    fontWeight: 'bold',
                  }}
                >
                  {t('ResultView.revision_mode')}
                </Typography>
                <Divider style={{ margin: '3% 0' }} />
                <Typography
                  style={{
                    letterSpacing: '0.1rem',
                    fontSize: theme.fonts.responsiveMedium,
                  }}
                >
                  - {t('ResultView.click_to_revise')}
                </Typography>

                <Typography
                  style={{
                    letterSpacing: '0.1rem',
                    fontSize: theme.fonts.responsiveMedium,
                  }}
                >
                  - {t('ResultView.click_to_draw')}
                </Typography>
                <Typography
                  style={{
                    letterSpacing: '0.1rem',
                    fontSize: theme.fonts.responsiveMedium,
                    marginTop: '6px',
                    color: theme.palette.secondary.main,
                  }}
                >
                  {t('ResultView.revise_disclaimer')}
                </Typography>
              </>
            }
          >
            <InfoIcon className={classes.revision_info_icon} fontSize='small' />
          </Tooltip>
        )}
        <div className={classes.container_quality_pgmi}>
          <div className={classes.container_symmetry} style={{ top: '1%' }}>
            {qualityChipsShown &&
              QUALITY_SYSTEM !== 'german' &&
              symmetryChip(
                symmetryCC,
                'CC',
                qualityInRevisionTab && onClickSymmetryCC,
                null,
                zoomedProjection,
                classes,
                theme,
                t
              )}
            {qualityChipsShown &&
              QUALITY_SYSTEM === 'german' &&
              singleSymmetryChip(
                qualityEvaluation,
                'cc',
                qualityInRevisionTab,
                onClickSingularSymmetry,
                zoomedProjection,
                classes,
                theme,
                t
              )}
          </div>
          <div className={classes.QualityZoomView}>
            <div className={classes.topRow}>
              <div className={classesRcc.join(' ')}>
                <QualityCC
                  view_position={'rcc'}
                  result={qualityEvaluation?.rcc}
                  onClickNipple={() => qualityInRevisionTab && onClickNipple('RCC')}
                  onClickPecto={() =>
                    qualityInRevisionTab &&
                    dispatch(actions.switchQuality('RCC', 'PectoralisCC', 2, true))
                  }
                  onClickLateralParen={() => qualityInRevisionTab && onClickParen('RCC', 'Lateral')}
                  onClickMedialParen={() => qualityInRevisionTab && onClickParen('RCC', 'Medial')}
                  onQualityChipClick={() =>
                    qualityInRevisionTab && dispatch(actions.switchQualityEvaluation('RCC', t))
                  }
                  onClickBlur={() => qualityInRevisionTab && onClickBlur('RCC')}
                  onNippleOrientationClick={() => {
                    const base = !!NIPPLE_ANGLE && NIPPLE_ANGLE[0] == NIPPLE_ANGLE[1] ? 2 : 3;
                    if (qualityInRevisionTab) {
                      dispatch(actions.switchNippleTiltQuality('RCC', 'NippleCentered', base));
                    }
                  }}
                  tooltipProj={(feature) =>
                    !!projectionData.rcc
                      ? getTooltipText('rcc', feature)
                      : t('ResultView.not_available')
                  }
                />
              </div>
              <div className={classesLcc.join(' ')}>
                <QualityCC
                  view_position={'lcc'}
                  result={qualityEvaluation?.lcc}
                  onClickNipple={() => qualityInRevisionTab && onClickNipple('LCC')}
                  onClickPecto={() =>
                    qualityInRevisionTab &&
                    dispatch(actions.switchQuality('LCC', 'PectoralisCC', 2, true))
                  }
                  onClickLateralParen={() => qualityInRevisionTab && onClickParen('LCC', 'Lateral')}
                  onClickMedialParen={() => qualityInRevisionTab && onClickParen('LCC', 'Medial')}
                  onQualityChipClick={() =>
                    qualityInRevisionTab && dispatch(actions.switchQualityEvaluation('LCC', t))
                  }
                  onClickBlur={() => qualityInRevisionTab && onClickBlur('LCC')}
                  onNippleOrientationClick={() => {
                    const base = !!NIPPLE_ANGLE && NIPPLE_ANGLE[0] == NIPPLE_ANGLE[1] ? 2 : 3;
                    qualityInRevisionTab &&
                      dispatch(actions.switchNippleTiltQuality('LCC', 'NippleCentered', base));
                  }}
                  tooltipProj={(feature) =>
                    !!projectionData.lcc
                      ? getTooltipText('lcc', feature)
                      : t('ResultView.not_available')
                  }
                />
              </div>
            </div>
            <div className={classes.bottomRow}>
              <div className={classesRmlo.join(' ')}>
                <QualityMLO
                  view_position={'rmlo'}
                  result={qualityEvaluation?.rmlo}
                  onClickNipple={() => qualityInRevisionTab && onClickNipple('RMLO')}
                  onClickPectoAngle={() =>
                    qualityInRevisionTab && dispatch(actions.switchQuality('RMLO', 'PectAngle', 3))
                  }
                  onClickParen={() => qualityInRevisionTab && onClickParen('RMLO')}
                  onQualityChipClick={() =>
                    qualityInRevisionTab && dispatch(actions.switchQualityEvaluation('RMLO', t))
                  }
                  onClickInfram={() => qualityInRevisionTab && onClickInfram('RMLO')}
                  onBuckyHeightClick={() =>
                    qualityInRevisionTab && dispatch(actions.switchBuckyHeightQuality('RMLO'))
                  }
                  onClickBlur={() => qualityInRevisionTab && onClickBlur('RMLO')}
                  onPectShapeClick={() =>
                    qualityInRevisionTab &&
                    dispatch(actions.switchPectShapeQuality('RMLO', 'PectShape', 3))
                  }
                  onPectLevelClick={() =>
                    qualityInRevisionTab &&
                    dispatch(actions.switchQuality('RMLO', 'PectLevel', 3, false))
                  }
                  tooltipProj={(feature) =>
                    !!projectionData.rmlo
                      ? getTooltipText('rmlo', feature)
                      : t('ResultView.not_available')
                  }
                />
              </div>
              <div className={classesLmlo.join(' ')}>
                <QualityMLO
                  view_position={'lmlo'}
                  result={qualityEvaluation?.lmlo}
                  onClickNipple={() => qualityInRevisionTab && onClickNipple('LMLO')}
                  onClickPectoAngle={() =>
                    qualityInRevisionTab && dispatch(actions.switchQuality('LMLO', 'PectAngle', 3))
                  }
                  onClickParen={() => qualityInRevisionTab && onClickParen('LMLO')}
                  onClickInfram={() => qualityInRevisionTab && onClickInfram('LMLO')}
                  onQualityChipClick={() =>
                    qualityInRevisionTab && dispatch(actions.switchQualityEvaluation('LMLO', t))
                  }
                  onBuckyHeightClick={() =>
                    qualityInRevisionTab && dispatch(actions.switchBuckyHeightQuality('LMLO'))
                  }
                  onClickBlur={() => qualityInRevisionTab && onClickBlur('LMLO')}
                  onPectShapeClick={() =>
                    qualityInRevisionTab &&
                    dispatch(actions.switchPectShapeQuality('LMLO', 'PectShape', 3))
                  }
                  onPectLevelClick={() =>
                    qualityInRevisionTab &&
                    dispatch(actions.switchQuality('LMLO', 'PectLevel', 3, false))
                  }
                  tooltipProj={(feature) =>
                    !!projectionData.lmlo
                      ? getTooltipText('lmlo', feature)
                      : t('ResultView.not_available')
                  }
                />
              </div>
            </div>
          </div>

          <div className={classes.container_symmetry} style={{ bottom: '1%' }}>
            {qualityChipsShown &&
              QUALITY_SYSTEM !== 'german' &&
              symmetryChip(
                symmetryMLO,
                'MLO',
                null,
                qualityInRevisionTab && onClickSymmetryMLO,
                zoomedProjection,
                classes,
                theme,
                t
              )}
            {qualityChipsShown &&
              QUALITY_SYSTEM === 'german' &&
              singleSymmetryChip(
                qualityEvaluation,
                'mlo',
                qualityInRevisionTab,
                onClickSingularSymmetry,
                zoomedProjection,
                classes,
                theme,
                t
              )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default QualityView;
