import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import * as actions from "../../../store/index";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  SvgIcon,
  CardActions,
  Container,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import ProjectionsView from "./ProjectionsView/ProjectionsView";
import QualityView from "./QualityView/QualityView";
import DanaiTab from "./DanaiView/DanaiTab";
import ResultDialogTitle from "./Title/ResultDialogTitle";
import QualityIcon from "../../../resources/icons/BQuality.png";
import BDiagnose from "../../../resources/icons/BDiagnose.png";
import { ReactComponent as Summary_Icon } from "../../../resources/icons/mammography_icon.svg";
import { PREMIUM_DATA_ANNOTATION } from "../../../config";
import SummaryCardActions from "./CardActions/SummaryCardActions";
import ReviseCardActions from "./CardActions/ReviseCardActions";
import RevisionTools from "./Common/RevisionTools";
import Draggable from "react-draggable";
import InRevisionDialog from "./CardActions/InRevisionDialog";
import { setCustomResults } from "../../../store/slices/labeling";
import SummaryFront from "./Summary/SummaryFront";
import {
  processCCProjection,
  processMLOProjection,
} from "./DanaiView/Diagnostic/Helpers/DiagnosticRevisionTableHelpers";
import { fetchGet } from "../../../helpers/fetching";

const useStyles = makeStyles((theme) => ({
  main_container: {
    overflow: "hidden",
    flexGrow: 1,
    width: "100vw",
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: theme.palette.complementary.darker,
    height: "95vh",
    top: "5%",
  },

  main_container_landscape: {
    overflow: "hidden",
    flexGrow: 1,
    width: "100vw",
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: theme.palette.complementary.darker,
    height: "95vh",
    top: "2%",
  },

  main_container_revising: {
    overflow: "hidden",
    flexGrow: 1,
    width: "100vw",
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: theme.palette.complementary.darker,
    height: "100vh",
  },

  result_view_main_container: {
    display: "flex",
    width: "100%",
    height: "100%",
    maxHeight: "100vh",
    justifyContent: "space-between",
    borderRadius: "5px",
    overflow: "hidden",
  },

  summary_container: {
    height: "100%",
    borderRadius: "5px",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
    maxHeight: "100vh",
  },

  header_container: {
    padding: 0,
    borderRadius: "5px",
    maxWidth: "100%",
    overflow: "hidden",
    height: "34px",
    minHeight: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  header_container_tabs: {
    padding: 0,
    borderRadius: "5px",
    maxWidth: "100%",
    height: "36px",
    minHeight: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  card_container: {
    justifyItems: "center",
    padding: "10px 0 0 0 !important",
    height: "100%",
    overflow: "hidden",
    maxHeight: "98vh",
  },

  placeholder_container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: theme.palette.complementary.mainDark,
    borderRadius: "5px",
    justifyContent: "center",
    flexDirection: "column",
  },

  main_card_details_container: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",

    overflowY: "auto",
  },

  placeholder_helper: {
    color: "gray",
    fontSize: theme.fonts.body1,
    maxWidth: "70%",
  },

  placeholder_title: {
    letterSpacing: "0.1rem",
    textTransform: "uppercase",
    color: "#b8b8b8",
    fontSize: theme.fonts.responsiveHeader,
  },

  header_icon: {
    width: "20px",
  },

  header_icon_disabled: { width: "20px", opacity: 0.3 },

  titleResultCard: {
    marginLeft: "10px",
    letterSpacing: "0.1rem",
    fontSize: theme.fonts.responsiveBig,
    alignSelf: "center",
    minWidth: "fit-content",
    textTransform: "none",
  },

  title_danai: {
    marginLeft: "10px",
    letterSpacing: "0.1rem",
    fontSize: theme.fonts.responsiveBig,
    alignSelf: "center",
    minWidth: "fit-content",
    textTransform: "none",
    color: "gray",
  },

  tab_buttons: {
    width: "30%",
    height: "100%",
    borderRadius: 0,
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },

  dragable_tool: {
    padding: "12px 6px",
    minHeight: "18vh",
    position: "absolute",
    boxShadow: "rgb(19 19 19 / 78%) 0px 0px 6px 2px",
    background: theme.palette.complementary.darker,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    cursor: "move",
    zIndex: 1000,
    borderRadius: "5px",
  },
}));

function ResultView(props) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token, study_id } = useParams();
  const history = useHistory();

  // Store State
  const results = useSelector((state) => state.results);
  const {
    applied_density,
    applied_opacities,
    applied_microcalc,
    shown_density,
    shown_microcalc,
    shown_opacities,
    shown_quality,
    applied_quality,
    exam_id,
  } = results;
  const { currentlyProcessed, exam_list, currentExam } = useSelector(
    (state) => state.examinations
  );
  const filter = useSelector((state) => state.filter);
  const {
    tabsIndex,
    examinationListDrawer,
    activeDialog,
    darkState,
    landscape,
  } = useSelector((state) => state.gui);

  const labeling = useSelector((state) => state.labeling);

  const {
    qualityInRevisionMode,
    qualityInDrawingMode,
    densityInRevisionMode,
    diagnosticsInRevisionMode,
    localChangesDensity,
    localChangesQuality,
    localChangesMicrocalc,
    localChangesOpacities,
    customOpacities,
  } = labeling;

  //Variables
  const annotatorRole =
    PREMIUM_DATA_ANNOTATION && JSON.parse(localStorage.getItem("annotator"));

  const showRevisionTool =
    (tabsIndex === 2 && qualityInRevisionMode && qualityInDrawingMode) ||
    diagnosticsInRevisionMode;

  const densityShown = shown_density || applied_density;
  const microcalcShown = shown_microcalc || applied_microcalc;
  const opacitiesShown = shown_opacities || applied_opacities;
  const qualityShown = shown_quality || applied_quality;

  const resultMicrocalcEvaluation =
    results?.evaluations?.microcalc?.[microcalcShown];
  const resultOpacitiesEvaluation =
    results?.evaluations?.opacities?.[opacitiesShown];
  const resultDensityEvaluation = results?.evaluations?.density?.[densityShown];
  const resultQualityEvaluation = results?.evaluations?.quality?.[qualityShown];

  const someLocalChanges =
    localChangesDensity ||
    localChangesQuality ||
    localChangesMicrocalc ||
    localChangesOpacities;
  const rightBreastProjections = ["rcc", "rmlo"];
  const leftBreastProjections = ["lcc", "lmlo"];

  async function authenticateURLToken(token) {
    const response = await fetchGet(`login/${token}`);
    if (response.success) {
      localStorage.setItem("username", response.username);
      localStorage.setItem("role", response.role);
      localStorage.setItem("annotation", response.annotation);
      localStorage.setItem("diagnostics", response.diagnostics);
      localStorage.setItem("aliases", response.aliases);
      localStorage.setItem("last_login", response.last_login);
      console.log("You are authorized");
    } else {
      console.log("Token not recognized. You shall no pass!");
      history.push('/logout');
    }
  }

  useEffect(() => {
    dispatch(setCustomResults(results));
  }, [results]);

  useEffect(() => {
    dispatch(actions.setLoadedProjections(0));
    dispatch(actions.setZoomedProjection(null));
    dispatch(actions.setInitialResult());
    dispatch(actions.hideLinesShown());

    if (tabsIndex === 2) {
      dispatch(actions.setTabsIndex(0));
    }

    if (!!currentExam) {
      dispatch(actions.fetchResults());
      if (study_id !== currentExam) {
        history.push(`/bbox/results/details/${currentExam}`);
      }
    }
  }, [currentExam]);

  useEffect(() => {
    if (!currentExam && exam_list.length > 0) {
      dispatch(actions.setCurrentExam(exam_list[0].id));
    } else if (exam_list.length === 0) {
      dispatch(actions.setCurrentExam(null));
    }
  }, [dispatch, exam_list, currentExam]);

  useEffect(() => {
    if (tabsIndex === 0) {
      dispatch(actions.hideAllLesions());
      dispatch(actions.setDensityInRevisionMode(false));
      dispatch(actions.setDiagnosticsInRevisionMode(false));
      dispatch(actions.setQualityInRevisionMode(false));
      dispatch(actions.setZoomedProjection(null));
      dispatch(actions.setQualityInDrawingMode(false));
    }
  }, [currentExam, tabsIndex]);

  useEffect(() => {
    if (!!study_id && study_id !== currentExam) {
      const newFilter = {
        ...filter,
        study_id,
      };
      dispatch(actions.setFilter(newFilter));
      dispatch(actions.setCurrentExam(study_id));
    }
    return () => {
      dispatch(actions.setFilterProperty(["study_id", null]));
    };
  }, [study_id]);

  useEffect(() => {
    const newGroupedBoxes = { RightBreast: [], LeftBreast: [] };
    const lesion_index_mapping = {};

    Object.entries(customOpacities).forEach(([projection, biradsData]) => {
      if (typeof biradsData === "object" && biradsData !== null) {
        Object.entries(biradsData).forEach(([biradsKey, boxes]) => {
          if (Array.isArray(boxes)) {
            boxes.forEach((box, i) => {
              const isRightBreast = rightBreastProjections.includes(projection);
              const isLeftBreast = leftBreastProjections.includes(projection);

              if (isRightBreast || isLeftBreast) {
                const breast = isRightBreast ? "RightBreast" : "LeftBreast";
                const processProjection = projection.includes("cc")
                  ? processCCProjection
                  : processMLOProjection;

                processProjection(
                  breast,
                  newGroupedBoxes,
                  projection,
                  biradsKey,
                  box,
                  i,
                  lesion_index_mapping
                );
              }
            });
          }
        });
      }
    });

    dispatch(actions.setLesionIndexMapping(lesion_index_mapping));
    dispatch(actions.setGroupedOpacities(newGroupedBoxes));
  }, [labeling?.customOpacities]);

  useEffect(() => {
    if (!token) return;
    authenticateURLToken(token);
  }, [token]);

  // Group opacities
  function SummaryTabIcon(props) {
    return (
      <SvgIcon {...props}>
        <Summary_Icon />
      </SvgIcon>
    );
  }

  const resultViewCardActions = () => {
    if (
      qualityInRevisionMode ||
      densityInRevisionMode ||
      diagnosticsInRevisionMode
    ) {
      return <ReviseCardActions />;
    } else return <SummaryCardActions />;
  };

  const discardChanges = () => {
    if (localChangesDensity) {
      dispatch(actions.setDensityInRevisionMode(false));
      dispatch(actions.setDensityLocalChanges(false));
      dispatch(actions.setCustomDensity(resultDensityEvaluation));
    } else if (localChangesMicrocalc || localChangesOpacities) {
      dispatch(actions.setMicrocalcLocalChanges(false));
      dispatch(actions.setOpacitiesLocalChanges(false));
      dispatch(actions.setDiagnosticsInRevisionMode(false));
      dispatch(actions.setCustomMicrocalc(resultMicrocalcEvaluation));
      dispatch(actions.setCustomOpacities(resultOpacitiesEvaluation));
    } else if (localChangesQuality) {
      dispatch(actions.setShownQualityOverlayType(""));
      dispatch(actions.setQualityInRevisionMode(false));
      dispatch(actions.setQualityInDrawingMode(false));
      dispatch(actions.setCustomQualityOverlays({}));
      dispatch(actions.setQualityLocalChanges(false));
      dispatch(actions.hideAllLesions());
      dispatch(actions.setCustomQuality(resultQualityEvaluation));
      dispatch(actions.setTabsIndex(1));
    }
    if (activeDialog === "unsavedChanges" && !localChangesQuality)
      dispatch(actions.setTabsIndex(0));
    dispatch(actions.hideAllLesions());
    dispatch(actions.setInitialImages());
    dispatch(actions.setActiveDialog(null));
    dispatch(actions.setZoomedProjection(null));
    dispatch(actions.setQualityInDrawingMode(false));
  };

  const saveLocalChanges = () => {
    if (localChangesDensity) {
      dispatch(actions.saveEvaluation("density", t));
      dispatch(actions.setDensityInRevisionMode(false));
      dispatch(actions.setDensityLocalChanges(false));
      dispatch(actions.setTabsIndex(0));
    } else if (localChangesMicrocalc || localChangesOpacities) {
      if (localChangesMicrocalc && localChangesOpacities) {
        dispatch(actions.saveEvaluation("microcalc", t));
        dispatch(actions.saveEvaluation("opacities", t));
      } else if (localChangesMicrocalc) {
        dispatch(actions.saveEvaluation("microcalc", t));
      } else if (localChangesOpacities) {
        dispatch(actions.saveEvaluation("opacities", t));
      }
      dispatch(actions.setMicrocalcLocalChanges(false));
      dispatch(actions.setOpacitiesLocalChanges(false));
      dispatch(actions.setDiagnosticsInRevisionMode(false));
      dispatch(actions.setTabsIndex(0));
    } else if (localChangesQuality) {
      dispatch(actions.saveEvaluation("quality", t));
      dispatch(actions.setShownQualityOverlayType(""));
      dispatch(actions.setQualityInRevisionMode(false));
      dispatch(actions.setQualityInDrawingMode(false));
      dispatch(actions.setCustomQualityOverlays({}));
      dispatch(actions.setQualityLocalChanges(false));
      dispatch(actions.setTabsIndex(1));
    }
    dispatch(actions.hideAllLesions());
    dispatch(actions.setActiveDialog(null));
    dispatch(actions.setZoomedProjection(null));
  };

  return (
    <Container
      maxWidth={false}
      className={
        tabsIndex === 2
          ? classes.main_container_revising
          : landscape
          ? classes.main_container
          : classes.main_container_landscape
      }
      style={{
        borderTopLeftRadius: "5px",
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
      }}
    >
      <Helmet>
        <title>{t("b_box.pat_details")}</title>
        <meta name="patient_details" />
      </Helmet>
      {activeDialog === "unsavedChanges" && (
        <InRevisionDialog
          open={activeDialog === "unsavedChanges"}
          onDiscard={() => discardChanges()}
          onClose={() => dispatch(actions.setActiveDialog(null))}
          onSave={() => saveLocalChanges()}
        />
      )}
      {showRevisionTool && (
        <Draggable
          bounds="parent"
          axis="both"
          defaultPosition={{
            x: landscape
              ? examinationListDrawer
                ? 48
                : -45
              : examinationListDrawer
              ? 20
              : -25,
            y: 600,
          }}
        >
          <div className={classes.dragable_tool}>
            <RevisionTools />
          </div>
        </Draggable>
      )}
      <Grid
        container
        spacing={1}
        className={classes.result_view_main_container}
        style={{
          backgroundColor: theme.palette.background.default,
          overflowY: landscape ? "hidden" : "scroll",
        }}
      >
        <Grid item xs={landscape ? 6 : 12}>
          <Card className={classes.summary_container} elevation={0}>
            <CardHeader
              style={{
                padding: "0",
                background: darkState
                  ? theme.palette.complementary.mainDark
                  : "#d1d1d1",
              }}
              className={classes.header_container}
              title={
                <ResultDialogTitle
                  titlePrevious={dispatch(actions.getNextPatientName(-1)) || ""}
                  onClickBackward={() => {
                    !someLocalChanges
                      ? (discardChanges(),
                        dispatch(actions.switchExamResult(-1)))
                      : dispatch(actions.setActiveDialog("unsavedChanges"));
                  }}
                  onClickForward={() => {
                    !someLocalChanges
                      ? (discardChanges(),
                        dispatch(actions.switchExamResult(1)))
                      : dispatch(actions.setActiveDialog("unsavedChanges"));
                  }}
                  titleNext={dispatch(actions.getNextPatientName(1)) || ""}
                />
              }
            />
            <CardContent className={classes.card_container}>
              <ProjectionsView />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={landscape ? 6 : 12} style={{ maxHeight: "100%" }}>
          <Card className={classes.summary_container} elevation={0}>
            <CardHeader
              style={{
                padding: "0",
                background: darkState
                  ? theme.palette.complementary.mainDark
                  : "#d1d1d1",
              }}
              className={classes.header_container}
              title={
                <div className={classes.header_container_tabs}>
                  <div
                    onClick={() =>
                      !someLocalChanges
                        ? (dispatch(actions.setTabsIndex(0)), discardChanges())
                        : dispatch(actions.setActiveDialog("unsavedChanges"))
                    }
                    className={classes.tab_buttons}
                    style={{
                      color: tabsIndex === 0 && theme.palette.primary.main,
                      borderBottom:
                        tabsIndex === 0 &&
                        `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <SummaryTabIcon
                      color="inherit"
                      fontSize="small"
                      width="25px"
                    />
                    <Typography className={classes.titleResultCard}>
                      {t("ResultView.summary")}
                    </Typography>
                  </div>

                  <div
                    onClick={() =>
                      !someLocalChanges
                        ? (dispatch(actions.setTabsIndex(1)), discardChanges())
                        : dispatch(actions.setActiveDialog("unsavedChanges"))
                    }
                    className={classes.tab_buttons}
                    style={{
                      color: tabsIndex === 1 && theme.palette.primary.main,
                      borderBottom:
                        tabsIndex === 1 &&
                        `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <img src={QualityIcon} className={classes.header_icon} />
                    <Typography className={classes.titleResultCard}>
                      {t("b_box.b_quality")}
                    </Typography>
                  </div>

                  <div
                    onClick={() =>
                      !someLocalChanges
                        ? discardChanges()
                        : dispatch(actions.setActiveDialog("unsavedChanges"))
                    }
                    className={classes.tab_buttons}
                    style={{
                      color: tabsIndex === 2 && theme.palette.primary.main,
                      borderBottom:
                        tabsIndex === 2 &&
                        `2px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <img
                      src={BDiagnose}
                      className={
                        densityInRevisionMode ||
                        diagnosticsInRevisionMode ||
                        qualityInRevisionMode
                          ? classes.header_icon
                          : classes.header_icon_disabled
                      }
                    />
                    <Typography
                      className={
                        densityInRevisionMode ||
                        diagnosticsInRevisionMode ||
                        qualityInRevisionMode
                          ? classes.titleResultCard
                          : classes.title_danai
                      }
                    >
                      DANAI
                    </Typography>
                  </div>
                </div>
              }
            />
            <CardContent className={classes.card_container}>
              {(!currentExam || exam_list.length === 0) && !token ? (
                <div className={classes.placeholder_container}>
                  <Typography
                    className={classes.placeholder_title}
                    color="primary"
                    gutterBottom
                  >
                    {t("ResultView.empty")}
                  </Typography>
                  <Typography
                    className={classes.placeholder_helper}
                    color="primary"
                    gutterBottom
                  >
                    {t("ResultView.placeholder")}
                  </Typography>
                </div>
              ) : (
                <div className={classes.main_card_details_container}>
                  {/* Summary Tab */}
                  {tabsIndex === 0 && <SummaryFront />}

                  {/* Quality Tab */}
                  {tabsIndex === 1 && <QualityView />}

                  {/* Diagnostic and density Tab */}
                  {tabsIndex === 2 &&
                    (densityInRevisionMode ||
                      diagnosticsInRevisionMode ||
                      qualityInRevisionMode) && <DanaiTab />}
                </div>
              )}
            </CardContent>

            {currentExam && exam_list.length !== 0 && (
              <CardActions
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: darkState
                    ? theme.palette.complementary.mainDark
                    : "rgb(209, 209, 209)",
                  borderRadius: "5px",
                  padding: "0",
                  marginTop: "8px",
                }}
              >
                {resultViewCardActions()}
              </CardActions>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default React.memo(ResultView);
