import { useTranslation } from 'react-i18next';
import { QUALITY_VALUES } from '../../../config';

export const filterOptionsTitle = (filterKey) => {
  const { t } = useTranslation();
  return t(`Filters.${filterKey}`).toUpperCase();
};

export const filterOptionsLabels = (filterTitle) => {
  const { t } = useTranslation();

  const qualityValues = QUALITY_VALUES.map((value) => t(`Quality.${value}`));

  if (filterTitle === 'density') return ['ACR A', 'ACR B', 'ACR C', 'ACR D'];
  if (filterTitle === 'quality') return qualityValues || QUALITY_VALUES;
  if (filterTitle === 'modality') return [t('Filters.modality_mamo'), t('Filters.modality_tomo')];

  if (filterTitle === 'screening')
    return [t('Filters.screening_screening'), t('Filters.screening_diagnostic')];

  if (filterTitle === 'opacities' || filterTitle === 'microcalc')
    return ['BI-RADS 2', 'BI-RADS 3', 'BI-RADS 4', 'BI-RADS 5', t('Filters.diagnosis_lesions_no')];

  if (filterTitle === 'postop')
    return [t('Filters.postop_operated'), t('Filters.postop_not_operated')];
  if (filterTitle === 'multiExams') return [t('Filters.multi_exams')];
  if (filterTitle === 'multiCaptures') return [t('Filters.multi_captures')];
  if (filterTitle === 'annotatedOnly') return [t('Filters.reannotated')];
  if (filterTitle === 'confirmedOnly') return [t('Filters.confirmed')];

  if (filterTitle === 'revision')
    return [
      t('Filters.not_confirmed'),
      t('Filters.confirmed_density'),
      t('Filters.confirmed_quality'),
      t('Filters.confirmed_opacities'),
      t('Filters.confirmed_microcalc'),
    ];

  if (filterTitle === 'risk')
    return [t('Filters.normal_risk'), t('Filters.increased_risk'), t('Filters.higher_risk')];
};
