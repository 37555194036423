import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProjectionsCard from './Projections/ProjectionsCard';
import alt_rcc_lang from '../../../../resources/missing_rcc_.png';
import alt_lcc_lang from '../../../../resources/missing_lcc_.png';
import alt_rmlo_lang from '../../../../resources/missing_rmlo_.png';
import alt_lmlo_lang from '../../../../resources/missing_lmlo_.png';
import alt_rcc from '../../../../resources/missing_rcc.png';
import alt_lcc from '../../../../resources/missing_lcc.png';
import alt_rmlo from '../../../../resources/missing_rmlo.png';
import alt_lmlo from '../../../../resources/missing_lmlo.png';
import CapturesDialog from './CapturesDialog/CapturesDialog';
import { setLoadedProjections } from '../../../../store';
import ZoomView from './ZoomView/ZoomView';

const useStyles = makeStyles((theme) => ({
  projections_view_container: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    backgroundColor: theme.palette.complementary.mainDark,
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    padding: '0 12px',
  },

  onLoad: {
    opacity: '1!important',
    transition: 'opacity 0.4s',
  },

  hidden: {
    opacity: 0,
  },

  spinner_loader: {
    position: 'absolute',
    zIndex: 1000,
  },

  proj_missing_img: {
    borderRadius: '5px',
    margin: 'auto',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },

  proj_missing_img_v: {
    maxWidth: '100%',
    borderRadius: '5px',
    margin: 'auto',
  },
}));

function ProjectionsView() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [secondCaptureOpen, setSecondCaptureOpen] = useState(null);
  const [projFlipped, setProjFlipped] = useState([false, false, false, false]);
  const [isLoading, setIsLoading] = useState(false);

  // Store state
  const results = useSelector((state) => state.results);
  const images = useSelector((state) => state.images);
  const currentExam = useSelector((state) => state.examinations.currentExam);
  const { landscape, tabsIndex, language } = useSelector((state) => state.gui);

  const { folder, projections, second_captures } = results;

  // Effects
  useEffect(() => {
    setProjFlipped([false, false, false, false]);
    setSecondCaptureOpen(null);
  }, [folder]);

  useEffect(() => {
    const expectedProjections = Object.values(projections).filter((l) => !!l);
    const loading =
      !!currentExam &&
      (images.loadedProjections === 0 || images.loadedProjections < expectedProjections);
    setIsLoading(loading);
  }, [images.loadedProjections, projections, dispatch]);

  // Handlers
  const secondOpenHandle = (proj) => {
    if (proj === secondCaptureOpen) setSecondCaptureOpen(null);
    else setSecondCaptureOpen(proj);
  };

  const classesZoomView = [classes.hidden];
  if (!isLoading) classesZoomView.push(classes.onLoad);

  return (
    <div className={classes.projections_view_container}>
      {isLoading && <CircularProgress size={80} className={classes.spinner_loader} />}

      <ZoomView
        className={classesZoomView.join(' ')}
        projectionRcc={projections?.rcc}
        projectionLcc={projections?.lcc}
        projectionLmlo={projections?.lmlo}
        projectionRmlo={projections?.rmlo}
      >
        {!!projections.rcc ? (
          <ProjectionsCard
            proj_name='rcc'
            onCaptureClick={second_captures?.rcc.length ? () => secondOpenHandle('rcc') : null}
            onLoad={() => dispatch(setLoadedProjections(images.loadedProjections + 1))}
            uid={results?.projections?.rcc?.uid}
          />
        ) : (
          <img
            key='rcc'
            className={landscape ? classes.proj_missing_img : classes.proj_missing_img_v}
            src={language === 'en' || !language ? alt_rcc : alt_rcc_lang}
            alt='RCC missing'
            style={{
              maxHeight: landscape ? (tabsIndex === 2 ? '46vh' : '43vh') : 'calc(25vh - 100px)',
            }}
          />
        )}

        {!!projections.lcc ? (
          <ProjectionsCard
            left
            proj_name='lcc'
            onCaptureClick={second_captures?.lcc.length ? () => secondOpenHandle('lcc') : null}
            onLoad={() => dispatch(setLoadedProjections(images.loadedProjections + 1))}
            uid={results?.projections?.lcc?.uid}
          />
        ) : (
          <img
            key='lcc'
            className={landscape ? classes.proj_missing_img : classes.proj_missing_img_v}
            src={language === 'en' || !language ? alt_lcc : alt_lcc_lang}
            alt='LCC missing'
            style={{
              maxHeight: landscape ? (tabsIndex === 2 ? '46vh' : '43vh') : 'calc(25vh - 100px)',
            }}
          />
        )}

        {!!projections.rmlo ? (
          <ProjectionsCard
            proj_name='rmlo'
            onCaptureClick={second_captures?.rmlo.length ? () => secondOpenHandle('rmlo') : null}
            onLoad={() => dispatch(setLoadedProjections(images.loadedProjections + 1))}
            uid={results?.projections?.rmlo?.uid}
          />
        ) : (
          <img
            key='rmlo'
            className={landscape ? classes.proj_missing_img : classes.proj_missing_img_v}
            src={language === 'en' || !language ? alt_rmlo : alt_rmlo_lang}
            alt='RMLO missing'
            style={{
              maxHeight: landscape ? (tabsIndex === 2 ? '46vh' : '43vh') : 'calc(25vh - 100px)',
            }}
          />
        )}

        {!!projections.lmlo ? (
          <ProjectionsCard
            left
            proj_name='lmlo'
            onCaptureClick={second_captures?.lmlo.length ? () => secondOpenHandle('lmlo') : null}
            onLoad={() => dispatch(setLoadedProjections(images.loadedProjections + 1))}
            uid={results?.projections?.lmlo?.uid}
          />
        ) : (
          <img
            key='lmlo'
            className={landscape ? classes.proj_missing_img : classes.proj_missing_img_v}
            src={language === 'en' || !language ? alt_lmlo : alt_lmlo_lang}
            alt='LMLO missing'
            style={{
              maxHeight: landscape ? (tabsIndex === 2 ? '46vh' : '43vh') : 'calc(25vh - 100px)',
            }}
          />
        )}
      </ZoomView>
      {!!secondCaptureOpen && (
        <CapturesDialog
          openDialog={!!secondCaptureOpen}
          onClose={() => secondOpenHandle(null)}
          proj_name={secondCaptureOpen}
          uids={[
            ...second_captures[secondCaptureOpen],
            projections[secondCaptureOpen] ? projections[secondCaptureOpen].uid : '',
          ]}
        />
      )}
    </div>
  );
}

export default ProjectionsView;
