import React, { useEffect, useState } from 'react';
import { Chip, Tooltip, Typography, Zoom } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import style from './QualityProjections.module.css';
import { useTheme } from '@material-ui/core/styles';
import * as actions from '../../../../../store';

import useResponsive from '../../../../../helpers/hooks/useResponsive';
import { qualityEvaluationChip } from '../../../../../helpers/qualityProjection';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';

import concept_rmlo from '../../../../../resources/concept_images/Concept_RMLO.png';
import concept_mlo_concave from '../../../../../resources/concept_images/ConceptImg_MLO_concave.png';
import concept_mlo_convex from '../../../../../resources/concept_images/ConceptImg_MLO_convex.png';
import concept_mlo_straight from '../../../../../resources/concept_images/ConceptImg_MLO_straight.png';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import HeightIcon from '@material-ui/icons/Height';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import { BLUR } from '../../../../../config';

import {
  parenchymaClass,
  nippleProfileClass,
  pectoAngleClass,
  inframFoldClass,
  pectoLevelClass,
  blurAreaChip,
  skinFoldsChip,
  compressionChip,
} from './QualityCommon/QualityCommon';
import { useStyles } from './QualityCommon/QualityProjections.style';

const QualityMLO = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isXSmallScreen, isSmallScreen, isMediumScreen, isXXLargeScreen } = useResponsive();
  const zoomedProjection = useSelector((state) => state.gui.zoomedProjection);
  const landscape = useSelector((state) => state.gui.landscape);
  const darkState = useSelector((store) => store.gui.darkState);
  const { examinationListDrawer, tabsIndex } = useSelector((state) => state.gui);
  const guiPresentationDisplay = useSelector((store) => store.gui.presentation_lesions);
  const projections = useSelector((state) => state.results.projections);
  const { qualityInRevisionMode, qualityInDrawingMode, shownQuality } = useSelector(
    (state) => state.labeling
  );

  const {
    view_position,
    result,
    onClickNipple,
    onClickPectoAngle,
    onClickParen,
    onQualityChipClick,
    onClickInfram,
    onBuckyHeightClick,
    onClickBlur,
    onPectShapeClick,
    onPectLevelClick,
  } = props;

  const [flip, setFlip] = useState(null);
  const [pnlIsHovered, setPnlIsHovered] = useState(false);
  const [flip_rotate, setFlipRotate] = useState(null);
  const [conceptImg, setConceptImg] = useState(null);
  const [pectLabel, setPectLabel] = useState('');

  const notOnlyBoxes = guiPresentationDisplay !== 'boxes';
  const skinFolds = result?.skinFolds;
  const left = view_position === 'lmlo';
  const blurredAreas = result?.blur;

  // Revision mode

  const qualityFeatInRevision = tabsIndex === 2 && qualityInRevisionMode && !!qualityInDrawingMode;

  const qualityInfoMode =
    tabsIndex === 1 || (tabsIndex === 2 && qualityInRevisionMode && !qualityInDrawingMode);

  const qualityInRevison = tabsIndex === 2 && qualityInRevisionMode && !qualityInDrawingMode;

  const resultQualityEvaluation = useAppliedEvaluation(
    'quality',
    shownQuality,
    qualityInRevisionMode
  );

  // Effects
  useEffect(() => {
    if (left) {
      setFlip({ transform: 'scaleX(-1)' });
      setFlipRotate({ transform: 'scaleX(-1) rotate(19deg)' });
    } else setFlip(null);
  }, [props]);

  useEffect(() => {
    const preloadImages = [
      concept_rmlo,
      concept_mlo_straight,
      concept_mlo_concave,
      concept_mlo_convex,
    ];

    preloadImages.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    let label = t('ResultView.is_not_evaluated');
    let selectedConceptImage = concept_rmlo;

    if (qualityFeatInRevision) {
      setConceptImg(selectedConceptImage);
      setPectLabel(label);
    } else if (result?.PectShape === 'straight') {
      selectedConceptImage = concept_mlo_straight;
      label = t('ResultView.straight_pect_shape');
    } else if (result?.PectShape === 'concave') {
      selectedConceptImage = concept_mlo_concave;
      label = t('ResultView.concave_pect_shape');
    } else if (result?.PectShape === 'convex') {
      selectedConceptImage = concept_mlo_convex;
      label = t('ResultView.convex_pect_shape');
    }

    setConceptImg(selectedConceptImage);
    setPectLabel(label);
  }, [result?.PectShape, qualityFeatInRevision]);

  const smallView =
    examinationListDrawer || zoomedProjection || isXSmallScreen || isSmallScreen || isMediumScreen;

  const handlePnlMouseEnter = () => {
    setPnlIsHovered(true);
    dispatch(actions.setLinesToShow(view_position));
  };

  const handlePectAngleMouseEnter = () => {
    dispatch(actions.setPectAngleLinesToShow(view_position));
  };

  const handlePnlMouseLeave = () => {
    setPnlIsHovered(false);
    dispatch(actions.hideLinesShown());
  };

  const showParencOverlays = (proj) => {
    if (proj !== 'correct') dispatch(actions.setParenchymaCutsToShow(view_position));
    if (proj === 'correct') dispatch(actions.hideParenchymaCutsShown(view_position));

    const newLesionsToShow = {
      [`parenchyma${view_position?.toUpperCase()}`]: true,
    };

    dispatch(actions.setLinesToShow(view_position));

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const hideParencOverlays = () => {
    dispatch(actions.hideParenchymaCutsShown(view_position));
    dispatch(actions.hideAllLesions());
    if (!pnlIsHovered) dispatch(actions.hideLinesShown());
  };

  const showPectOverlays = () => {
    const newLesionsToShow = {
      [`pectoralis${view_position?.toUpperCase()}`]: true,
    };

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const hidePectOverlays = () => {
    dispatch(actions.hideAllLesions());
  };

  const showNippleProfile = () => {
    dispatch(actions.setNippleProfileToShow(view_position));
  };

  const showIMF = () => {
    dispatch(actions.setIMFToShow(view_position));
  };

  const handlePectoLevelMouseEnter = () => {
    dispatch(actions.setPectLevelToShow(view_position));
  };

  const handlePectoLevelMouseLeave = () => {
    dispatch(actions.hidePectLevel(view_position));
  };

  const chipsPosition = () => {
    if (isXXLargeScreen)
      return {
        right: left ? (examinationListDrawer || zoomedProjection ? '-5%' : '-11%') : undefined,
        left: left ? undefined : examinationListDrawer || zoomedProjection ? '-5%' : '-11%',
      };
    else
      return {
        right: left ? (smallView ? '-10%' : '-20%') : undefined,
        left: left ? undefined : smallView ? '-10%' : '-20%',
      };
  };

  const showSkinFolds = (proj) => {
    const newLesionsToShow = {
      [`skinFolds${proj.toUpperCase()}`]: true,
    };

    dispatch(actions.setQualityOverlaysToShow(newLesionsToShow));
  };

  const buckyHeightInfo = (elevation) => {
    let color, label, icon, tooltipLabel;

    if (elevation === 'correct') {
      color = theme.palette.projections.buckyCorrect;
      tooltipLabel = t('ResultView.breast_center');
      label = t('ResultView.centered');
      icon = (
        <HeightIcon
          fontSize='small'
          style={{
            color: 'inherit',
            cursor: 'help',
            width: '18px',
            transform: 'rotate(90deg)',
          }}
        />
      );
    } else if (elevation === 'high') {
      color = theme.palette.projections.buckyInsufficient;
      tooltipLabel = t('ResultView.breast_low');
      label = `${t('ResultView.bucky_height')}: ${t('ResultView.high')}`;
      icon = (
        <ArrowUpwardIcon
          fontSize='small'
          style={{
            color: 'inherit',
            cursor: 'help',
            width: '18px',
          }}
        />
      );
    } else if (elevation === 'low') {
      color = theme.palette.projections.buckyInsufficient;
      tooltipLabel = t('ResultView.breast_high');
      label = `${t('ResultView.bucky_height')}: ${t('ResultView.low')}`;
      icon = (
        <ArrowDownwardIcon
          fontSize='small'
          style={{
            color: 'inherit',
            cursor: 'help',
            width: '18px',
          }}
        />
      );
    } else if (elevation === 'not_evaluated' || !elevation) {
      color = theme.palette.projections.not_evaluated;
      tooltipLabel = t('ResultView.elev_not_eval');
      label = t('ResultView.is_not_evaluated');
      icon = (
        <SettingsEthernetIcon
          fontSize='small'
          style={{
            color: 'inherit',
            cursor: 'help',
            width: '18px',
          }}
        />
      );
    }

    return { color, label, icon, tooltipLabel };
  };

  const buckyHeightChip = () => {
    return (
      <Tooltip
        arrow
        title={
          <Typography
            style={{
              fontSize: '1.3vh',
              lineHeight: 1.3,
            }}
          >
            {buckyHeightInfo(result?.elevation).tooltipLabel}
          </Typography>
        }
      >
        <Chip
          variant='outlined'
          label={buckyHeightInfo(result?.elevation).label}
          size='small'
          icon={buckyHeightInfo(result?.elevation).icon}
          onClick={() => {
            if (onBuckyHeightClick && result !== null && qualityInRevison) onBuckyHeightClick();
          }}
          style={{
            color: buckyHeightInfo(result?.elevation).color,
            border: `1px solid ${buckyHeightInfo(result?.elevation).color}`,
            height: '20px',
            fontSize: zoomedProjection
              ? theme.fonts.responsiveMedium
              : theme.fonts.responsiveMediumBig,
            marginTop: '6px',
            cursor: qualityInRevison ? 'pointer' : 'help',
            minWidth: '91px',
            textTransform: 'capitalize',
          }}
        />
      </Tooltip>
    );
  };

  return (
    <div
      className={style.quality_projection_container}
      style={{ alignItems: flip ? 'flex-end' : 'flex-start' }}
    >
      {result &&
        (!qualityInRevisionMode || (qualityInRevisionMode && !qualityInDrawingMode)) &&
        qualityEvaluationChip(
          view_position,
          result?.quality,
          result?.quality_explanation,
          zoomedProjection,
          onQualityChipClick,
          examinationListDrawer || zoomedProjection,
          qualityInfoMode,
          qualityInRevisionMode,
          theme,
          t
        )}

      {result && (
        <div
          style={{
            height: '40px',
            maxWidth: '250px',
            position: 'absolute',
            top: '0',
            right: chipsPosition().right,
            left: chipsPosition().left,
            display: 'flex',
            flexDirection: 'column',
            alignItems: left ? 'flex-end' : 'flex-start',
            zIndex: theme.zIndex.tooltip,
          }}
        >
          {!qualityInRevisionMode &&
            !qualityInDrawingMode &&
            compressionChip(
              projections?.[props.view_position]?.compression,
              resultQualityEvaluation?.[props.view_position]?.compressionClass,
              zoomedProjection,
              theme,
              t
            )}
          {result?.elevation && !qualityInDrawingMode && buckyHeightChip()}
          {!qualityInRevisionMode &&
            !qualityInDrawingMode &&
            skinFoldsChip(
              skinFolds,
              showSkinFolds,
              view_position,
              hidePectOverlays,
              notOnlyBoxes,
              zoomedProjection,
              t,
              theme
            )}
          {BLUR === 'yes' &&
            ((result && !qualityInRevisionMode && !qualityInDrawingMode) ||
              (qualityInRevisionMode &&
                !qualityInDrawingMode &&
                result &&
                Object?.keys(result)?.length !== 0)) &&
            blurAreaChip(
              blurredAreas,
              onClickBlur,
              qualityInfoMode,
              qualityInRevison,
              zoomedProjection,
              theme,
              t
            )}
        </div>
      )}

      <div style={flip} className={style.QualityProj}>
        <img
          style={{
            maxHeight: landscape ? '35vh' : 'calc(33vh - 170px)',
            filter: darkState ? '' : 'contrast(0)',
          }}
          src={conceptImg || concept_rmlo}
          alt='not found'
        />
        <Tooltip
          title={
            <Typography
              style={{
                fontSize: '1.3vh',
                lineHeight: 1.3,
              }}
            >
              {nippleProfileClass(result?.Nipple, t)?.text}
            </Typography>
          }
          arrow
          interactive
          placement='bottom'
          TransitionComponent={Zoom}
          onOpen={() => qualityInfoMode && showNippleProfile()}
          onClose={() => qualityInfoMode && dispatch(actions.hideLinesShown())}
        >
          <button
            style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
            className={
              qualityFeatInRevision
                ? [classes.nippleMLOBase, classes.neutral]
                : `${classes.nippleMLOBase}  ${
                    classes[nippleProfileClass(result?.Nipple, t)?.classColor]
                  }`
            }
            id={style.nipple_mlo}
            onClick={() => {
              if (onClickNipple && result !== null && qualityInRevison) onClickNipple();
            }}
          />
        </Tooltip>
        {/* Inframammary fold */}
        <Tooltip
          title={
            <Typography
              style={{
                fontSize: '1.3vh',
                lineHeight: 1.3,
              }}
            >
              {inframFoldClass(result?.Infram, t)?.text}
            </Typography>
          }
          arrow
          interactive
          placement={left ? 'right-start' : 'left-start'}
          TransitionComponent={Zoom}
          onOpen={() => qualityInfoMode && showIMF()}
          onClose={() => qualityInfoMode && dispatch(actions.hideLinesShown())}
        >
          <button
            style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
            className={
              qualityFeatInRevision
                ? [classes.buttonBase, classes.inframBase, classes.neutral]
                : `${classes.buttonBase} ${classes.inframBase} ${
                    classes[inframFoldClass(result?.Infram, t)?.classColor]
                  }`
            }
            id={'infram'}
            onClick={() => {
              if (onClickInfram && result !== null && qualityInRevison) onClickInfram();
            }}
          />
        </Tooltip>
        {/* Pecto Angle */}
        <Tooltip
          title={
            <Typography
              style={{
                fontSize: '1.3vh',
                lineHeight: 1.3,
              }}
            >
              {pectoAngleClass(result?.PectAngle, result?.PectAngleValue, t)?.text}
            </Typography>
          }
          arrow
          interactive
          placement={left ? 'right-start' : 'left-start'}
          TransitionComponent={Zoom}
        >
          <button
            onMouseEnter={() => qualityInfoMode && handlePectAngleMouseEnter}
            onMouseLeave={() => qualityInfoMode && handlePnlMouseLeave}
            style={{
              cursor: qualityInRevison ? 'pointer' : 'help',
            }}
            className={
              qualityFeatInRevision
                ? [classes.buttonBase, classes.neutral]
                : `${classes.buttonBase} ${classes.pectAngleBase} ${
                    classes[
                      pectoAngleClass(result?.PectAngle, result?.PectAngleValue, t).classColor
                    ]
                  }`
            }
            id={style.angle}
            onClick={() => {
              if (onClickPectoAngle && result !== null && qualityInRevison) onClickPectoAngle();
            }}
          />
        </Tooltip>

        {/* Parenchyma */}
        <Tooltip
          onOpen={() => qualityInfoMode && showParencOverlays(result?.ParenchymaCuts)}
          onClose={() => qualityInfoMode && hideParencOverlays()}
          title={
            <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
              {parenchymaClass(result?.ParenchymaCuts, t)?.text}
            </Typography>
          }
          arrow
          interactive
          placement='top-end'
          TransitionComponent={Zoom}
        >
          <button
            className={
              qualityFeatInRevision
                ? [classes.parenMLOBase, classes.neutral]
                : `${classes.parenMLOBase} ${
                    classes[parenchymaClass(result?.ParenchymaCuts, t)?.classColor]
                  }`
            }
            style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
            id={'parenMLO'}
            onClick={() => {
              if (onClickParen && result !== null && qualityInRevison) onClickParen();
            }}
          />
        </Tooltip>

        {/* PNL */}
        <Tooltip
          onOpen={() => qualityInfoMode && handlePnlMouseEnter()}
          onClose={() => qualityInfoMode && handlePnlMouseLeave()}
          arrow
          title={
            <Typography
              style={{
                fontSize: '1.3vh',
                lineHeight: 1.3,
              }}
            >
              {result?.PectoNippleLength
                ? `${t('ResultView.pnl_measures')}
                   ${result?.PectoNippleLength?.toFixed(0)}mm`
                : t('ResultView.is_not_evaluated')}
            </Typography>
          }
        >
          <svg style={flip_rotate} className={classes.pnlSvgMLO}>
            <text
              x={left ? '40%' : '50%'}
              y='35%'
              fill='white'
              style={{
                fontSize: theme.fonts.responsiveSmall,
                cursor: 'help',
                fontWeight: pnlIsHovered ? 'bold' : 'normal',
                textShadow: pnlIsHovered ? '2px 2px 4px rgb(52 52 52)' : 'none',
              }}
            >
              {result && result.PectoNippleLength ? result.PectoNippleLength.toFixed(0) : null}
            </text>

            <line
              x1='0'
              y1='50%'
              x2='100%'
              y2='50%'
              className={`${classes.buttonBase} ${classes.pnlBase} ${classes.neutral}`}
            />
          </svg>
        </Tooltip>

        {/* Pecto Level */}
        <Tooltip
          onOpen={() => qualityInfoMode && handlePectoLevelMouseEnter()}
          onClose={() => qualityInfoMode && handlePectoLevelMouseLeave()}
          title={
            <Typography
              style={{
                fontSize: '1.3vh',
                lineHeight: 1.3,
              }}
            >
              {pectoLevelClass(result?.PectLevel, t).text}
            </Typography>
          }
          arrow
          interactive
          placement={left ? 'bottom-start' : 'bottom-end'}
          TransitionComponent={Zoom}
        >
          <svg
            className={style.svg_pecto}
            style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
            id='svg_pecto'
            onClick={() => {
              if (result !== null && qualityInRevison) onPectLevelClick();
            }}
          >
            <line
              x1='30%'
              y1='0'
              x2='30%'
              y2='100%'
              className={
                qualityFeatInRevision
                  ? [classes.PectLevelBase, classes.neutral]
                  : `${classes.PectLevelBase}  ${
                      classes[pectoLevelClass(result?.PectLevel, t)?.classColor]
                    }`
              }
            />
          </svg>
        </Tooltip>

        {/* Convexity */}
        <Tooltip
          onOpen={() => qualityInfoMode && showPectOverlays()}
          onClose={() => qualityInfoMode && hidePectOverlays()}
          title={
            <Typography
              style={{
                fontSize: '1.3vh',
                lineHeight: 1.3,
              }}
            >
              {pectLabel}
            </Typography>
          }
          arrow
          interactive
          placement={left ? 'right-start' : 'left-start'}
          TransitionComponent={Zoom}
          id='svg_convexity'
        >
          <div
            className={style.pect_convexity}
            id='svg_convexity'
            style={{ cursor: qualityInRevison ? 'pointer' : 'help' }}
            onClick={() => {
              if (result !== null && qualityInRevison) onPectShapeClick();
            }}
          />
        </Tooltip>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'absolute',
          top: 0,
          right: left ? 0 : undefined,
          left: left ? null : 0,
        }}
      ></div>
    </div>
  );
};

export default QualityMLO;
