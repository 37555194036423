import React, { useState, useCallback, useEffect } from 'react';
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  Typography,
  Select,
  FormControl,
  MenuItem,
  useTheme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { fetchGet, fetchPost } from '../../../../helpers/fetching';
import { useTranslation } from 'react-i18next';
import Logo from '../../../../resources/coorporate_images/brayz_logo_small.png';
import { filter } from '@amcharts/amcharts4/.internal/core/utils/Iterator';

const useStyle = makeStyles(() => ({
  CheckboxContainer: {
    display: 'flex',
  },

  ReviewSelect: {
    width: '50%',
    marginTop: '5%'
  },
}));

const DataExport = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();

  // Store state
  const filter = useSelector(state => state.filter);
  const examinations = useSelector(state => state.examinations);

  // Local state
  const [images, setImages] = useState(false);
  const [overlays, setOverlays] = useState(false);
  const [results, setResults] = useState(true);
  const [users, setUsers] = useState([
    { username: 'bbox' },
    { username: 'confirmed' },
    { username: 'applied' },
  ]);
  const [selectedRevision, setSelectedRevision] = useState('applied');

  async function saveHandler() {
    if (results) {
      const response = await fetchPost('data/csv', {
        filter,
        revision_by: selectedRevision,
      });
  
      if (response.success) {
        saveData(response.data);
      } else {
        props.setSnackbar({
          msg: response.msg,
          severity: 'error',
        });
      }
    }

    if (images) {
      saveImages(filter);
    }

    if (overlays) {
      saveOverlays(filter);
    }

    props.onClose();
  }

  async function saveImages(filter) {
    const response = await fetchPost(
      'data/images', 
      {
        filter,
        selectedRevision,
      },
      'server', false, 'blob');

    if (response.status !== 200) {
      props.setSnackbar({
        msg: response.msg,
        severity: 'error',
      });
      return;
    }

    downloadFile(response.blob, 'bbox_images.zip');
  }

  async function saveOverlays(filter) {
    const response = await fetchPost(
      'data/overlays', 
      {
        filter,
        selectedRevision,
      },
      'server', false, 'blob');

    if (response.status !== 200) {
      props.setSnackbar({
        msg: response.msg,
        severity: 'error',
      });
      return;
    }

    downloadFile(response.blob, 'bbox_overlays.zip');
  }

  function saveData(data) {
    const text = data2Text(data.metaAndResults, data.headers);

    const a = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download = 'bbox_data.csv';
    a.click();
    URL.revokeObjectURL(a.href);
  }

  function data2Text(dataRows, headers) {
    let txt = headers.join(';') + ';\n';
    for (let row of dataRows) {
      for (let h of headers) {
        txt += (h in row ? row[h] : null) + ';';
      }
      txt += '\n';
    }
    
    return txt;
  }

  function downloadFile(file, path) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = path;
    a.click();
    URL.revokeObjectURL(a.href);
  }

  // Fetching
  const fetchUsers = useCallback(async () => {
    const response = await fetchGet('users');
    if (!response.success) return;
    const users = response.data
      .filter(user => user.annotation_permission)
      .concat({ username: 'bbox' }, { username: 'confirmed' }, { username: 'applied' });
    setUsers(users);
  }, []);

  // Effect
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <DraggableDialog
      maxWidth="md"
      title={t('export.exportTitle')}
      open={props.open}
      actions={[
        {
          onClick: saveHandler,
          color: 'primary',
          label: t('export.export'),
        },
        {
          onClick: props.onClose,
          color: 'secondary',
          label: t('export.cancel'),
        },
      ]}
      onClose={props.onClose}
      // style={{ zIndex: theme.zIndex.backdrops }}
    >
      <>
        <div className={classes.CheckboxContainer}>
          <FormControlLabel
            style={{ marginLeft: 'inherit' }}
            key={'Results'}
            label={<Typography>{t('export.exportResults')}</Typography>}
            control={
              <Checkbox
                checked={results}
                onChange={() => setResults(prev => !prev)}
              />
            }
          />

          <FormControlLabel
            style={{ marginLeft: 'inherit' }}
            key="Images"
            label={<Typography>{t('export.images')}</Typography>}
            control={
              <Checkbox
                checked={images}
                onChange={() => setImages((prev) => !prev)}
              />
            }
          />

          <FormControlLabel
            style={{ marginLeft: 'inherit' }}
            key="Overlays"
            label={<Typography>{t('export.overlays')}</Typography>}
            control={
              <Checkbox
                checked={overlays}
                onChange={() => setOverlays((prev) => !prev)}
                name="images"
              />
            }
          />
        </div>
        <FormControl className={classes.ReviewSelect}>
          <Select
            value={selectedRevision}
            onChange={(e) => setSelectedRevision(e.target.value)}
          >
            {users.map(user => (
              <MenuItem 
                key={user.username} 
                value={user.username}
              >
                {user.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    </DraggableDialog>
  );
};

export default DataExport;
