import {
  Chip,
  Divider,
  Fab,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import patch20Regular from '@iconify/icons-fluent/patch-20-regular';
import patch20Filled from '@iconify/icons-fluent/patch-20-filled';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { Icon } from '@iconify/react';
import FluentResizeLarge24Filled from '@iconify/icons-fluent/resize-large-24-filled';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// BREAST VOLUME
const chipColor = (value, theme) => {
  const colorMap = {
    correct: theme.palette.projections.volumeUp,
    moderate: theme.palette.projections.moderate,
    insufficient: theme.palette.projections.volumeDown,
    not_evaluated: theme.palette.projections.not_evaluated,
  };
  return colorMap[value] || theme.palette.projections.not_evaluated;
};

const currentBreastDiff = (
  view_position,
  currentBreastTissueDepicted,
  breastAreaDifference,
  t
) => {
  const absDifference = Math.abs(breastAreaDifference);
  const direction =
    breastAreaDifference < 0 ? t('ResultView.less') : t('ResultView.more');
  const viewPositionUpper = view_position?.toUpperCase();
  const firstLetter = view_position?.slice(0, 1).toUpperCase();
  const oppositeView = view_position.includes('mlo') ? 'CC' : 'MLO';

  return `- ${viewPositionUpper} ${t(
    'ResultView.current_breast_tissue_helper',
    {
      currentBreast: `${currentBreastTissueDepicted?.toFixed(0)}`,
    }
  )} ${t('ResultView.has')} ${absDifference}cm³ ${direction} ${t(
    'ResultView.than'
  )} ${firstLetter}${oppositeView}`;
};

export const breastVolumeChip = (
  currentBreastTissueDepicted,
  volumeProjectionDiff,
  breastAreaDifference,
  noPastBreastEvaluations,
  pastBreastVolume,
  volumeLastExamDiff,
  volumeTooltipOpen,
  setVolumeTooltipOpen,
  volumeChipClicked,
  setVolumeChipClicked,
  view_position,
  left,
  theme,
  classes,
  t,
  dispatch,
  actions
) => {
  const smallView = false;

  const renderTooltipTitle = (title, subTitle, color) => (
    <>
      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: '1.3vh',
          lineHeight: 1.3,
          margin: '4px 0px',
        }}
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1.3vh',
            lineHeight: 1.3,
            margin: '4px 0px',
            color,
          }}
        >
          {subTitle}
        </Typography>
      )}
    </>
  );

  const renderChip = (iconColor, handleClick) => (
    <Fab
      size="small"
      style={{
        background: volumeTooltipOpen ? iconColor : 'transparent',
        border: `1px solid ${iconColor}`,
        cursor: handleClick ? 'pointer' : 'help',
      }}
      onMouseEnter={() => setVolumeTooltipOpen(true)}
      onMouseLeave={() => {
        if (!volumeChipClicked) setVolumeTooltipOpen(false);
      }}
      onClick={handleClick}
      className={left ? classes.compression_left : classes.compression_right}
    >
      <Icon
        icon={FluentResizeLarge24Filled}
        color={volumeTooltipOpen ? 'black' : iconColor}
      />
    </Fab>
  );

  if (!currentBreastTissueDepicted) {
    return (
      <Tooltip
        placement="top-start"
        arrow
        title={renderTooltipTitle(
          `${t('ResultView.breast_tissue_abbreviation')}: ${t(
            'ResultView.not_available'
          )}`
        )}
      >
        {renderChip('gray', null)}
      </Tooltip>
    );
  }

  const handleChipClick = () => {
    setVolumeChipClicked(!volumeChipClicked);
    setVolumeTooltipOpen(!volumeChipClicked);
  };

  const volumeDiffColor = chipColor(volumeProjectionDiff, theme);
  const volumeLastDiffColor = chipColor(volumeLastExamDiff, theme);

  if (noPastBreastEvaluations) {
    return (
      <Tooltip
        placement="top-start"
        arrow
        title={
          <>
            {renderTooltipTitle(
              `${t(
                'ResultView.breast_tissue_abbreviation'
              )}: ${currentBreastTissueDepicted?.toFixed(0)}cm³`
            )}
            {volumeProjectionDiff
              ? volumeProjectionDiff !== 'not_evaluated' &&
                renderTooltipTitle(
                  `${t('ResultView.volume_variance')}: ${volumeProjectionDiff}`,
                  '',
                  volumeDiffColor
                )
              : ''}
            <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
              {volumeProjectionDiff === 'not_evaluated'
                ? t('ResultView.diff_not_available')
                : currentBreastDiff(
                    view_position,
                    currentBreastTissueDepicted,
                    breastAreaDifference,
                    t
                  )}
            </Typography>
          </>
        }
      >
        {renderChip(volumeDiffColor, null)}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      interactive
      open={volumeTooltipOpen}
      arrow
      placement="top-start"
      title={
        <>
          {renderTooltipTitle(
            `${t(
              'ResultView.breast_tissue_abbreviation'
            )}: ${currentBreastTissueDepicted?.toFixed(0)}cm³`
          )}
          {volumeProjectionDiff
            ? renderTooltipTitle(
                `${t('ResultView.volume_variance')}: ${volumeProjectionDiff}`,
                '',
                volumeDiffColor
              )
            : ''}
          <Typography
            style={{ fontSize: '1.3vh', marginBottom: '4px', lineHeight: 1.3 }}
          >
            {currentBreastDiff(
              view_position,
              currentBreastTissueDepicted,
              breastAreaDifference,
              t
            )}
          </Typography>
          <Divider />
          <Typography
            style={{
              fontWeight: 'bold',
              fontSize: '1.3vh',
              lineHeight: 1.3,
              margin: '4px 0px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() =>
              dispatch(actions.setCurrentExam(pastBreastVolume?.[0]?.exam_id))
            }
          >
            {t('ResultView.prev_exam')}
            <ArrowRightIcon
              fontSize="medium"
              style={{
                color: volumeLastDiffColor,
                cursor: 'pointer',
                marginLeft: '2px',
              }}
              onClick={() =>
                dispatch(actions.setCurrentExam(pastBreastVolume?.[0]?.exam_id))
              }
            />
          </Typography>
          {renderTooltipTitle(
            `${t('ResultView.volume_variance')}: ${volumeLastExamDiff}`,
            '',
            volumeLastDiffColor
          )}
          <div>
            <Typography style={{ fontSize: '1.3vh', lineHeight: 1.3 }}>
              {`- ${t(
                'ResultView.current_breast_tissue'
              )}: ${currentBreastTissueDepicted?.toFixed(0)} cm³`}
            </Typography>
            <Typography
              style={{
                fontSize: '1.3vh',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {`- ${t(
                'ResultView.past_breast_tissue'
              )}: ${pastBreastVolume?.[0]?.BreastVolume?.toFixed(
                1
              )} cm³ (${pastBreastVolume?.[0]?.date.slice(0, 10)})`}
            </Typography>
          </div>
        </>
      }
    >
      {renderChip(volumeDiffColor, handleChipClick)}
    </Tooltip>
  );
};

/* POSTOP Clips */
export const postOpChip = (
  postopClips,
  showClips,
  qualityOverlaysToShow,
  proj_name,
  left,
  classes,
  theme,
  t
) => {
  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={
        <Typography
          style={{
            letterSpacing: '0.1rem',
            textTransform: 'uppercase',
            fontSize: theme.fonts.responsiveMedium,
            fontWeight: 'bold',
          }}
          color="inherit"
        >
          {postopClips
            ? t('ResultView.postop_clips')
            : t('ResultView.no_clips')}
        </Typography>
      }
      arrow
      interactive
      placement="right-end"
    >
      <Fab
        size="small"
        onMouseEnter={() => {
          postopClips && showClips(proj_name);
        }}
        onMouseLeave={() => {
          postopClips && showClips(proj_name);
        }}
        style={{
          background: 'transparent',
          border: `1px solid ${
            postopClips ? theme.palette.lesions.clips : '#3e3e3e'
          }`,
        }}
        className={
          left ? classes.clipsPatchIconLeft : classes.clipsPatchIconRight
        }
      >
        <Icon
          icon={
            postopClips
              ? qualityOverlaysToShow['clips' + proj_name.toUpperCase()]
                ? patch20Filled
                : patch20Regular
              : patch20Regular
          }
          style={{
            width: 30,
            height: 30,
            color: postopClips ? 'inherit' : '#3e3e3e',
          }}
        />
      </Fab>
    </Tooltip>
  );
};

/* POSTOP Reduction Scars */
export const reductionScarsChip = (
  evaluation,
  left,
  classes,
  postopHandler,
  theme,
  t
) => {
  return (
    <Tooltip
      title={
        <>
          <Typography
            style={{
              letterSpacing: '0.1rem',
              textTransform: 'uppercase',
              fontSize: theme.fonts.responsiveMedium,
              fontWeight: 'bold',
            }}
          >
            {evaluation?.PostSurgery?.includes('reductionScars')
              ? t('ResultView.reductionScars')
              : t('ResultView.no_reductionScars')}
          </Typography>
          <Divider style={{ margin: '3%' }} />
          <Typography
            style={{
              letterSpacing: '0.1rem',
              fontSize: theme.fonts.responsiveMedium,
              display: 'flex',
              alignItems: 'center',
            }}
            color="inherit"
          >
            <TouchAppIcon fontSize="small" />
            {evaluation?.PostSurgery?.includes('reductionScars')
              ? t('ResultView.remove_operated', {
                  postop: t('projections.reductionScars'),
                })
              : t('ResultView.set_operated', {
                  postop: t('projections.reductionScars'),
                })}
          </Typography>
        </>
      }
      arrow
      placement="right-end"
      TransitionComponent={Zoom}
    >
      <Fab
        size="small"
        style={{
          background: 'transparent',
          border: `1px solid ${
            evaluation?.PostSurgery?.includes('reductionScars')
              ? '#a48023'
              : '#3e3e3e'
          }`,
        }}
        className={left ? classes.postOpLeft : classes.postOpRight}
        onClick={() => postopHandler(evaluation?.PostSurgery)}
      >
        {evaluation?.PostSurgery?.includes('reductionScars') ? (
          <LocalHospitalIcon
            fontSize="small"
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
              color: '#a48023',
            }}
          />
        ) : (
          <LocalHospitalIcon
            fontSize="small"
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
              color: '#3e3e3e',
            }}
          />
        )}
      </Fab>
    </Tooltip>
  );
};
