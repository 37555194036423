import React, { useState, useEffect, useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { fetchGet, fetchPost } from '../../../../helpers/fetching';
import { jsPDF } from 'jspdf';
import {
  setActionToConfirm,
  setSnackbar,
  setActiveDialog,
  sendReports,
  sendSR,
} from '../../../../store';
import { useTranslation } from 'react-i18next';
import {
  DIAGNOSTICS_MICROCALC,
  DIAGNOSTICS_OPACITIES,
} from '../../../../config';
import animation from '../../../../resources/processing_animation.gif';
import { Button, Divider, Fab, Tooltip, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const Report = (props) => {
  const { t } = useTranslation();
  const [qualitySrc, setQualitySrc] = useState(null);
  const [diagnosticsSrc, setDiagnosticsSrc] = useState(null);
  const [pending, setPending] = useState(null);
  const {
    confirmed_density,
    confirmed_quality,
    confirmed_opacities,
    confirmed_microcalc,
    pat_name,
    applied_density,
    applied_quality,
    applied_opacities,
    applied_microcalc,
  } = useSelector((state) => state.results);
  const currentExam = useSelector((state) => state.examinations.currentExam);
  const somePremiumDiagnostics =
    (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) &&
    JSON.parse(localStorage.getItem('diagnostics'));
  const dispatch = useDispatch();

  const fetchReports = useCallback(async () => {
    setPending(t('report.generating'));

    const responseQuality = await fetchGet(
      `report_quality/${currentExam}`,
      'bbox',
      false,
      'blob'
    );

    let responseDiagnostics = null;
    if (somePremiumDiagnostics) {
      responseDiagnostics = await fetchGet(
        `report_diagnostics/${currentExam}`,
        'bbox',
        false,
        'blob'
      );
    }
    if (
      responseQuality.status === 200 &&
      (!somePremiumDiagnostics ||
        (responseDiagnostics && responseDiagnostics.status === 200))
    ) {
      const objectURLQuality = URL.createObjectURL(responseQuality.blob);
      setQualitySrc(objectURLQuality);

      if (responseDiagnostics) {
        const objectURLDiagnostics = URL.createObjectURL(
          responseDiagnostics.blob
        );
        setDiagnosticsSrc(objectURLDiagnostics);
      }
      setPending(null);
    } else {
      setQualitySrc('nothing');
      dispatch(
        setSnackbar({
          msg: `${t('report.failed_code')} ${responseQuality.status} ${
            responseDiagnostics ? responseDiagnostics.status : ''
          }`,
          severity: 'error',
        })
      );
      setPending(null);
    }
  }, [currentExam, dispatch]);

  const generatePDF = (imageSrc, fileName) => {
    const pdf = new jsPDF();
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = image.width;
      const imgHeight = image.height;

      const ratio = imgHeight / imgWidth;
      const newHeight = pageHeight;
      const newWidth = newHeight / ratio;

      const xOffset = (pageWidth - newWidth) / 2;

      pdf.addImage(image, 'JPEG', xOffset, 0, newWidth, newHeight);
      pdf.save(fileName);
    };
  };

  const onSRSend = () => {
    if (pending) return;

    if (
      ![confirmed_density, confirmed_quality, confirmed_opacities].every(
        (item) => item
      )
    )
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_structure_pacs'));
            dispatch(sendSR(currentExam, t));
          },
          text: t('report.not_confirmed'),
        })
      );
    else {
      setPending(t('report.send_structure_pacs'));
      dispatch(sendSR(currentExam));
      setPending(null);
    }
  };

  const onQualityReportSend = () => {
    if (pending) return;
    if (![confirmed_density, confirmed_quality].every((item) => item))
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_img'));
            dispatch(sendReports('quality', currentExam, t));
          },
          text: t('report.not_confirmed_send'),
        })
      );
    else {
      setPending(t('report.send_img'));
      dispatch(sendReports('quality', currentExam, t));
      setPending(null);
    }
  };

  const onDiagnoseReportSend = () => {
    if (pending) return;
    if (
      ![confirmed_density, confirmed_opacities, confirmed_microcalc].every(
        (item) => item
      )
    )
      dispatch(
        setActionToConfirm({
          action: () => {
            setPending(t('report.send_img'));
            dispatch(sendReports('diagnostics', currentExam, t));
            setPending(null);
          },
          text: t('report.not_confirmed_send'),
        })
      );
    else {
      setPending(t('report.send_img'));
      dispatch(sendReports('diagnostics', currentExam, t));
      setPending(null);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <DraggableDialog
      maxWidth="xl"
      title={t('ResultView.report_title', { user: pat_name })}
      open={props.open}
      actions={[
        somePremiumDiagnostics && {
          onClick: onDiagnoseReportSend,
          color: 'primary',
          label: t('report.send_diagnose'),
        },
        {
          onClick: onQualityReportSend,
          color: 'primary',
          label: t('report.send_quality'),
        },
        {
          onClick: onSRSend,
          color: 'primary',
          label: t('report.send_sr'),
        },
      ]}
      onClose={() => dispatch(setActiveDialog(null))}
    >
      {pending ? (
        <>
          <img
            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            src={animation}
            alt="logoanimation"
          />
          <p>{pending}</p>
        </>
      ) : (
        <>
          {/* Quality Report */}
          <img src={qualitySrc} alt={t('report.quality_unavailable')} />
          <div
            style={{
              width: '90%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<GetAppIcon />}
              style={{ background: '#a91e14', margin: '10px' }}
              onClick={() =>
                generatePDF(qualitySrc, `b-QUALITY_Report_${pat_name}.pdf`)
              }
            >
              {t('report.download_quality')}
            </Button>
          </div>

          <Divider
            light
            orientation="horizontal"
            variant="fullWidth"
            style={{ margin: '10px' }}
          />
          {/* Diagnose Report */}
          {somePremiumDiagnostics && (
            <>
              <img
                src={diagnosticsSrc}
                alt={t('report.diagnostics_unavailable')}
              />
              <div
                style={{
                  width: '90%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<GetAppIcon />}
                  style={{ background: '#a91e14', margin: '10px' }}
                  onClick={() =>
                    generatePDF(
                      diagnosticsSrc,
                      `b-DIAGNOSTICS_Report_${pat_name}.pdf`
                    )
                  }
                >
                  {t('report.download_diagnostics')}
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </DraggableDialog>
  );
};

export default Report;
