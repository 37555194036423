import {
  handleMatchedLesion,
  removeLesionAndUpdateIndices,
} from './DiagnosticRevisionTableHelpers';

export const severityColor = (severity, theme) => {
  if (severity) {
    if (severity === 'benign') return theme.palette.lesions.microcalcBenign;
    else if (severity === 'malignant') return theme.palette.lesions.microcalcMalignant;
    else if (severity === 'birads2') return theme.palette.lesions.birads2;
    else if (severity === 'birads3') return theme.palette.lesions.birads3;
    else if (severity === 'birads4') return theme.palette.lesions.birads4;
    else if (severity === 'birads5') return theme.palette.lesions.birads5;
    else if (severity === 'lesionKnown') return theme.palette.lesions.lesionKnown;
    else if (severity === 'vessel') return theme.palette.lesions.lesionKnown;
  } else return 'cyan';
};

export const getMatchedProj = (breast) => {
  if (breast === 'rcc') return 'rmlo';
  if (breast === 'rmlo') return 'rcc';
  if (breast === 'lcc') return 'lmlo';
  if (breast === 'lmlo') return 'lcc';
};

export const deleteFinding = (lesionType, row, moduleResults, dispatch, actions, e) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }

  const [lesionProjection, lesionBirads, lesionIndex] = row;

  const handleOpacities = () => {
    const matchedLesion = moduleResults?.[lesionProjection]?.[lesionBirads]?.[lesionIndex]?.match;
    const matchedBreast = getMatchedProj(lesionProjection);

    let newState = JSON.parse(JSON.stringify(moduleResults));

    if (matchedLesion) {
      const [classType, locationIndex] = matchedLesion;
      let matchedPrevClass = newState[matchedBreast]?.[classType]?.[locationIndex];

      handleMatchedLesion(newState, matchedLesion, matchedBreast, lesionProjection);
      removeLesionAndUpdateIndices(newState, lesionProjection, lesionBirads, lesionIndex);
    } else {
      const otherProjLesions = newState?.[matchedBreast];

      removeLesionAndUpdateIndices(newState, lesionProjection, lesionBirads, lesionIndex);
    }

    dispatch(actions.setCustomOpacities(newState));
    dispatch(actions.setOpacitiesLocalChanges(true));
  };

  const handleMicrocalc = () => {
    const filterArray = (array, index) => array.filter((_, idx) => idx !== index);
    const updateState = (state, lesionProjection, lesionBirads, newArray) => ({
      ...state,
      [lesionProjection]: {
        ...state[lesionProjection],
        [lesionBirads]: newArray,
      },
    });

    const filteredArray = filterArray(moduleResults[lesionProjection][lesionBirads], lesionIndex);
    const newArray = filteredArray.length === 0 ? null : filteredArray;
    const newState = updateState(moduleResults, lesionProjection, lesionBirads, newArray);

    dispatch(actions.setCustomMicrocalc(newState));
    dispatch(actions.setMicrocalcLocalChanges(true));
  };

  if (lesionType === 'opacities') {
    handleOpacities();
  } else if (lesionType === 'microcalc') {
    handleMicrocalc();
  }

  dispatch(actions.setZoomedProjection(null));
  dispatch(actions.setSingleBoxHover(null));
};

export const saveResults = (moduleToSave, dispatch, actions, t) => {
  if (moduleToSave === 'opacities') {
    dispatch(actions.saveEvaluation('opacities', t));
    dispatch(actions.setOpacitiesLocalChanges(false));
  } else if (moduleToSave === 'microcalc') {
    dispatch(actions.saveEvaluation('microcalc', t));
    dispatch(actions.setMicrocalcLocalChanges(false));
  }
  dispatch(actions.setTabsIndex(0));
  dispatch(actions.setDiagnosticsInRevisionMode(false));
  dispatch(actions.setInitialImages());
};

export const discardCurrentChanges = (moduleToSave, moduleEval, dispatch, actions) => {
  if (moduleToSave === 'opacities') {
    dispatch(actions.setOpacitiesLocalChanges(false));
    dispatch(actions.setCustomOpacities(moduleEval));
  } else if (moduleToSave === 'microcalc') {
    dispatch(actions.setMicrocalcLocalChanges(false));
    dispatch(actions.setCustomMicrocalc(moduleEval));
  }
};

export const onConfirmClick = (moduleToConfirm, moduleShown, moduleState, dispatch, actions, t) => {
  if (moduleToConfirm === 'opacities') {
    dispatch(actions.confirmEvaluation(moduleShown, 'opacities', !moduleState, t));
  } else if (moduleToConfirm === 'microcalc') {
    dispatch(actions.confirmEvaluation(moduleShown, 'microcalc', !moduleState, t));
  }
};
