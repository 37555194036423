import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../store/index';
import { Icon } from '@iconify/react';
import {
  Button,
  useTheme,
  Typography,
  Tooltip,
  Fab,
  LinearProgress,
  makeStyles,
  ClickAwayListener,
  Badge,
  Popper,
  Paper,
  Divider,
  SvgIcon,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckIcon from '@material-ui/icons/Check';
import { useSelector, useDispatch } from 'react-redux';
import ReviewSelect from './ReviewSelect/ReviewSelect';
import patient20Regular from '@iconify/icons-fluent/patient-20-regular';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import BarChartIcon from '@material-ui/icons/BarChart';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as ReviewerIcon } from '../../../../resources/icons/reviewers.svg';
import { getCurrentModuleStatus } from '../Summary/SummaryCommon/SummaryCommon';
import { confirmEvaluation } from '../../../../store/slices/labeling';
import { ExitToApp } from '@material-ui/icons';
import { PREMIUM_DATA_ANNOTATION } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  button_group_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2px 4px',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    fontSize: theme.fonts.responsiveMedium,
  },

  summary_fabs: {
    margin: '4px',
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    backgroundColor: theme.palette.complementary.turquoise,

    '&:hover': {
      background: `${theme.palette.complementary.turquoiseMedium} !important`,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  report_button: {
    margin: '4px',
    height: '26px',
    minHeight: '26px',
    backgroundColor: theme.palette.complementary.turquoise,
    color: 'white',
    '&:hover': {
      background: `${theme.palette.complementary.turquoiseMedium} !important`,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  summary_edit_fabs: {
    margin: '4px',
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    backgroundColor: theme.palette.complementary.turquoise,

    '&:hover': {
      background: `${theme.palette.primary.main} !important`,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  summary_red_fabs: {
    margin: '4px',
    height: '26px',
    width: '26px',
    minHeight: '26px',
    minWidth: '26px',
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },
}));

const SummaryCardActions = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();

  // Store state
  const { applied_quality, confirmed_quality, shown_quality } = useSelector(
    (state) => state.results
  );
  const { savingPending } = useSelector((state) => state.labeling);
  const tabsIndex = useSelector((state) => state.gui.tabsIndex);

  const isConfirmed = applied_quality === 'bbox' && confirmed_quality;
  const isRelabeled = confirmed_quality && applied_quality !== 'bbox';
  const shownQuality = shown_quality || applied_quality;
  const annotatorRole = PREMIUM_DATA_ANNOTATION && JSON.parse(localStorage.getItem('annotation'));

  // Local state

  const handleRevisionMode = () => {
    dispatch(actions.setTabsIndex(2));
    dispatch(actions.setQualityInRevisionMode(true));
    dispatch(actions.setShownQualityOverlayType(''));
    dispatch(actions.setQualityInDrawingMode(false));
  };

  const exitRevisionMode = () => {
    dispatch(actions.setTabsIndex(0));
    dispatch(actions.hideAllLesions());
    dispatch(actions.setQualityInDrawingMode(false));
  };

  const onConfirmClick = () => {
    const state = shown_quality === applied_quality && confirmed_quality;
    dispatch(confirmEvaluation(shownQuality, 'quality', !state, t));
  };

  return (
    <>
      {savingPending ? (
        <div className={classes.button_group_container}>
          <LinearProgress
            style={{
              width: '98%',
            }}
          />
        </div>
      ) : (
        <div className={classes.button_group_container}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}
          >
            {/* Report button*/}
            {/* {(tabsIndex === 0 || tabsIndex === 1) && (
              <Tooltip
                arrow
                placement='top'
                title={
                  <Typography className={classes.tooltip_text} color='inherit'>
                    {t('ResultView.tooltip_report')}
                  </Typography>
                }
              >
                <Fab
                  className={classes.summary_fabs}
                  onClick={() => dispatch(actions.setActiveDialog('report'))}
                >
                  <DescriptionIcon
                    fontSize='medium'
                    style={{ width: '20px', height: '20px', color: 'white' }}
                  />
                </Fab>
              </Tooltip>
            )} */}

            <Button
              component='label'
              variant='contained'
              size='small'
              className={classes.report_button}
              onClick={() => dispatch(actions.setActiveDialog('report'))}
              startIcon={
                <DescriptionIcon
                  fontSize='medium'
                  style={{ width: '20px', height: '20px', color: 'white' }}
                />
              }
            >
              {t('ResultView.report')}
            </Button>

            {/* {tabsIndex === 1 && (
              <Tooltip
                title={
                  <Typography className={classes.tooltipText}>
                    {t('ResultView.summary_back')}
                  </Typography>
                }
                arrow
                placement='top-start'
              >
                <Fab
                  size='small'
                  onClick={() => exitRevisionMode()}
                  color='primary'
                  className={classes.summary_red_fabs}
                >
                  <ExitToApp
                    fontSize='medium'
                    style={{ width: '22px', height: '22px', transform: 'scaleX(-1)' }}
                  />
                </Fab>
              </Tooltip>
            )} */}
          </div>

          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* Labeling statistics Fab */}
            {tabsIndex === 0 && (
              <Tooltip
                placement='top-end'
                arrow
                title={
                  <Typography className={classes.tooltip_text} color='inherit'>
                    {t('ResultView.labelingStatistics')}
                  </Typography>
                }
              >
                <span>
                  <Fab
                    size='small'
                    className={classes.summary_fabs}
                    onClick={() => dispatch(actions.setActiveDialog('LabelStat'))}
                    color='primary'
                    style={{ marginLeft: '12px' }}
                  >
                    <BarChartIcon fontSize='medium' style={{ width: '25px', height: '25px' }} />
                  </Fab>
                </span>
              </Tooltip>
            )}

            {/* Enter revision mode  */}
            {tabsIndex === 1 && annotatorRole && (
              <Tooltip
                arrow
                placement='left-end'
                title={
                  <>
                    <Typography className={classes.tooltip_text} color='inherit'>
                      {tabsIndex === 1
                        ? t('ResultView.tooltip_revise_quality')
                        : t('ResultView.tooltip_revise_diagnostics')}
                    </Typography>
                  </>
                }
              >
                <Fab
                  size='small'
                  onClick={() => handleRevisionMode()}
                  color={!!confirmed_quality ? 'primary' : 'default'}
                  className={classes.summary_edit_fabs}
                >
                  <EditIcon
                    fontSize='medium'
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'white',
                    }}
                  />
                </Fab>
              </Tooltip>
            )}

            {/* Confirm or dicard quality */}
            {tabsIndex === 1 && annotatorRole && (
              <>
                <Tooltip
                  arrow
                  placement='top'
                  title={
                    <>
                      <Typography className={classes.tooltipText} style={{ fontWeight: 'bold' }}>
                        {
                          getCurrentModuleStatus(
                            applied_quality,
                            shownQuality,
                            isConfirmed,
                            isRelabeled,
                            t('ResultView.quality'),
                            theme,
                            t
                          ).currentApplied
                        }
                      </Typography>
                      <Divider style={{ margin: '3% 0' }} />
                      <Typography className={classes.tooltipText}>
                        {
                          getCurrentModuleStatus(
                            applied_quality,
                            shownQuality,
                            isConfirmed,
                            isRelabeled,
                            t('ResultView.quality'),
                            theme,
                            t
                          ).tooltipAction
                        }
                      </Typography>
                    </>
                  }
                >
                  <Fab
                    color='primary'
                    style={{
                      color: '#fff',
                      background: `${
                        getCurrentModuleStatus(
                          applied_quality,
                          shownQuality,
                          isConfirmed,
                          isRelabeled,
                          t('ResultView.quality'),
                          theme,
                          t
                        ).color
                      }`,
                      margin: '4px',
                      textWrap: 'nowrap',
                    }}
                    className={classes.summary_fabs}
                    onClick={() => {
                      onConfirmClick();
                    }}
                  >
                    {
                      getCurrentModuleStatus(
                        applied_quality,
                        shownQuality,
                        isConfirmed,
                        isRelabeled,
                        t('ResultView.quality'),
                        theme,
                        t
                      ).fabIcon
                    }
                  </Fab>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryCardActions;
