import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import { fetchGet } from '../../helpers/fetching';


const useStyles = makeStyles((theme) => ({
    status: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '3em',
      marginTop: '30%'
    },
  }));

const LoginWithToken = () => {
    const history = useHistory();
    const { token } = useParams();
    const classes = useStyles();

    const [status, setStatus] = useState('Authenticating...')

    async function authenticateUser(token) {
        const response = await fetchGet(`login/${token}`);
        if (response.success) {
          localStorage.setItem('username', response.username);
          localStorage.setItem('role', response.role);
          llocalStorage.setItem('annotation', response.annotation);
          localStorage.setItem('diagnostics', response.diagnostics);
          localStorage.setItem('aliases', response.aliases);
          localStorage.setItem('last_login', response.last_login);
          setStatus('You are authorized')
        } else {
            setStatus('Token not recognized. You shall no pass!')
        }
    }

    useEffect(() => {
        if (token) {
            authenticateUser(token);
        } else {
            console.error("No token provided in URL");
            history.push("/login");
        }
    }, [token]);

  return (
    <div className={classes.status}>
        {status}
    </div>
  );
};

export default LoginWithToken;
