import { Avatar, Typography } from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import { Error } from '@material-ui/icons';

export const patientRelativeRisk = (risk_evaluation, t, classes) => {
  if (risk_evaluation?.life_time_risk >= 30) {
    return (
      <div className={classes.life_risk_container}>
        <Error fontSize='medium' color='primary' />

        <Typography
          className={[classes.patient_relative_risk, classes.patient_risk_high].join(' ')}
        >
          {t('risk_management.high_average_summary')}
        </Typography>
      </div>
    );
  }
  if (risk_evaluation?.life_time_risk >= 17 && risk_evaluation?.life_time_risk <= 30) {
    return (
      <div className={classes.life_risk_container}>
        <Avatar className={[classes.risk_avatar, classes.patient_risk_moderate_icon].join(' ')}>
          <TimelineIcon className={classes.risk_icon} fontSize='inherit' />
        </Avatar>
        <Typography
          className={[classes.patient_relative_risk, classes.patient_risk_moderate].join(' ')}
        >
          {t('risk_management.sligh_high_average_summary')}
        </Typography>
      </div>
    );
  }
  if (risk_evaluation?.life_time_risk < 17) {
    return (
      <div className={classes.life_risk_container}>
        <Avatar className={[classes.risk_avatar, classes.patient_risk_low_icon].join(' ')}>
          <BubbleChartIcon className={classes.risk_icon} fontSize='inherit' />
        </Avatar>
        <Typography className={[classes.patient_relative_risk, classes.patient_risk_low].join(' ')}>
          {t('risk_management.normal_average_summary')}
        </Typography>
      </div>
    );
  }

  return '';
};
