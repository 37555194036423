import React, { useState, useEffect, useCallback } from 'react';
import PieChart from './charts/pieChart';
import MultiLineChartTwo from './charts/multiLineChartTwo';
import QualityFeaturesChart from './charts/QualityFeaturesChart';
import BarChartTwo from './charts/barChartTwo';
import Histogram from './charts/histogram';
import { Tooltip, Zoom, IconButton, Chip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ButtonGroupCompression from './ButtonGroup/ButtonGroupCompression';
import ButtonGroupProjections from './ButtonGroup/ButtonGroupProjections';
import ButtonGroupQualityFeats from './ButtonGroup/ButtonGroupPGMI';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../store/index';
import { formatDate, daysInMonth } from '../../../helpers/formatDate';
import { Helmet } from 'react-helmet';
import MultilineChart from './charts/multilineChart';
import ButtonGroupDiagnostics from './ButtonGroup/ButtonGroupDiagnostics';
import ButtonGroupQuality from './ButtonGroup/ButtonGroupQuality';
import ChartCard from './ChartCard/ChartCard';
import {
  DIAGNOSTICS_MICROCALC,
  DIAGNOSTICS_OPACITIES,
  PREMIUM_DENSITY,
  OVERALL_QUALITY,
  COMPRESSION_LOW,
  COMPRESSION_HIGH,
  COMPRESSION_TOLERANCE,
} from '../../../config';

const useStyles = makeStyles((theme) => ({
  dashboard_general: {
    height: '100%',
    width: '100%',
    display: 'grid',
    gridRowGap: '2%',
    gridColumnGap: '0.5%',
  },

  dashboard_responsive: {
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    overflowY: 'auto',
  },

  dashboard_no_premium: {
    // gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(3, 1fr)',
  },

  // Normal cards
  small_cards: {
    gridColumn: 'span 2',
    gridRow: '1',
  },
  medium_cards: {
    gridColumn: 'span 5',
    gridRow: '2',
  },
  big_cards: {
    gridColumn: 'span 3',
    gridRow: '3',
  },

  large_big_cards: {
    gridColumn: 'span 7',
    gridRow: '3',
  },

  // Responsive card classes
  small_cards_responsive: {
    gridRow: '1 span',
    gridColumn: 'span 2',
  },

  small_cards_responsive_two: {
    gridRow: '2',
    gridColumn: 'span 3',
  },

  medium_cards_responsive: {
    gridRow: '3',
    gridColumn: 'span 3',
  },

  big_cards_responsive: {
    gridRow: '4',
    gridColumn: '1 span',
  },

  large_big_cards_responsive: {
    gridRow: '4',
    gridColumn: 'span 4',
  },

  // No premium cards
  small_cards_one: {
    gridColumn: 'span 2',
    gridRow: '1',
  },
  small_cards_two: {
    gridColumn: 'span 3',
    gridRow: '1',
  },
  small_cards_three: { gridColumn: '3 span', gridRow: '1' },

  // Some premium card
  small_cards_one_premium: {
    gridColumn: 'span 1',
    gridRow: '1',
  },

  small_cards_two_premium: {
    gridColumn: 'span 2',
    gridRow: '1',
  },

  small_cards_three_premium: {
    gridColumn: 'span 3',
    gridRow: '1',
  },

  small_cards_four_premium: {
    gridColumn: 'span 2',
    gridRow: '1',
  },

  medium_cards_some_premium: {
    gridColumn: 'span 4',
    gridRow: '2',
    bordr: '1px dashed yellow',
  },

  big_cards_some_premium: {
    gridColumn: 'span 4',
    gridRow: '3',
  },

  medium_cards_no_premium: {
    gridColumn: 'span 4',
    gridRow: '2',
  },

  big_cards_no_premium: {
    gridColumn: 'span 4',
    gridRow: '3',
  },

  icon: {
    fontSize: '3vh',
  },

  CardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    height: '80%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  gridRowOneRanking: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },

  InfoIcon: {
    padding: 0,
    color: 'gray',
    fontSize: '1rem',
  },

  featuresFilter: {
    letterSpacing: '0.1rem',
    maxHeight: '20px',
    textTransform: 'capitalize',
    fontSize: '0.7em',
    display: 'flex',
    margin: '0 5%',
    padding: '4px',
    '& p': {
      color: theme.palette.secondary.main,
      margin: 0,
    },
  },

  title_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Store State
  const totalExaminations = useSelector((state) => state.examinations.totalExaminations);
  const gui = useSelector((state) => state.gui);
  const dates = useSelector((state) => state.dates);
  const filter = useSelector((state) => state.filter);
  const averageDose = useSelector((state) => state.statistics.doseData.aver);
  const averageQualityPerformance = useSelector(
    (state) => state.statistics.performanceHistogramData.aver
  );
  const barQualityData = useSelector((state) => state.statistics.barQualityData);
  const examinationListDrawer = useSelector((state) => state.gui.examinationListDrawer);

  // Loading states
  const loadingExams = useSelector((state) => state.statistics.loadingExams);
  const loadingDensity = useSelector((state) => state.statistics.loadingDensity);
  const loadingPerformance = useSelector((state) => state.statistics.loadingPerformance);
  const loadingCompression = useSelector((state) => state.statistics.loadingCompression);
  const loadingDose = useSelector((state) => state.statistics.loadingDose);
  const loadingFeatures = useSelector((state) => state.statistics.loadingFeatures);
  const loadingQuality = useSelector((state) => state.statistics.loadingQuality);
  const loadingOpacities = useSelector((state) => state.statistics.loadingOpacities);
  const loadingMicrocalc = useSelector((state) => state.statistics.loadingMicrocalc);

  // Local state
  const [activeProjection, setActiveProjection] = useState('rcc');
  const [cmpProjections, setCmpProjections] = useState([0, 1, 2, 3]);
  const [diagnosticCategory, setDiagnosticCategory] = useState('');
  const [qualityData, setQualityData] = useState('projections');
  const [performanceProjections, setPerformanceProjections] = useState([
    'rcc',
    'lcc',
    'rmlo',
    'lmlo',
  ]);

  //Loged user role & premium modules
  const somePremiumDiagnostics =
    (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) && JSON.parse(localStorage.getItem('diagnostics'));
  const premiumDensity = PREMIUM_DENSITY;

  const allPremiumShown = somePremiumDiagnostics && premiumDensity;
  const somePremiumCardsShown =
    (somePremiumDiagnostics && !PREMIUM_DENSITY) || (!somePremiumDiagnostics && PREMIUM_DENSITY);
  const noPremiumCardsShown =
    (somePremiumDiagnostics && !PREMIUM_DENSITY) ||
    (!somePremiumDiagnostics && PREMIUM_DENSITY) ||
    !somePremiumDiagnostics ||
    !PREMIUM_DENSITY;

  const dashboardClass = () => {
    if (!gui.landscape) return classes.dashboard_responsive;
    if (noPremiumCardsShown) return classes.dashboard_no_premium;
    return;
  };

  const getDynamicCardClass = (index, totalCards) => {
    if (totalCards === 3) {
      if (index === 0) return classes.small_cards_one;
      if (index === 1) return classes.small_cards_two;
      if (index === 2) return classes.small_cards_three;
    } else if (totalCards === 4) {
      if (index === 0) return classes.small_cards_one_premium;
      if (index === 1) return classes.small_cards_two_premium;
      if (index === 2) return classes.small_cards_three_premium;
      if (index === 3) return classes.small_cards_four_premium;
    }
    return {};
  };

  const smallCardsClasses = (chartLocation) => {
    if (!gui.landscape) {
      if (chartLocation >= 3) return classes.small_cards_responsive_two;
      return classes.small_cards_responsive;
    }
    if (noPremiumCardsShown || somePremiumCardsShown) {
      let totalCards = somePremiumCardsShown ? 4 : 3;
      return getDynamicCardClass(chartLocation, totalCards);
    }
    return classes.small_cards;
  };

  const mediumCardClass = () => {
    if (!gui.landscape) return classes.medium_cards_responsive;
    if (somePremiumCardsShown) return classes.medium_cards_some_premium;
    if (noPremiumCardsShown) return classes.medium_cards_no_premium;
    return classes.medium_cards;
  };

  const mediumBigCardClass = (pointChart) => {
    if (!gui.landscape)
      return pointChart ? classes.big_cards_responsive : classes.large_big_cards_responsive;

    if (somePremiumCardsShown) return classes.big_cards_some_premium;
    if (noPremiumCardsShown) return classes.big_cards_no_premium;
    return pointChart ? classes.big_cards : classes.large_big_cards;
  };

  // Handlers
  function set_active_proj_compression(n, btn_id) {
    const button = document.getElementById(btn_id);

    const cmp_projections = cmpProjections.filter((value) => value !== n);

    if (button.className.split(' ').some((c) => /MuiButton-contained.*/.test(c))) {
    } else {
      cmp_projections.push(n);
    }

    setCmpProjections(cmp_projections);
  }

  function activePerformanceProj(proj) {
    setPerformanceProjections((performanceProjections) => {
      if (performanceProjections.includes(proj)) {
        return performanceProjections.filter((value) => value !== proj);
      } else {
        return [...performanceProjections, proj];
      }
    });
  }

  function featuresQualityHandler(label, feature, value) {
    const featuresQuality = {
      label,
      feature,
      value,
      projection: activeProjection.toUpperCase(),
    };
    dispatch(actions.setFilterProperty(['featuresQuality', featuresQuality]));
  }

  const filterChartHandler = useCallback(
    (feature, chart) => {
      if (chart === 'pieDensityData') {
        dispatch(actions.setFilterProperty(['density', feature]));
      } else if (chart === 'pieQualityData') {
        dispatch(actions.setFilterProperty(['quality', feature]));
      } else if (chart === 'pieStudyQualityData') {
        dispatch(actions.setFilterProperty(['study_quality', feature]));
      } else if (chart === 'microcalc') {
        dispatch(actions.setFilterProperty(['microcalc', feature]));
      } else if (chart === 'opacities') {
        dispatch(actions.setFilterProperty(['opacities', feature]));
      }
    },
    [filter, dispatch]
  );

  const onPerformanceBulletClick = (data) => {
    if (data.uid !== undefined) dispatch(actions.showExamination(data.uid));
    else if (data.date !== undefined) {
      let from = formatDate(data.date).trim();
      let to = from;
      if (from.length < 8) {
        from = from + '-01';
        to = to + '-' + daysInMonth(to.slice(5, 7), to.slice(0, 4));
      }
      dispatch(actions.setPerformanceDates([from, to]));
    }
  };

  const onCompressionBulletClick = (data) => {
    if (data.uid !== undefined) dispatch(actions.showExamination(data.uid));
    else if (data.date !== undefined) {
      let from = formatDate(data.date).trim();
      let to = from;
      if (from.length < 8) {
        from = from + '-01';
        to = to + '-' + daysInMonth(to.slice(5, 7), to.slice(0, 4));
      }
      dispatch(actions.setCompressionDates([from, to]));
    }
  };

  useEffect(() => {
    if (DIAGNOSTICS_OPACITIES) setDiagnosticCategory('opacities');
    else if (DIAGNOSTICS_MICROCALC) setDiagnosticCategory('microcalc');
  }, []);

  useEffect(() => {
    dispatch(actions.setCurrentExam(null));
    return () => {
      dispatch(actions.setInitialStatistics());
    };
  }, [dispatch]);

  useEffect(() => {
    const abortMostStats = new AbortController();
    dispatch(actions.fetchMostStatistics(theme, t, abortMostStats.signal));
    return () => abortMostStats.abort();
  }, [dispatch, totalExaminations]);

  useEffect(() => {
    const abortPerfStats = new AbortController();
    dispatch(actions.fetchPerformanceAction(abortPerfStats.signal));
    return () => abortPerfStats.abort();
  }, [dispatch, totalExaminations, dates.performanceDates]);

  useEffect(() => {
    const abortCompStats = new AbortController();
    dispatch(actions.fetchCompressionAction(abortCompStats.signal));
    return () => abortCompStats.abort();
  }, [dispatch, totalExaminations, dates.compressionDates]);

  const getChipLabel = (chart) => {
    if (chart === 'compression') {
      if (dates.compressionDates[0] === dates.compressionDates[1]) return dates.compressionDates[0];

      if (dates.compressionDates[0] !== dates.compressionDates[1])
        return `${dates.compressionDates[0]} -
                            ${dates.compressionDates[1]}`;
    }
    if (chart === 'performance') {
      if (dates.performanceDates[0] === dates.performanceDates[1]) return dates.performanceDates[0];

      if (dates.performanceDates[0] !== dates.performanceDates[1])
        return `${dates.performanceDates[0]} -
                            ${dates.performanceDates[1]}`;
    }
  };

  const pgmiChipColor = (value) => {
    if (value === 'none') return theme.palette.dashboard.noSkinFolds;
    if (
      value === 'correct' ||
      value === 'mild' ||
      value === 'straight' ||
      value === 'mild' ||
      value === 'convex'
    )
      return theme.palette.projections.good;
    if (value === 'moderate' || value === 'medium') return theme.palette.projections.moderate;
    if (value === 'insufficient' || value === 'severe' || value === 'concave')
      return theme.palette.projections.insufficient;
  };

  const diagnosisChipLabel = (label) => {
    if (!label[0] || label[0] == 'null' || label[0].toLowerCase() === 'not_eval') {
      return t(`Dashboard.not_eval`);
    }
    return label[0].toUpperCase();
  };

  const filterChipColor = (chipLabel) => {
    if (!chipLabel || !chipLabel[0]) {
      return theme.palette.complementary.turquoise;
    }
    if (chipLabel[0] === 'A' || chipLabel[0] === 'perfect' || chipLabel[0] === 'adequate') {
      return theme.palette.projections.perfect;
    }
    if (chipLabel[0] === 'B' || chipLabel[0] === 'good' || chipLabel[0] === 'none')
      return theme.palette.projections.good;
    if (chipLabel[0] === 'C' || chipLabel[0] === 'moderate' || chipLabel[0].includes('benign'))
      return theme.palette.projections.moderate;
    if (chipLabel[0] === 'D' || chipLabel[0] === 'inadequate' || chipLabel[0].includes('malignant'))
      return theme.palette.projections.insufficient;

    return theme.palette.complementary.turquoise;
  };

  const cardsToRender = [
    //  Examinations
    <ChartCard
      key='exams'
      loading={loadingExams}
      CardClass={smallCardsClasses(0)}
      CardContentClass={classes.CardContent}
      title={t('Dashboard.examinations')}
      subheader={`${totalExaminations} ${t('Dashboard.examinations')}`}
    >
      <BarChartTwo chartdiv='bar_exams' theme={theme} darkState={gui.darkState} />
    </ChartCard>,

    //  Dose
    <ChartCard
      key='dose'
      loading={loadingDose}
      CardClass={smallCardsClasses(1)}
      CardContentClass={classes.CardContent}
      title={t('Dashboard.dose')}
      subheader={averageDose ? `${t('Dashboard.average')} ${averageDose?.toFixed(0)}mGy` : ''}
      uniqueStyle={true}
    >
      <Histogram chartdiv='histogram_dose' theme={theme} darkState={gui.darkState} />
    </ChartCard>,

    // Density
    PREMIUM_DENSITY && (
      <ChartCard
        key='dens'
        loading={loadingDensity}
        CardClass={smallCardsClasses(2)}
        CardContentClass={classes.CardContent}
        title={
          <div className={classes.title_header}>
            {t('Dashboard.density')}
            {'density' in filter && (
              <Chip
                className={classes.featuresFilter}
                label={`ACR ${diagnosisChipLabel(filter.density)}`}
                variant='outlined'
                onDelete={() => dispatch(actions.setFilterProperty(['density', null]))}
                size='small'
                style={{
                  border: `1px solid ${filterChipColor(filter?.density)}`,
                  color: filterChipColor(filter?.density),
                }}
              />
            )}
          </div>
        }
        subheader={'ACR'}
      >
        <PieChart
          chartdiv='pie_density'
          theme={theme}
          dataKey='pieDensityData'
          examinationListDrawer={examinationListDrawer}
          setFilterChartHandler={filterChartHandler}
          t={t}
        />
      </ChartCard>
    ),

    // Quality
    <ChartCard
      key='qual'
      loading={loadingQuality}
      CardClass={smallCardsClasses(PREMIUM_DENSITY ? 3 : 2)}
      CardContentClass={classes.CardContent}
      title={
        <div className={classes.title_header}>
          {t('Dashboard.quality')}
          {'quality' in filter && (
            <Chip
              className={classes.featuresFilter}
              label={`${diagnosisChipLabel(filter.quality)}`}
              variant='outlined'
              onDelete={() => dispatch(actions.setFilterProperty(['quality', null]))}
              size='small'
              style={{
                border: `1px solid ${filterChipColor(filter?.quality)}`,
                color: filterChipColor(filter?.quality),
              }}
            />
          )}
        </div>
      }
      subheader={
        qualityData === 'projections'
          ? t('Dashboard.quality_per_projections')
          : t('Dashboard.quality_per_studies')
      }
      action={
        !('quality' in filter) &&
        OVERALL_QUALITY && (
          <ButtonGroupQuality setQualityData={setQualityData} qualityData={qualityData} />
        )
      }
    >
      <PieChart
        chartdiv='pie_quality'
        theme={theme}
        dataKey={qualityData === 'projections' ? 'pieQualityData' : 'pieStudyQualityData'}
        examinationListDrawer={examinationListDrawer}
        setFilterChartHandler={filterChartHandler}
        t={t}
      />
    </ChartCard>,

    // Diagnostics visibility from .ENV
    somePremiumDiagnostics && (
      <ChartCard
        key='diagnostics'
        loading={loadingOpacities}
        CardClass={smallCardsClasses(PREMIUM_DENSITY ? 4 : 3)}
        CardContentClass={classes.CardContent}
        title={
          <div className={classes.title_header}>
            {examinationListDrawer ? 'Diagnost.' : t('Dashboard.diagnostics')}
            {diagnosticCategory in filter && (
              <Chip
                className={classes.featuresFilter}
                label={diagnosisChipLabel(filter[diagnosticCategory])}
                variant='outlined'
                onDelete={() => dispatch(actions.setFilterProperty([diagnosticCategory, null]))}
                size='small'
                style={{
                  border: `1px solid ${filterChipColor(filter[diagnosticCategory])}`,
                  color: filterChipColor(filter[diagnosticCategory]),
                }}
              />
            )}
          </div>
        }
        subheader={
          diagnosticCategory === 'microcalc'
            ? t('ResultView.diagnosticsMicrocalc')
            : t('ResultView.diagnosticsLesions')
        }
        action={
          !(diagnosticCategory in filter) && (
            <ButtonGroupDiagnostics
              setDiagnosticCategory={setDiagnosticCategory}
              diagnosticCategory={diagnosticCategory}
              showButtons={DIAGNOSTICS_MICROCALC && DIAGNOSTICS_OPACITIES}
            />
          )
        }
      >
        <PieChart
          chartdiv='pie_diagnostics'
          theme={theme}
          dataKey={diagnosticCategory}
          examinationListDrawer={examinationListDrawer}
          setFilterChartHandler={filterChartHandler}
          t={t}
        />
      </ChartCard>
    ),

    // Compression
    <ChartCard
      key='compression'
      loading={loadingCompression}
      CardClass={mediumCardClass()}
      CardContentClass={classes.CardContent}
      title={
        <div className={classes.title_header}>
          {t('Dashboard.compression')}
          {!!dates.compressionDates && (
            <Chip
              className={classes.featuresFilter}
              label={getChipLabel('compression')}
              variant='outlined'
              onDelete={() => dispatch(actions.setCompressionDates(null))}
              color='secondary'
              size='small'
            />
          )}
        </div>
      }
      action={
        <ButtonGroupCompression
          onClick={(index, btn_id) => set_active_proj_compression(index, btn_id)}
          cmp_projections={cmpProjections}
        />
      }
    >
      <MultilineChart
        chartdiv='chart_compression'
        active_projections={cmpProjections}
        theme={theme}
        darkState={gui.darkState}
        onBulletClick={onCompressionBulletClick}
        low_limit={COMPRESSION_LOW}
        high_limit={COMPRESSION_HIGH}
        tolerance={COMPRESSION_TOLERANCE}
        dataKey='lineCompressionData'
        t={t}
      />
    </ChartCard>,

    //PGMI - QUALITY FEATURES
    <ChartCard
      key='bar_quality'
      loading={loadingFeatures}
      CardClass={mediumCardClass()}
      CardContentClass={classes.CardContent}
      title={
        <div className={classes.title_header}>
          {t('Dashboard.quality_features')}
          {!!filter.featuresQuality && (
            <Chip
              className={classes.featuresFilter}
              label={`${filter.featuresQuality.label} ${filter.featuresQuality.value}`}
              variant='outlined'
              onDelete={() => dispatch(actions.setFilterProperty(['featuresQuality', null]))}
              size='small'
              style={{
                border: `1px solid ${pgmiChipColor(filter?.featuresQuality?.value)}`,
                color: pgmiChipColor(filter?.featuresQuality?.value),
              }}
            />
          )}
        </div>
      }
      action={
        <ButtonGroupQualityFeats
          onClick={setActiveProjection}
          active_projection={activeProjection}
        />
      }
      subheader={
        !barQualityData[activeProjection] || barQualityData[activeProjection].length == 0
          ? t('Dashboard.no_features_placeholder')
          : activeProjection?.toUpperCase()
      }
    >
      <QualityFeaturesChart
        chartdiv='bar_quality'
        theme={theme}
        darkState={gui.darkState}
        setFeaturesQuality={featuresQualityHandler}
        activeProjection={activeProjection}
      />
    </ChartCard>,

    //  Quality Point histogram
    <ChartCard
      key='histogram_quality'
      loading={loadingDose}
      CardClass={mediumBigCardClass('pointChart')}
      CardContentClass={classes.CardContent}
      title={t('Dashboard.quality_points')}
      uniqueStyle={true}
      subheader={
        averageQualityPerformance
          ? `${t('Dashboard.average')} ${averageQualityPerformance?.toFixed(0)}%`
          : ''
      }
    >
      <Histogram chartdiv='histogram_quality' theme={theme} darkState={gui.darkState} />
    </ChartCard>,

    // Quality Performance
    <ChartCard
      key='performance_line'
      loading={loadingPerformance}
      CardClass={mediumBigCardClass()}
      CardContentClass={classes.CardContent}
      title={
        <div className={classes.title_header}>
          {t('Dashboard.performance')}
          {!!dates.performanceDates && (
            <Chip
              className={classes.featuresFilter}
              label={getChipLabel('performance')}
              variant='outlined'
              onDelete={() => dispatch(actions.setPerformanceDates(null))}
              color='secondary'
              size='small'
            />
          )}
        </div>
      }
      action={
        <ButtonGroupProjections
          onClick={activePerformanceProj}
          performanceProjections={performanceProjections}
        />
      }
    >
      <MultiLineChartTwo
        chartdiv='line_pgmi'
        activeProjections={performanceProjections}
        theme={theme}
        darkState={gui.darkState}
        onBulletClick={onPerformanceBulletClick}
        dataKey='performanceQualityData'
      />
    </ChartCard>,
  ];

  return (
    <>
      <Helmet>
        <title>{t('b_box.pat_dashboard')}</title>
        <meta name='patient_dashboard' />
      </Helmet>

      <div className={`${classes.dashboard_general} ${dashboardClass()}`}>{cardsToRender}</div>
    </>
  );
};

export default Dashboard;
